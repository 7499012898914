import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = ['permissionLevel', 'availableSeatsLabel', 'hiddenOrganizationId']
  currentOrganization = null

  connect() {
    if (this.hasHiddenOrganizationIdTarget) {
      this.currentOrganization = this.hiddenOrganizationIdTarget.value;
      this._loadOrganizationInfos()
    }
  }

  didChangeOrganization(event) {
    this.currentOrganization = event.target.value;
    this._loadOrganizationInfos()
  }

  _loadOrganizationInfos() {
    if (this.currentOrganization) {
      if (this.hasAvailableSeatsLabelTarget) {
        this.availableSeatsLabelTarget.innerHTML = `<i class="far fa-spinner"></i>`
      }
      fetch(`/spa/organization_admin/organizations/${this.currentOrganization}/write_seats_info`, {
        method: 'GET'
      }).then(response => {
        response.json().then(content => {
          const hasSeatsLeft = content.remainingWriteSeats >= 1
          if (this.hasPermissionLevelTarget) {
            this.permissionLevelTarget.disabled = false;
            this.permissionLevelTarget.checked = hasSeatsLeft;
            this.permissionLevelTarget.disabled = !hasSeatsLeft;
          }
          if (this.hasAvailableSeatsLabelTarget) {
            if (hasSeatsLeft) {
              this.availableSeatsLabelTarget.classList.remove('text-danger')
            } else {
              this.availableSeatsLabelTarget.classList.add('text-danger')
            }
            const leftSeatsLabel = content.licenseWriteSeats === 'Unlimited'? content.licenseWriteSeats : `${content.remainingWriteSeats}/${content.licenseWriteSeats}`
            this.availableSeatsLabelTarget.innerHTML = `<i class="fa fa-exclamation-circle ${hasSeatsLeft? '' : 'text-danger'}"></i>&nbsp;<span>Available license seats (with edit capability): ${leftSeatsLabel}`
          }
        }).catch(json_error => {
          alert(`Ops, something went wrong. Please try again`)
          this.dbg('JSON error: ', json_error)
        })
      }).catch(_error => {
        alert(`Ops, something went wrong. Please try again`)
        this.dbg('Error: ', error)
      })
    } else {
      if (this.hasAvailableSeatsLabelTarget) {
        this.availableSeatsLabelTarget.innerHTML = ''
        this.permissionLevelTarget.checked = true
        this.permissionLevelTarget.disabled = true
      }
    }
  }
}