import { NETWORK_ACTIVITY } from '../actions/networkActivity'

export default (state = {}, action) => {
  switch(action.type) {
    case NETWORK_ACTIVITY: {
    let { networkActivity = {} } = state;
    const { subject, isActive } = action;
    networkActivity[subject] = isActive;
    return {
      ...networkActivity
    }

    } default:
      return state
  }
}