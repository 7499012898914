import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "freezeSpinner" ]
  static values = { engine: String,
                    conditional: Boolean,
                    exists: Boolean,
                    fetchOnceUrl: String,
                    pollUrl: String,
                    oneWayWarning: String,
                    combinationData: String }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      // Handle cancel stuff
      if ( window.location.search.match(/[?&]canceled=-?\d/) ) {
        // Remove the canceled parameter to make sure a refresh won't show the error message again
        history.replaceState({}, document.title, window.location.pathname + window.location.search.replace(/[?&]canceled=-?\d/, ''));
        // User canceled the computation of the very first set combination, no need to re-create one
        if (!this.existsValue) {
          return
        }
      }
      if (this.conditionalValue) {
        let fetchCombinationsOnce = this.existsValue
        let pollForCombinations = this.engineValue !== 'beta' && this.engineValue !== 'beta:optimized' && !this.existsValue
        // Remove the cancel flag if it was previously set:
        HW.model.Plan.canceledResultsComputation = false

        if (fetchCombinationsOnce) {
          HW.utils.scenarios.fetchResults(this.fetchOnceUrlValue, this.oneWayWarningValue)
        }

        if (pollForCombinations) {
          HW.utils.setupCombinationsPE(this.pollUrlValue, this.oneWayWarningValue)
        }
      }
    }
  }

  disconnect() {
    HW.utils.stopPeriodicUpdater(this.pollUrlValue);
  }

  existsValueChanged() {
    if (this.existsValue) {
      HW.utils.scenarios.fetchResults(this.fetchOnceUrlValue, this.oneWayWarningValue)
    }
  }

  combinationDataValueChanged() {
    if (this.hasCombinationDataValue && this.combinationDataValue.length > 0) {
      let combinationDataObj = JSON.parse(JSON.parse(this.combinationDataValue));
      let combinationResultsDataTable = document.getElementById('combination-results-table_wrapper');

      if(combinationResultsDataTable && !combinationDataObj.status.complete) {
        var datatable = $('#combination-results-table').DataTable();
        datatable.clear();
        datatable.rows.add(combinationDataObj.combination_data.combinations);
        datatable.draw();

        document.getElementById('combination-case-count-display').innerHTML = combinationDataObj.combination_data.test_case_counts.combination_case_count_display;
        document.getElementById('strength-counter-container').innerHTML = combinationDataObj.combination_data.test_case_counts.pair_sum;
      } else {
        HW.utils.scenarios.buildTable(combinationDataObj, this.oneWayWarningValue);
      }
    }
  }

  freezeTests(event) {
    event.target.disabled = true;
    this.freezeSpinnerTarget.classList.remove('d-none');
    $('#freeze-strength').val($('#strength-picker').val());
    $('#freeze-form').submit();
  }
}
