import { buildHTMLNodes } from 'modules/DOM/element';

export default class HTMLNodesCellRenderer {
  init(params) {
    if(params.value !== undefined) {
      this.eGui = buildHTMLNodes(params.value);
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    this.eGui = buildHTMLNodes(params.value);
    return true;
  }
}