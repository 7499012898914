import copy from 'copy-to-clipboard'
import Tipped from '@staaky/tipped'
import aui from '@atlassian/aui' // eslint-disable-line no-unused-vars

import 'bootstrap'

// Grab the tooltip popover sanitizer whitelist and add the data-turbo-method option
// so we can utilize it from popovers
var defaultBootstrapWhiteList = $.fn.tooltip.Constructor.Default.whiteList
defaultBootstrapWhiteList.a = ['data-turbo-method', 'data-turbo-frame', 'data-remote', 'data-url', 'data-method', 'data-toggle', 'target', 'href', 'title', 'rel']

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

/**************************************
  Copy To Clipboard Functionality
  Provide the data to copy within the click
  element's data-copy-value attribute.
  Example: <i class='material-icons content_copy' data-copy-value='foo'/>
**************************************/
$(document).on('click', '.copy-to-clipboard', (event) => {
  copy($(event.target).data('copyValue'));
});

// Not a fan of using this compared to Bootstrap 4's library, but we don't
// have as much freedom to dynamically create/update tooltips that way.  Have
// yet to be able to figure out how to create a Bootstrap 4 tooltip after the
// loading of some content via an AJAX request yet.
const tooltipOptions = {
  skin: 'light',
  size: 'large',
  behavior: 'hide',
  hideOthers: 'true',
  position: { target: 'topmiddle' }
}

Tipped.delegate('.copy-to-clipboard', 'Copy to clipboard', Object.assign({
  showOn: 'mouseenter'
}, tooltipOptions));

Tipped.delegate('.copy-to-clipboard', 'Copied!', Object.assign({
  showOn: 'click'
}, tooltipOptions));
/**************************************
  Copy To Clipboard Functionality
**************************************/