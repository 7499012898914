// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { SPABase, createError, userId } from './helpers'

export const DELETE_MEMBERSHIP_REQUEST = 'DELETE_MEMBERSHIP_REQUEST'
export const DELETE_MEMBERSHIP_SUCCESS = 'DELETE_MEMBERSHIP_SUCCESS'
export const DELETE_MEMBERSHIP_FAILURE = 'DELETE_MEMBERSHIP_FAILURE'
export const deleteMembership = membership => {
  return dispatch => {
    dispatch({ type: DELETE_MEMBERSHIP_REQUEST })

    const { id, projectId } = membership

    axios.delete(`${SPABase}/users/${userId()}/projects/${projectId}/memberships/${id}`)
          .then(response => {
            dispatch({
              type: DELETE_MEMBERSHIP_SUCCESS,
              id,
              projectId
            })
          }).catch(error => {
            dispatch(createError({ error, type: DELETE_MEMBERSHIP_FAILURE }))
          })
  }
}

export const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST'
export const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS'
export const FETCH_MEMBERSHIPS_FAILURE = 'FETCH_MEMBERSHIPS_FAILURE'
export const fetchMemberships = projectId => {
  return dispatch => {
    dispatch({ type: FETCH_MEMBERSHIPS_REQUEST })

    axios.get(`${SPABase}/users/${userId()}/projects/${projectId}/memberships`)
          .then(response => {
            dispatch({
              type: FETCH_MEMBERSHIPS_SUCCESS,
              response
            })
          }).catch(error => {
              dispatch(createError({ error, type: FETCH_MEMBERSHIPS_FAILURE }))
            })
  }
}

export const UPDATE_MEMBERSHIP_REQUEST = 'UPDATE_MEMBERSHIP_REQUEST'
export const UPDATE_MEMBERSHIP_SUCCESS = 'UPDATE_MEMBERSHIP_SUCCESS'
export const UPDATE_MEMBERSHIP_FAILURE = 'UPDATE_MEMBERSHIP_FAILURE'
export const updateMembership = membership => {
  return dispatch => {
    dispatch({ type: UPDATE_MEMBERSHIP_REQUEST })

    const { id, level, projectId } = membership

    const params = {
      membership: {
        level
      },
    }

    axios.put(`${SPABase}/users/${userId()}/projects/${projectId}/memberships/${id}`, params)
          .then(response => {
            dispatch({
              type: UPDATE_MEMBERSHIP_SUCCESS,
              response
            })
          }).catch(error => {
            dispatch(createError({ error, type: UPDATE_MEMBERSHIP_FAILURE }))
          })
  }
}
