import { Controller } from "@hotwired/stimulus"
import HexMindMap from "../views/parameters/mind_map.js"

export default class extends Controller {
  static values = { key: String }
  static targets = ['exportButton', 'exportForm', 'testStrength']

  connect() {
    if (this.keyValue === 'Mind Map') {
      HexMindMap.initializeMindMapExport()
    }
  }

  cancelFormSubmit(event) {
    event.preventDefault();

    return false;
  }

  showLoadingState() {
    this.exportButtonTargets.forEach(button => {
      button.classList.add('disabled')
    })
  }

  submit(event) {
    event.preventDefault();

    if(this.exportFormTarget.checkValidity()) {
      this.showLoadingState();

      const exportType = event.target.value

      // Set our commit hidden field equal to the
      // value of the button pressed to submit
      // the form
      $('#commit').val(exportType)

      if(exportType === "Download") {
        // Grab our form and set its target to blank to force
        // it in a new window, then submit our form in that new
        // window, thus having the download in the new window and
        // allowing us to then redirect back to the export page.
        this.exportFormTarget.setAttribute('target', '_blank');
      }

      this.exportFormTarget.submit();

      if(exportType === "Download") {
        Turbo.visit(this.exportFormTarget.dataset.redirectPath);
      }
    } else {
      this.exportFormTarget.reportValidity();
    }
  }

  testsChanged() {
    if(this.testStrengthTarget.value === "0") {
      Turbo.visit(this.testStrengthTarget.dataset.combinationsPath)
    }

    this.exportButtonTargets.forEach(button => {
      button.classList.remove('disabled')
    })
  }
}