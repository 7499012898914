import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { emailSuccess: Boolean, hasTests: Boolean, strengthError: Boolean, strength: String }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      if(this.hasTestsValue && !this.strengthErrorValue) {
        $('#no-tests-present').hide();
        $('#export-options').show();
      } else {
        $('#export-options').hide();
        $('#no-tests-present').show();

        if(this.strengthErrorValue) {
          $('#MB_caption').html(`Please re-create the ${this.strengthValue}-way tests first.`);
        }
      }

      $('.export-option-help-icon').popover({ container: '#data-export-dialog', trigger: 'hover' });
    } else {
      $('#export-container').remove()
    }

    if(this.emailSuccessValue) {
      $('#export-confirmation-dialog').modal()
    }
  }
}