import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['formElement']

  enableTarget(_event) {
    this.formElementTargets.forEach(function(target) {
      target.disabled = false;
      target.title = '';
    })
  }
}