import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'reset']
  static values = { cancelUrl: String }

  connect() {
    this.initialValue = this.inputTarget.value
    this.inputTarget.focus()
  }

  handleKeyPress(event) {
    const modifierKey = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey

    if (event.key === 'Enter') {
      event.preventDefault()
      this.submit()
    }
  }

  handleKeyUp(event) {
    if (event.key === 'Escape') {
      this.resetTarget.click()
    }
  }

  submitBlank() {
    this.inputTarget.value = ''

    this.submit()
  }

  submit() {
    if (this.initialValue !== this.inputTarget.value) {
      this.element.dispatchEvent(new SubmitEvent('submit', { bubbles: true, cancelable: true }))
    }
  }
}