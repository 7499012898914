/* global module */

// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import './utils/asciiArt'
import { Turbo } from '@hotwired/turbo-rails'
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.$RefreshReg$ = () => {};
window.$RefreshSig$ = () => () => {};

if (module.hot) {
  module.hot.accept() // tell hmr to accept updates

  if (module.hot.data) {
    application = module.hot.data.application // re-use old application if one was passed after update
  }
}

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

if (module?.hot) {
  module.hot.dispose(data => {
    data.application = application // on disposal of the old version (before reload), pass the old application to the new version
  })
}

$(document).on('click', 'a[data-toggle="collapse"][data-visit-location]', event => {
  if(!event.target.className.includes('caret')) {
    let path = event.currentTarget.getAttribute('data-visit-location')

    if (window.location.pathname !== path) {
      Turbo.visit(path)
    }
  }
})

$(document).on('click', '#sidebar-navigation li.expandable:not(.active) a[data-toggle="collapse"][data-visit-location] i.material-icons', event => {
  event.stopPropagation();
  event.preventDefault();
  // Find the parent link
  const $linkEl = $(event.target).parents('a[data-toggle="collapse"]')
  // Find the collapse content
  const $subdropdown = $linkEl.next('.subdropdown')
  // Toggle the collapse
  $subdropdown.collapse("toggle")
});
