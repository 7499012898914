import { Controller } from '@hotwired/stimulus'
import { last } from 'underscore/underscore'
import mergeWith from 'deepmerge'
import { buildTree, el, fileReservedKw } from 'modules/DOM'

export default class ResourceTreeController extends Controller {

  _resourcesData = null

  connect() {
    this.element[this.identifier] = this
  }

  get resourcesData() {
    return this._resourcesData
  }

  set resourcesData(resData) {
    this._resourcesData = resData
  }

  _resourceManager = null
  get resourceManager() {
    if (this._resourceManager) {
      return this._resourceManager
    }
    let el = this.element.parentElement
    while(el) {
      if (el['automate-admin--resource-manager'] instanceof Controller) {
        this._resourceManager = el['automate-admin--resource-manager']
        return this._resourceManager
      }
      el = el.parentElement
    }
  }

  updateResources(resData) {
    this.resourcesData = resData
    this.renderResources()
  }

  renderResources() {
    if (this.resourcesData) {
      const filesList = this.resourcesData?.resources
      const parsedFiles = this.filesMap(filesList)
      const tree = this.filesStructure(parsedFiles)
      this.spitFoldersDir(tree)
    }
  }

  filesMap(filesList) {
    return filesList.map((fileData) => {
      // Splitting the object key parts:            rbc/images/leanft.json
      const pieces = fileData?.key.split("/")    // ['rbc', 'images', 'leanft.json']
      const filename = last(pieces)              // 'leanft.json'
      const parseFilename = filename.match(/^([^.]+)\.([a-z0-3]+)$/) // ['leanft.json', 'leanft', 'json', ...]
      const fname = parseFilename[1]             // 'leanft'
      const fext = parseFilename[2]              // 'json'
      let foldersPath = pieces                   // ['rbc', 'images', 'leanft.json']
      if (parseFilename?.length) {               // if we got a match from filename, let's lose it from the folders
        foldersPath = pieces.slice(0, -1)        // ['rbc', 'images']
      }
      return {
        ...fileData,
        uri: fileData?.links.item?.href,
        filename,
        name: fname,
        mimetype: fext,
        folders: foldersPath,
        pathname: foldersPath.join("/")
      }
    })
  }

  filesStructure(parsedFilesList) {
    const tree = parsedFilesList.reduce((fs, file) => {
      const mg = file.folders.slice().reverse().reduce((tmpFs, folderName, _index) => {
        return {
          [folderName]: tmpFs
        }
      }, {[fileReservedKw]: [file]})
      return mergeWith(fs, mg)
    }, {})
    return tree
  }
  /****   PARSE BUCKET  ****/
  /*************************/

  /*************************/
  /**** FILES STRUCTURE ****/
  spitFoldersDir(fs) {
    this.element.innerHTML = ""
    const treeEl = buildTree(fs, {
      download: {
        event: 'download',
        icon: 'download',
        handler: this.downloadFileClicked
      },
      update: {
        event: 'update',
        icon: 'upload_file',
        handler: this.updateFileChanged
      }, 
      delete: {
        event: 'delete',
        icon: 'delete',
        handler: this.deleteFileClicked
      },
      drop: {
        event: 'drop',
        handler: this.fileDropped
      },
      show: {
        event: 'show',
        handler: this.showFileClicked
      },
      new: {
        event: 'new',
        handler: this.uploadFile
      }
    })
    this.element.append(treeEl)
  }
  /**** FILES STRUCTURE ****/
  /*************************/

  /*************************/
  /****     ACTIONS     ****/
  deleteFileClicked = (file, _elem) => {
    if (confirm(`Are you sure you want to delete ${file.filename}?`)) {
      this.resourceManager.deleteFile(file)
    }
  }

  updateFileChanged = (oldFile, newFile, elem) => {
    this.resourceManager.replaceFile(oldFile, newFile, elem)
  }

  showFileClicked = (file, _elem) => {
    this.resourceManager.showFile(file)
  }

  fileDropped = (oldFile, droppedFile, elem) => {
    if (oldFile) {
      this.updateFileChanged(oldFile, droppedFile, elem)
    } else {
      this.uploadFile(null, droppedFile)
    }
  }

  uploadFile = (fileInfo, file) => {
    this.resourceManager.uploadFile(file, fileInfo)
  }

  downloadFileClicked = (file, _elem) => {
    this.resourceManager?.getFile(file).then((fileContent) => {
      const dwEl = el("A", {
        attributes: {
          href: `data:text/plain;charset=utf-8,${encodeURIComponent(fileContent)}`,
          download: file.filename
        },
        style: {
          display: 'none'
        },
        events: {
          click: () => {
            setTimeout(() => {
              this.element.removeChild(dwEl)
            }, 2000)
          }
        }
      })
      this.element.append(dwEl)
      dwEl.click()
    }, (rejectReason) => {
      console.error(`Error: couldn't create link to start download of ${file.uri}`)
    })
  }
  /****     ACTIONS     ****/
  /*************************/
}
