import axios from 'axios'

import { SPABase, authenticityToken, createError, userId } from './helpers'
import { deleteMembership } from './memberships'
import { openModal, EDIT_PLAN } from './shared'
import { networkActivity } from './networkActivity'

const baseEndpoint = () => `/users/${userId()}/plans`

export const EDIT_PROJECT = 'EDIT_PROJECT'
export const editProject = ({ name }) => {
  return { type: EDIT_PROJECT, name }
}

export const EDIT_PROJECT_NAME = 'EDIT_PROJECT_NAME'
export const editProjectName = name => {
  return { type: EDIT_PROJECT_NAME, name }
}

export const ADD_PROJECT_FOLDER = 'ADD_PROJECT_FOLDER'
export const addProjectFolder = _ => {
  return { type: ADD_PROJECT_FOLDER }
}

export const EDIT_PROJECT_FOLDER = 'EDIT_PROJECT_FOLDER'
export const editProjectFolder = ({ name }) => {
  return { type: EDIT_PROJECT_FOLDER, name }
}

export const EDIT_PROJECT_FOLDER_NAME = 'EDIT_PROJECT_FOLDER_NAME'
export const editProjectFolderName = name => {
  return { type: EDIT_PROJECT_FOLDER_NAME, name }
}

export const CANCEL_EDIT_PROJECT = 'CANCEL_EDIT_PROJECT'
export const cancelEditProject = () => {
  return { type: CANCEL_EDIT_PROJECT }
}

export const CANCEL_COPY_REVISION = 'managePlans/copyRevision/cancel'
export const cancelCopyRevision = () => {
  return { type: CANCEL_COPY_REVISION }
}

export const CANCEL_COPY_PLAN = 'managePlans/copyPlan/cancel'
export const cancelCopyPlan = () => {
  return { type: CANCEL_COPY_PLAN }
}

export const CANCEL_REVERT_PLAN = 'managePlans/revertPlan/cancel'
export const cancelRevertPlan = () => {
  return { type: CANCEL_REVERT_PLAN }
}

export const CANCEL_EDIT_PLAN = 'managePlans/editPlan/cancel'
export const cancelEditPlan = () => {
  return { type: CANCEL_EDIT_PLAN }
}

export const CANCEL_NEW_PLAN = 'managePlans/newPlan/cancel'
export const cancelNewPlan = () => {
  return { type: CANCEL_NEW_PLAN }
}

export const LEAVE_PROJECT = 'LEAVE_PROJECT'
export const leaveProject = membership => {
  return dispatch => {
    dispatch(deleteMembership(membership))

    const { projectId } = membership
    dispatch({ type: LEAVE_PROJECT, projectId })
  }
}

export const OPEN_PLAN = 'OPEN_PLAN'
export const openPlan = id => {
  window.location = `${baseEndpoint()}/${id}/edit?show_notes=true`

  return {
    type: OPEN_PLAN,
  }
}

export const REIMPORT_PLAN = 'REIMPORT_PLAN'
export const reimportPlan = (id, { name, project, file }) => {
  return dispatch => {
    dispatch(networkActivity({subject: 'reimportPlan', isActive: true}))
    const url = `${baseEndpoint()}/${id}.json`

    const formData = new FormData()

    formData.append('plan[import]', file)
    name ? formData.append('plan[name]', name) : null
    formData.append('authenticity_token', authenticityToken())

    axios.put(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
          .then(response => {
            const { id } = response.data

            window.location = `${baseEndpoint()}/${id}`
            dispatch({ type: OPEN_PLAN })
          }).catch(error => {
            const { message } = error.response.data

            dispatch(openModal({ name: 'ERROR', message, title: 'Unable to reimport the model', action: () => dispatch(openModal({ name: EDIT_PLAN })) }))
          }).finally(() => {
            dispatch(networkActivity({subject: 'reimportPlan', isActive: false}))
          })
  }
}

export const SELECT_COLUMN_HEADER = 'managePlans/selectColumnHeader'
export const selectColumnHeader = ({ attribute, initiallyAscending }) => {
  return {
    type: SELECT_COLUMN_HEADER,
    attribute,
    initiallyAscending
  }
}

export const SELECT_FILTER_ITEM = 'managePlans/selectFilterItem'
export const selectFilterItem = ({ initialSort, primaryFilter, selectProject }) => {
  const { attribute, ascending } = initialSort

  return {
    type: SELECT_FILTER_ITEM,
    ascending,
    attribute,
    primaryFilter,
    id: selectProject.id
  }
}

export const SELECT_PROJECT = 'managePlans/selectProject'
export const selectProject = (id, projectType) => {
  return {
    type: SELECT_PROJECT,
    id,
    projectType
  }
}

export const SELECT_PROJECT_FOLDER = 'managePlans/selectProjectFolder'
export const selectProjectFolder = (folderId, projectId, projectType) => {
  return {
    type: SELECT_PROJECT_FOLDER,
    id: projectId,
    projectType,
    folderId
  }
}

export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const updateSearchTerm = searchTerm => {
  return {
    type: UPDATE_SEARCH_TERM,
    searchTerm
  }
}
