import { HANDLE_SHARE_PLAN_CHANGE } from 'actions/sharePlan'
import { CREATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS  } from 'actions/projects'

const INITIAL_STATE = {
  plan: {},
  project: {
    id: undefined,
    name: ''
  },
  submitted: false
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CREATE_PROJECT_REQUEST:
    case UPDATE_PROJECT_SUCCESS: {
      return {
        ...state,
        ...action.props,
        submitted: true
      }
    }
    case HANDLE_SHARE_PLAN_CHANGE: {
      return {
        ...state,
        ...action.props
      }
    }
    default: return state
  }
}
