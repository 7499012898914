import HexController from 'controllers/hex_controller'
import Tipped from '@staaky/tipped'
import { uniqueId } from 'underscore'
import { safeHTML } from 'modules/DOM/safeHTML'
import { el, text } from 'modules/DOM/element'

export default class TableTooltipsController extends HexController {
  _combinationData = null
  _elementId = null

  /* Stimulus lifecycle */
  connect() {
    this.setupTooltips()
    // Make sure the element has an id
    this._elementId = this.element.id || uniqueId(this.identifier)
    this.element.id = this._elementId
  }

  disconnect() {
    this.removeTooltips()
  }
  /********************/

  /* Data getters */
  get combinationData() {
    return this._combinationData
  }

  get testCaseCounts() {
    return this.combinationData.testCaseCounts
  }

  get dynamicText() {
    return this.combinationData.dynamicText
  }

  get truncatedParamNames() {
    return this.combinationData.truncatedParamNames
  }
  /********************/

  /* Data update and tooltips create/destroy */
  updateCombinationData(combinationData) {
    this._combinationData = combinationData
    this.removeTooltips()
    this.setupTooltips()
  }

  setupTooltips() {
    if (this._combinationData) {
      // @TODO: enable this once scenarios table is migrated and test it
      // this._strengthInfoTooltips()
      this._truncatedParamTooltips()
      this._noPossibleValueTooltips()
      this._anyValueTooltips()
      this._truncatedValuesTooltips()
    }
  }

  removeTooltips() {
    // @TODO: same here, waiting for scenarios table
    // this._destroyStrengthInfoTooltips()
    this._destroyTruncatedParamTooltips()
    this._destroyNoPossibleValueTooltips()
    this._destroyAnyValueTooltips()
    this._destroyTruncatedValuesTooltips()
  }
  /********************/

  /* Tooltips creation and helpers */
  _commonProps = (customProps = {}, richFormat = false) => {
    // If the tooltip contains HTML it needs to stick when mouse hover it so that user can interact with it
    const richFormatInOutEvents = {
      showOn: {
        element: 'mouseenter',
        tooltip: 'mouseenter'
      },
      hideOn: {
        element: 'mouseleave',
        tooltip: 'mouseleave'
      },
    }
    const plainTextInOutEvents = {
      showOn: 'mouseenter'
    }
    return {
      className: this.dynamicText.subdomain_string,
      maxWidth: 500,
      size: 'large',
      skin: 'light',
      stem: false,
      position: { target: 'bottommiddle' },
      ...( richFormat ? richFormatInOutEvents : plainTextInOutEvents ),
      ...customProps
    }
  }

  /* Strength info  */
  get _strengthInfoSelector() {
    return `#${this._elementId} #strength-info`
  }

  _strengthInfoTooltips() {
    // @FIXME: find a better way to create this rich text tooltip.
    // ie: via `HexModules/DOM/element.el` and `HexModules/DOM/element.text`
    // or using _.template, make sure it's safe for XSS attaks
    const strengthInfoHtml = safeHTML(`<p>We cover all <strong>${this.testCaseCounts.pair_sum}</strong> ` +
                                      `possible ${this.testCaseCounts.strength} in just <strong>${this.testCaseCounts.case_count}</strong> ` +
                                      `of a total possible <strong>${this.testCaseCounts.possible_combinations}</strong> ` +
                                      `scenarios.</p><p>${this.dynamicText.combination_strength_hint}</p>` +
                                      `<p>${this.dynamicText.any_values}</p>`)
    const stregthInfoProps = this._commonProps({
      offset: { x: -4 },
      zIndex: 9999
    }, true)

    Tipped.delegate( this._strengthInfoSelector, strengthInfoHtml, stregthInfoProps );
  }

  _destroyStrengthInfoTooltips() {
    Tipped.undelegate(this._strengthInfoSelector)
  }
  /************************/

  /* Truncated params */
  get _truncatedParamSelector() {
    return `#${this._elementId} .ag-header-cell-text`
  }

  _truncatedParamTooltips() {
    // create tooltip explanation for all truncated parameter names
    Tipped.delegate(this._truncatedParamSelector, (headerEl) => {
      const symbKey = Symbol.for(headerEl.innerText.toLowerCase())
      const paramNames = this.truncatedParamNames
      return paramNames[symbKey]?.fullName
    }, this._commonProps({ position: { target: 'bottommiddle' }}))
  }

  _destroyTruncatedParamTooltips() {
    Tipped.undelegate(this._truncatedParamSelector)
  }

  /************************/

  /* No possible values */
  get _noPossibleValueSelector() {
    return `#${this._elementId} .no-possible-value`
  }

  _noPossibleValueTooltips() {
    const noPossibleValueText = this.t('With the value pair constraints that exist for this model, ' +
                                       'and the other parameter values that are being paired in this particular test case, ' +
                                       'there is no available value for this parameter that will not conflict with at least one of the value pair constraints.')
    // Pass the above text through a P HTML element to make sure it's properly encoded and safe (XSS)
    const noPossibleValueHtml = el('p', noPossibleValueText)
    // create tooltip explanation for all no possible values
    Tipped.delegate( this._noPossibleValueSelector, noPossibleValueHtml.innerHTML, this._commonProps())
  }

  _destroyNoPossibleValueTooltips() {
    Tipped.undelegate(this._noPossibleValueSelector)
  }
  /************************/
  
  /* Any value */
  get _anyValueSelector() {
    return `#${this._elementId} .any-value`
  }

  _anyValueTooltips() {
    const anyValueText = this.t(`This value is not being used to achieve coverage. It can be replaced with any other valid parameter value.`)
    // create tooltip explanation for all any valid values
    Tipped.delegate( this._anyValueSelector, anyValueText, this._commonProps() );
  }

  _destroyAnyValueTooltips() {
    Tipped.undelegate(this._anyValueSelector)
  }
  /************************/
  
  /* Truncated values */
  get _truncatedValuesSelector() {
    // We need to specify 2 spans here or the title attribute will be left intact
    // by Tipped. leading to a double tooltip
    return `#${this._elementId} span.middle-truncated.has-tooltip`
  }

  _truncatedValuesTooltips() {
    const commonProps = this._commonProps()
    // create tooltip explanation for all any valid values
    Tipped.delegate( this._truncatedValuesSelector, (ttEl) => safeHTML(ttEl.dataset.tiptitle), commonProps )
  }

  _destroyTruncatedValuesTooltips() {
    Tipped.undelegate(this._truncatedValuesSelector)
  }
  /************************/
}