import store from '../sharedStore'

export const SPABase = '/spa'

export const authenticityToken = () => {
  const { authenticityToken } = store.getState().metadata

  return authenticityToken
}

export const createError = ({ error, type }) => {
  const message = error.response ? error.response.data.message || 'Something went wrong.' : 'It looks like you may be offline.'

  return {
    type,
    message
  }
}

export const difference = (object, base) => {
  const changes = (object, base) => {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}

export const isEmpty = obj => _.isEqual(obj, {})

export const currentPlan = () => {
  return store.getState().metadata.currentPlan
}

export const planId = () => {
  const planData = currentPlan();
  return planData && planData.id;
}

export const userId = () => {
  const { userId } = store.getState().metadata
  return userId
}

export const tippedOptions = (opts = {}) => {
  const defaultOptions = {
    behavior: 'hide',
    maxWidth: 246,
    stem: false,
    showDelay: 500,
    padding: true,
    position: {
      target: 'bottommiddle'
    },
    size: 'large',
    skin: 'dark'
  };
  // Move positionTarget where it belongs
  const updatedOpts = opts.positionTarget ? {
    ...opts,
    position: {
      target: opts.positionTarget
    }
  } : opts
  return {
    ...defaultOptions,
    ...updatedOpts
  }
}

export const reactStateDispatch = store.dispatch

export const i18nTrnsl = (copy) => i18n.translate(copy).fetch()

export const currentUserCanEdit = ({id, organization_id, organization_level} = {}) => {
  // Make sure the user data have been loaded (id), and that the user or is not part of an organization or that his an editor of such
  return id && (!organization_id || organization_level === 'editor')
}

const helpers = {
  SPABase,
  authenticityToken,
  createError,
  difference,
  isEmpty,
  currentPlan,
  planId,
  userId,
  reactStateDispatch,
  i18nTrnsl,
  currentUserCanEdit
}

export default helpers;
