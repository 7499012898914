import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = [ 'source' ]

  copy() {
    this.sourceTarget.select();

    if(document.queryCommandSupported("copy")) {
      document.execCommand('copy');
      $(this.sourceTarget).effect('highlight', {}, 3000);
    }
  }
}
