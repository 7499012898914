// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { SPABase, userId } from './helpers'

export const ADD_USER = 'projectManagement/addUser'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const addUser = ({ emails, level, projectId }) => {
  return dispatch => {
    dispatch({ type: ADD_USER })

    const params = {
      emails,
      level,
      project_id: projectId
    }

    document.getElementsByClassName('react-dropdown-select-clear')[0].click();

    axios.post(`${SPABase}/users/${userId()}/projects/${projectId}/parse_emails`,  params)
          .then(response => {
            dispatch({
              type: ADD_USER_SUCCESS,
              response
            })})
  }
}

export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const updateEmail = email => {
  return (dispatch, _) => {
    dispatch({ type: UPDATE_EMAIL, email })
  }
}

export const UPDATE_LEVEL = 'UPDATE_LEVEL'
export const updateLevel = level => {
  return {
    type: UPDATE_LEVEL,
    level
  }
}
