import { el } from './element'
import { format } from "bytes"
import { uniq, last } from "underscore"

const capitalize = (str) => {
  if (str?.length) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`
  }
}

const iconForFile = (file) => {
  const lowType = file.mimetype.toLowerCase()
  const codeQ = lowType === 'json'
  const newQ = lowType === 'new_resource'
  const tableQ = lowType === 'csv'
  const xlsQ = ['xls', 'xlsx', 'excel'].includes(lowType)
  return el('I', {
    classMap: {
      'material-icons': true,
      'mimetype-icon': true,
      'code': codeQ,
      'add': newQ,
      'table': tableQ,
      'description': xlsQ,
      'article': !codeQ && !tableQ && !xlsQ
    }
  })
}

const fileSpan = (label, className, opts) => {
  return el('SPAN', {
    innerHTML: `${label}`,
    classMap: {
      [className]: className?.length,
    },
    ...opts,
  })
}

const faIcon = (iconName, classes = []) => {
  const classList = uniq([
    ...classes,
    'material-icons',
    iconName
  ])
  return el('I', {classList})
}

const uploadFileInput = (action, file=null) => {
  return el('INPUT.file-upload-field', {
    attributes: {
      accept: 'json,application/json',
      type: 'file'
    },
    events: {
      click: (e) => {
        e.stopPropagation()
      },
      change: (e) => {
        e.preventDefault()
        const newFiles = e.target.files
        if (file.multi && newFiles.length > 1) {
          Array.from(newFiles).map((f) => action(file, f, e.currentTarget))
        } else {
          action(file, newFiles[0], e.currentTarget)
        }
      }
    }
  })
}

const bt = (file, rel, faIconType, action) => {
  let fileInput = null
  const icon = faIcon(faIconType)
  if (rel === "update") {
    fileInput = uploadFileInput(action, file)
  }
  return el('BUTTON', {
    classList: [`${rel}-bt`, 'btn'],
    children: [icon, fileInput].filter(Boolean),
    tipped: true,
    attributes: {
      title: capitalize(rel)
    },
    events: {
      click: (e) => {
        if (fileInput) {
          // Proxy the click to open the file picker view
          fileInput.click()
        } else {
          action(file, e.currentTarget)
        }
      }
    }
  })
}

const lastModifiedLabel = (file) => {
  const dateStr = new Date(file.updated_at).toLocaleString()
  if (file.metadata?.authorname?.length) {
    return dateStr + ` by ${file.metadata.authorname}`
  }
  return dateStr
}
const kb = (size) => `${size} bytes`

const leaf = (file, actions) => {
  const newQ = file.new
  const upldInpt = newQ && uploadFileInput(actions.new.handler, {new: true, multi: true, pathname: file.pathname})
  const tagName = newQ? "BUTTON" : "A"
  const link = el(tagName, {
    classMap: {
      'leaf-target': true,
      'leaf-new': newQ
    },
    children: [
      iconForFile(file),
      el('SPAN.dnd-drag', newQ? `Upload new in ${file.pathname}` : `Replace ${file.filename}...`),
      el('SPAN.dnd-uploading', 'Uploading file, please wait...'),
      el('SPAN.dnd-not-valid', 'Sorry, this file type is not valid...'),
      fileSpan(file.filename, 'filename', {tipped: true, attributes: {title: newQ? 'Upload a new file' : `Show ${file.filename}`}}),
      upldInpt,
      !newQ && fileSpan(format(Number(file.size)), 'filesize', {tipped: true, attributes: {title: kb(file.size)}}),
      !newQ && fileSpan($.timeago(file.updated_at), 'last-mod', {tipped: true, attributes: {title: lastModifiedLabel(file)}})
    ].filter(Boolean),
    attributes: {
      href: file.uri || '',
      target: '_blank',
    },
    events: {
      click: (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (newQ) {
          upldInpt.click()
        } else {
          if (actions?.show) {
            return actions?.show.handler(file, link)
          }
        }
      }
    }
  })

  const bts = !newQ && Object.values(actions).filter((v) => v.icon).map(({event, handler, icon}) => {
    return bt(file, event, icon, handler)
  })
  const actionIcons = el("DIV.action-icons", {
    children: bts?.length? bts : []
  })

  const isGoodFile = (e) => {
    const allFiles = Array.from(e?.dataTransfer?.files)
    if (!allFiles?.length) {
      return true
    }
    return allFiles.filter((f) => f && (f?.type === "application/json" || f?.name.match(/\.json$/i))).length > 0
  }

  return el('DIV.leaf', {
    events: {
      dragenter: (e) => {
        e.target.classList.add('dnd-file-drag')
        if (!isGoodFile(e)) {
          e.target.classList.add('dnd-file-not-valid')
        }
      },
      dragover: (e) => {
        e.preventDefault()
      },
      dragleave: (e) => {
        e.preventDefault()
        isGoodFile(e)
        e.target.classList.remove('dnd-file-drag', 'dnd-file-not-valid')
      },
      drop: (e) => {
        e.preventDefault()
        e.target.classList.remove('dnd-file-drag')
        e.target.classList.add('dnd-file-drop')
        setTimeout(() => {
          e.target.classList.remove('dnd-file-drop', 'dnd-file-drag')
        }, 5000)
        if (!isGoodFile(e)) {
          return
        }
        const newFile = e.dataTransfer.files[0]
        if (newQ) {
          actions.new.handler(file, newFile, e.target)
        } else {
          actions.drop.handler(file, newFile, e.target)
        }
      }
    },
    children: [link, actionIcons]
  })
}

const dir = (name, indent, initiallyExpanded, expandable = false) => {
  const ichevron = el('I', {
    classList: ['fa', 'fa-chevron-right']
  })
  const idir = el('I', {
    classList: ['fa', 'fa-folder']
  })
  const p = el('LABEL.folder-name', {
    innerHTML: `${name}`
  })
  const expandCollapseFolder = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.parentNode.classList.toggle('expanded')
  }
  const header = el('HEADER', {
    classList: ['folder-header'],
    children: [ichevron, idir, p],
    events: expandable && {
      click: expandCollapseFolder
    }
  })
  return el('DIV', {
    classMap: {
      'folder': true,
      [`level-${indent}`]: true,
      'ml-4': true,
      'expanded': initiallyExpanded
    },
    children: [header]
  })
}

const newFileData = (siblingFile={}, pathname="") => {
  return {
    key: null,
    filename: pathname.length ? `Add file in ${pathname}` : `Add file`,
    pathname: pathname || "",
    mimetype: 'new_resource',
    uri: window.location,
    links: [],
    new: true
  }
}

export const fileReservedKw = "__files";

const pathnameForFolder = (pathname, newFolder) => {
  const nextPiece = newFolder === fileReservedKw? "" : newFolder
  const concatChar = pathname?.length && nextPiece?.length? "/" : ""
  return `${pathname}${concatChar}${nextPiece}`
}

const iterTree = (fs, container, indent, actions, pathname) => {
  const unsortedKeys = Object.keys(fs)
  const sortedFolders = unsortedKeys.filter((k) => k !== fileReservedKw).sort()
  const concatFiles = sortedFolders.length <  unsortedKeys.length? [fileReservedKw] : []
  const allKeys = sortedFolders.concat(concatFiles)
  allKeys.map((k) => {
    const v = fs[k]
    const newPathname = pathnameForFolder(pathname, k)
    if (k === fileReservedKw) {
      const filesContainer = el("DIV.files")
      v.map((file) => {
        filesContainer.append(leaf(file, actions))
      })
      const newFile = newFileData(last(v), newPathname)
      const newLeaf = leaf(newFile, actions)
      filesContainer.append(newLeaf)
      container.append(filesContainer)
    } else {
      const innerContainer = dir(k, indent + 1, true) // All expanded and disable collapse for the time being // idx === 0)
      const branches = el('DIV.branches')
      innerContainer.append(branches)
      container.append(innerContainer)
      iterTree(v, branches, indent + 1, actions, newPathname)
    }
  })
}

const buildTree = (tree, actions) => {
  const treeContainer = el("DIV", {classList: ['tree']})
  iterTree(tree, treeContainer, 0, actions, "")
  return treeContainer
}

export { buildTree };
