import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = [ 'form', 'submitButton' ]

  ignoreInput(event) {
    event.preventDefault()
  }

  showLoadingState() {
    this.submitButtonTargets.forEach(button => {
      button.classList.add('disabled')
      // button.disabled = true
    })
  }

  submit(event) {
    if(this.formTarget.checkValidity()) {
      this.showLoadingState();
    } else {
      this.formTarget.reportValidity();
      event.preventDefault();
    }
  }

  submitEnd() {
    this.submitButtonTargets.forEach(button => {
      button.classList.remove('disabled')
    })
  }
}