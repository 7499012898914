import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

let width, height, gradient;

export default class extends Controller {
  static values = { data: Array }
  static targets = ['canvas']

  connect() {
    this.drawGraph()
  }

  drawGraph() {
    Chart.defaults.color = '#344563'
    Chart.defaults.fontSize = 14
    Chart.defaults.fontFamily = "'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', 'sans-serif'";

    new Chart(this.canvasTarget.id, {
      type: 'line',
      cubicInterpolationMode: 'default',
      data: {
        datasets: [{
          backgroundColor: (context) => {
            const chart = context.chart;
            const {ctx, chartArea} = chart;
 
            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!gradient || width !== chartWidth || height !== chartHeight) {
              // Create the gradient because this is either the first render
              // or the size of the chart has changed
              width = chartWidth;
              height = chartHeight;
              gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);
              gradient.addColorStop(0, 'rgba(0, 101, 255, 0.2)')
              gradient.addColorStop(1, 'rgba(0, 101, 255, 0.0)')
            }
            return gradient
          },
          fill: true,
          borderColor: 'rgba(0, 101, 255, 1)',
          borderWidth: 4,
          data: this.dataValue,
          pointRadius: 0
        }],
        labels: this.dataValue
      },
      options: {
        responsive: true,
        scales: {
          yAxes: {
            id: 'percentage-y-axis',
            gridLines: {
              borderDash: [8, 4],
              color: 'rgba(223, 225, 230, 1)',
            },
            ticks: {
              min: 0,
              max: 100
            }
          },
          xAxes: {
            id: 'test-count-x-axis',
            gridLines: {
              borderDash: [8, 4],
              color: 'rgba(223, 225, 230, 1)',
            },
            ticks: {
              min: 0,
              max: Math.max(this.dataValue),
              maxTicksLimit: 10,
              precision: 0,
              callback: function(_value, index, _values) {
                return index + ' tests';
              }
            }
          },
          x: {
            display: false
          },
          y: {
            display: false
          }
        },
        plugins: {
          tooltip: {
            mode: 'nearest',
            intersect: false,
            displayColors: false,
            backgroundColor: '#172B4D',
            bodySpacing: 8,
            xPadding: 8,
            yPadding: 8,
            cornerRadius: 3,
            caretSize: 0,
            callbacks: {
              title: () => '',
              label: (context) => {
                if(context.parsed.y === 0) {
                  return '0% before any tests';
                } else if (context.dataIndex === 100) {
                  return '100% after final test'
                } else if (context.dataIndex === 1) {
                  return context.label + '% after first test';
                } else {
                  return context.label + '% after ' + context.dataIndex + ' tests';
                }
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    })
  }
}
