import { combineReducers } from 'redux'

import networkActivity from './networkActivity'
import managePlans from './managePlans'
import metadata from './metadata'
import modal from './modal'
import planForm from './planForm'
import plans from './plans'
import projects from './projects'
import projectManagement from './projectManagement'
import requirements from './requirements'
import sharePlan from './sharePlan'
import valuePairs from './valuePairs'
import user from './user'

const rootReducer = combineReducers({
  networkActivity,
  managePlans,
  metadata,
  modal,
  planForm,
  plans,
  projects,
  projectManagement,
  requirements,
  sharePlan,
  valuePairs,
  currentUser: user
})

export default rootReducer
