import { Controller } from "@hotwired/stimulus"
import HexMindMap from "../views/parameters/mind_map.js"

export default class extends Controller {
  static values = { planParameters: String, planId: Number, userId: Number, modifiable: Boolean, saveUrl: String }

  connect() {
    // If the page being connected to is a turbo preview (from the cache) then do not rerun the initialization
    // code. Instead, remove the old SVG view such that we have a "seamless" rerender.
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      HexMindMap.initializeMindMapView()
      document.addEventListener('generateMindMapExport', HexMindMap.initializeMindMapExport);
    } else {
      $('#mindmap-container svg').remove()
    }
  }

  disconnect() {
    document.removeEventListener('generateMindMapExport', HexMindMap.initializeMindMapExport);
  }
}