import { combineReducers } from 'redux'

import primaryFilter from './primaryFilter'
import searchTerm from './searchTerm'
import selectedProject from './selectedProject'
import sortBy from './sortBy'

const rootReducer = combineReducers({
  primaryFilter,
  searchTerm,
  selectedProject,
  sortBy
})

export default rootReducer
