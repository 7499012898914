import HexController from 'controllers/hex_controller'
import { i18nTrnsl, tippedOptions } from 'actions/helpers'

export default class extends HexController {
  static targets = ['expandBt']

  connect() {
    if(localStorage.getItem('sidebar-mini')) {
      this._collapse();
    } else {
      this._expand();
    }
  }

  updateTooltip(title) {
    // Remove any previous tooltip to make sure the content is updated
    Tipped.remove(this.expandBtTarget)
    Tipped.create(this.expandBtTarget, i18nTrnsl(title), tippedOptions({positionTarget: 'right', offset: {y: -6}}) )
  }

  _collapse() {
    // Collapse the sidebar, save the current state in the local storage and update the tooltip on the expand button
    document.body.classList.add('sidebar-mini')
    localStorage.setItem('sidebar-mini', true)
    this.updateTooltip('Expand')
  }

  _expand() {
    // Expand the sidebar, remove the previous state from the local storage and update the tooltip on the expand button
    document.body.classList.remove('sidebar-mini')
    localStorage.removeItem('sidebar-mini')
    this.updateTooltip('Collapse')
  }

  click() {
    // If this is currently collapsed, or it is not collapsed but we're in mini mode, expand it
    if (this.currentlyCollapsed || !this.currentlyCollapsed && localStorage.getItem('sidebar-mini')) {
      this._expand();
    } else {
      this._collapse();
    }
  }

  mouseover() {
    if (this.currentlyCollapsed) {
      _.debounce(() => {
        document.body.classList.remove('sidebar-mini')
      }, 200)()
    }
  }

  chevron_mouseover(event) {
    event.stopPropagation()
  }

  mouseleave() {
    // If the sidebar is not currently collapsed AND we are not in an expanded state
    // then go ahead and collapse it again
    if (!this.currentlyCollapsed && localStorage.getItem('sidebar-mini')) {
      _.debounce(() => {
        document.body.classList.add('sidebar-mini')
      }, 200)()
    }
  }

  get currentlyCollapsed() {
    return document.body.classList.contains('sidebar-mini')
  }
}
