import { buildHTMLNodes } from 'modules/DOM/element';

export default class HTMLNodesHeaderRenderer {
  init(params) {
    this.agParams = params
    if(params.column.colDef.headerComponentParams !== undefined) {
      if (params.enableSorting) {
        const container = {
          node: 'div',
          opts: { classList: ['table-header-icons'] },
          children: [params.column.colDef.headerComponentParams, {
            node: 'div',
            opts: {
              classList: ['sort-icon', 'sort-ascending', 'sort-descending'].filter(Boolean)
            }
          }]
        }
        this.eGui = buildHTMLNodes(container)
        this.eSortTarget = this.eGui.querySelector(".sort-icon");
        this.eGui.addEventListener('click', e => {
          const sortDirection = this.agParams.column.isSortAscending() ? 'desc' : 'asc'
          return this.onSortRequested(sortDirection, e)
        });

        this.onSortChangedListener = this.onSortChanged.bind(this);
        this.agParams.column.addEventListener('sortChanged', this.onSortChangedListener);
        this.onSortChanged();
      } else {
        this.eGui = buildHTMLNodes(params.column.colDef.headerComponentParams);
      }
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    this.eGui = buildHTMLNodes(params.column.colDef.headerComponentParams);
    return true;
  }

  onSortRequested(order, event) {
    this.agParams.setSort(order, event.shiftKey);
  }

  onSortChanged() {
    if (this.agParams.column.isSortAscending()){
      this.eSortTarget.classList.remove('sort-ascending');
      this.eSortTarget.classList.add('sort-descending');
    } else if (this.agParams.column.isSortDescending()){
      this.eSortTarget.classList.add('sort-ascending');
      this.eSortTarget.classList.remove('sort-descending');
    } else {
      this.eSortTarget.classList.remove('sort-descending', 'sort-ascending');
    }
  }

  destroy() {
    this.eGui.removeEventListener('click', this.onSortRequestedListener);
    this.agParams.column.removeEventListener('sortChanged', this.onSortChangedListener);
  }
}