import { applyMiddleware, compose, createStore, } from 'redux'
import ReduxPromiseMiddleware from 'redux-promise-middleware'
import ReduxThunk from 'redux-thunk'

import rootReducer from 'reducers'

const initialState = {}

// Setup taken from here: https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ }) : compose
    // Use this to turn on action tracability
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose

const enhancer = composeEnhancers(applyMiddleware(ReduxThunk, ReduxPromiseMiddleware))

const store = createStore(rootReducer, initialState, enhancer)

export default store
