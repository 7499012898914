import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = [ 'source', 'expected' ]

  connect() {
    this.toggleFormDisplay();
  }

  // For the source target, grab its value
  // Then, for all of our expected targets that we want
  // to toggle, loop through them.  Check the allowed values
  // which should be an array of values that the source target
  // value could be which will cause the expected target to be
  // visible, otherwise it is hidden.
  // We also check if the expected target wants us to toggle
  // the required state of the child, such that when it is visible
  // it is required and when it is hidden it is not required.
  toggleFormDisplay() {
    let sourceTargetValue = this.sourceTarget.value
    let childToggle = this.toggleChildrenRequired.bind(this)

    this.expectedTargets.forEach((ele) => {
      let allowedValues = JSON.parse(ele.dataset.allowedValues)
      let shouldToggleChildrenRequired = JSON.parse(ele.dataset?.toggleChildrenRequired)

      if(allowedValues.includes(sourceTargetValue)) {
        ele.style.display = 'block'
        childToggle(shouldToggleChildrenRequired, ele, true)
      } else {
        ele.style.display = 'none'
        childToggle(shouldToggleChildrenRequired, ele, false)
      }
    });
  }

  toggleChildrenRequired(shouldToggle, parent, toggleValue) {
    if(shouldToggle) {
      parent.childNodes.forEach((child) => {
        if(child.nodeName == "INPUT") {
          child.required = toggleValue
        }
      })
    }
  }
}
