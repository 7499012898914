import { 
  CANCEL_EDIT_PROJECT, EDIT_PROJECT, ADD_PROJECT_FOLDER, EDIT_PROJECT_FOLDER, EDIT_PROJECT_FOLDER_NAME,
  EDIT_PROJECT_NAME, LEAVE_PROJECT, SELECT_PROJECT, SELECT_PROJECT_FOLDER, SELECT_FILTER_ITEM,
} from 'actions/managePlans'

import { DELETE_PROJECT_SUCCESS } from 'actions/projects'
import { DELETE_PROJECT_FOLDER_SUCCESS } from 'actions/projectFolders'

const INITIAL_STATE = { id: null, errorMessage: null, folderId: null }
const selectedProject = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CANCEL_EDIT_PROJECT: {
      return { ...state, creating: false, editing: false, name: null, errorMessage: null }

    } case ADD_PROJECT_FOLDER: {
      return { ...state, name: '', creating: true, errorMessage: null }
    } case EDIT_PROJECT:
      case EDIT_PROJECT_FOLDER: {
      const { name } = action

      return { ...state, editing: true, name, errorMessage: null }

    } case EDIT_PROJECT_NAME:
      case EDIT_PROJECT_FOLDER_NAME: {
      const { name } = action

      return { ...state, name, errorMessage: null }
    } case DELETE_PROJECT_SUCCESS:
      case LEAVE_PROJECT: {
      return state.id === action.projectId ? INITIAL_STATE : state
    } case DELETE_PROJECT_FOLDER_SUCCESS: {
      return {...state, folderId: null }

    } case SELECT_FILTER_ITEM: {
      return {...INITIAL_STATE, id: action.id}

    } case SELECT_PROJECT:
      case SELECT_PROJECT_FOLDER: {
      const { id, folderId } = action

      return { ...INITIAL_STATE, id, folderId }

    } default: {
      if(action.type.endsWith('_FAILURE') && action.message) {
        const { message } = action
        return { ...state, errorMessage: message }
      } else {
        return { ...state, errorMessage: null }
      }
    }
  }
}

export default selectedProject
