import { el } from 'modules/DOM';
import { buildLoadingSpinner } from 'modules/ag-grid/loadingSpinnerRenderer';

export default class UserNameCellRenderer {
  init(params) {
    this.eGui = this.buildNameCell(params);
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    this.eGui = this.buildNameCell(params);
    return true;
  }

  buildNameCell(params) {
    const user = params.data?.name_display;

    if(params.data === undefined) {
      return buildLoadingSpinner();
    } else if (user.intercom_link) {
      return this.buildIntercomLink(user);
    } else {
      return el('div.truncated', user.display_name, {attributes: {title: user.display_name}});
    }
  }

  buildIntercomLink(user) {
    return el('a.truncated', user.display_name, { attributes: { href: user.intercom_link, target: '_blank', title: user.display_name }});
  }
}