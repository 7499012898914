import { FETCH_USER_SUCCESS } from 'actions/shared'

export default (state = {}, action) => {
  switch(action.type) {
    case FETCH_USER_SUCCESS: {
      return action.response.data
    } default:
      return state
  }
}
