// export const asciiArt = `
//  ▄     ▄  ▄▄▄▄▄▄▄  ▄       ▄  ▄▄▄▄▄▄▄  ▄         ▄  ▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄
// ▐░▌   ▐░▌▐░░░░░░░▌▐░▌     ▐░▌▐░░░░░░░▌▐░▌       ▐░▌▐░░░░░░░░░▌▐░░░░░░░▌▐░░░░░░░▌
// ▐░▌   ▐░▌▐░█▀▀▀▀▀  ▐░▌   ▐░▌ ▐░█▀▀▀█░▌▐░▌       ▐░▌ ▀▀▀█░█▀▀▀ ▐░█▀▀▀▀▀ ▐░█▀▀▀▀▀
// ▐░▌   ▐░▌▐░▌        ▐░▌ ▐░▌  ▐░▌   ▐░▌▐░▌       ▐░▌    ▐░▌    ▐░▌      ▐░▌
// ▐░█▄▄▄█░▌▐░█▄▄▄▄▄    ▐░▐░▌   ▐░█▄▄▄█░▌▐░▌   ▄   ▐░▌    ▐░▌    ▐░█▄▄▄▄▄ ▐░█▄▄▄▄▄
// ▐░░░░░░░▌▐░░░░░░░▌    ▐░▌    ▐░░░░░░░▌▐░▌  ▐░▌  ▐░▌    ▐░▌    ▐░░░░░░░▌▐░░░░░░░▌
// ▐░█▀▀▀█░▌▐░█▀▀▀▀▀    ▐░▌░▌   ▐░█▀▀▀█░▌▐░▌ ▐░▌░▌ ▐░▌    ▐░▌     ▀▀▀▀▀█░▌▐░█▀▀▀▀▀
// ▐░▌   ▐░▌▐░▌        ▐░▌ ▐░▌  ▐░▌   ▐░▌▐░▌▐░▌ ▐░▌▐░▌    ▐░▌          ▐░▌▐░▌
// ▐░▌   ▐░▌▐░█▄▄▄▄▄  ▐░▌   ▐░▌ ▐░▌   ▐░▌▐░▌░▌   ▐░▐░▌ ▄▄▄█░█▄▄▄  ▄▄▄▄▄█░▌▐░█▄▄▄▄▄
// ▐░▌   ▐░▌▐░░░░░░░▌▐░▌     ▐░▌▐░▌   ▐░▌▐░░▌     ▐░░▌▐░░░░░░░░░▌▐░░░░░░░▌▐░░░░░░░▌
//  ▀     ▀  ▀▀▀▀▀▀▀  ▀       ▀  ▀     ▀  ▀▀       ▀▀  ▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀
// `

export const asciiArt = "";

export const printAsciiArt = () => {
 console.log(`%c${asciiArt}`, `color: #7265C4`)
}

// const printIfDev = () => {
//   if (process.env.RAILS_ENV === "development") {
//     printAsciiArt()
//   }
// }

// (() => {
//   document.addEventListener("DOMContentLoaded", printIfDev);
// })()
