const _passwordValidationsPassed = Symbol('passwordValidationsPassed');
const _toggleFormSubmitButton = Symbol('toggleFormSubmitButton');

export default class FormValidations {
  shouldValidatePassword = false
  complex = false
  minPasswordLength = 0
  confirmationNeeded = false

  static setup = (shouldValidatePassword, complex, minPasswordLength, confirmationNeeded) => {
    return new this(shouldValidatePassword, complex, minPasswordLength, confirmationNeeded)
  }

  constructor(shouldValidatePassword, complex, minPasswordLength, confirmationNeeded) {
    this.setup(shouldValidatePassword, complex, minPasswordLength, confirmationNeeded)
    return this
  }
  // //
  // Function to simply perform the setup of our form validations on any given
  // page. Does not actually perform any validation, it leaves that up to the browser
  // HTML5 validation API.
  // //
  setup = (shouldValidatePassword, complex, minPasswordLength, confirmationNeeded) => {
    this.shouldValidatePassword = shouldValidatePassword
    this.complex = complex
    this.minPasswordLength = minPasswordLength
    this.confirmationNeeded = confirmationNeeded
    $(document).ready(() => {
      $('input').each((_, element) => {
        let ele = $(element);

        ele.on('keyup change blur', _ => {
          this.validateForm();
        });

        // ele.on('focus', event => {
        //   let input = $(event.currentTarget);

        //   let small = input.prevAll('small');
        //   if (!small.length) {
        //     small = input.parent().prevAll('small');
        //   }

        //   small.show();
        //   input.attr('placeholder', '');
        // });
    
        // ele.on('blur', event => {
        //   let input = $(event.currentTarget);

        //   if (input.val() === "") {
        //     let small = input.prevAll('small');
        //     if (!small.length) {
        //       small = input.parent().prevAll('small');
        //     }
        //     small.hide();
        //     input.removeClass('inputFilled');
    
        //     input.attr('placeholder', input.data('placeholder'));
        //   } else {
        //     input.addClass('inputFilled');
        //   }
        // });
      });

      $('select').each((_, element) => {
        let ele = $(element);

        ele.on('change', _ => this.validateForm());
      });

      $('form.needs-validation').submit((event) => {
        let form = event.currentTarget;
        let fullyValid = this.validateForm();

        if (fullyValid === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      });

      $('form .form-group input:not([readonly]):not([disabled]):first').focus();

      $('form').submit(_ => $(this).find('#submit-button').prop('disabled', true))
    });
  }

  validateForm = () => {
    let form = $('form.needs-validation')[0];
    let formValid = form.checkValidity();
    let fullyValid = formValid;

    if (this.shouldValidatePassword) {
      fullyValid = (this.validatePassword() && formValid);
    }

    this[_toggleFormSubmitButton](fullyValid);

    return fullyValid;
  }

  userIsChangingPassword = () => {
    if (this.shouldValidatePassword &&                          // Password validation is required
        ($('#user_current_password').val()?.length > 0 ||       // current password is empty
         $('#user_password').val()?.length > 0 ||               // new password is empty
         $('#user_password_confirmation').val()?.length > 0)) { // new password confirmation is empty
      return true
    }
    return false
  }

  validatePassword = () => {
    // Check if the user already touched the password fields
    if (!this.userIsChangingPassword()) {
      return true
    }
    // Check the user email field first to see if this is an @hexawise.com email.
    // If so, then go ahead and pretend we are "valid"
    if($('#login-form #user_email').length && $('#login-form #user_email').val().includes('@hexawise.com')) {
      return true
    }

    let validationsPass = this[_passwordValidationsPassed]();

    if (validationsPass) {
      $('#user_password').removeClass('invalid');

      if (this.confirmationNeeded) {
        // If the password confirmation is needed as well, run through
        // its validations too.
        return this.validatePasswordConfirmation();
      }
    } else {
      $('#user_password').addClass('invalid');
    }

    return validationsPass;
  }

  validatePasswordConfirmation = () => {
    // Then we need to check if the passwords are the same
    let password = $('#user_password').val();
    let passwordConfirmation = $('#user_password_confirmation').val();
    let passwordsMatch = password === passwordConfirmation;

    if (passwordsMatch) {
      $('#user_password_confirmation, #password-req-match').removeClass('invalid');
    } else {
      $('#user_password_confirmation, #password-req-match').addClass('invalid');
    }

    return passwordsMatch;
  }

  [_passwordValidationsPassed] = () => {
    const validations = this.complex
      ? {
        lower: false,
        capital: false,
        number: false,
        special: false,
        length: false
      }
      : { length: false };
    const password = $('#user_password').val();

    // if not complex, just check for length
    if (password.length >= this.minPasswordLength && password.length <= 128) validations.length = true;
    if (this.complex) {
      if (/[a-z]/.test(password)) validations.lower = true;
      if (/[A-Z]/.test(password)) validations.capital = true;
      if (/[0-9]/.test(password)) validations.number = true;
      if (/[^a-zA-Z0-9]/.test(password)) validations.special = true;
    }

    Object.keys(validations).forEach((key) => {
      if(validations[key]) {
        $(`#password-req-${key}`).removeClass('invalid');
      } else {
        $(`#password-req-${key}`).addClass('invalid');
      }
    });

    // Check that every validation key is true.  If not, we should not consider this valid.
    let validationsPass = Object.keys(validations).every((key) => validations[key]);

    return validationsPass;
  }

  [_toggleFormSubmitButton] = (shouldEnable) => {
    if (shouldEnable) {
      $('#submit-button').prop('disabled', false)
    } else {
      $('#submit-button').prop('disabled', true)
    }
  }
}