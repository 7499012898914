import { UPDATE_SEARCH_TERM } from 'actions/managePlans'

const searchTerm = (state = '', action) => {
  switch(action.type) {
    case UPDATE_SEARCH_TERM: {
      return action.searchTerm

    } default:
      return state
  }
}

export default searchTerm
