// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { SPABase, createError, userId } from './helpers'
import { openModal, closeModal, redirectToHome, PROJECT_MANAGEMENT, MANAGE_PLANS } from './shared'

import { updatePlan } from './plans'

export const CANCEL_DELETE_PROJECT = 'project/delete/cancel'

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'
export const createProject = ({ planId, projectName, folderName, openProjectManagement }) => {
  return dispatch => {
    dispatch({ type: CREATE_PROJECT_REQUEST })

    const params = {
      project: {
        name: projectName,
        folder: {
          name: folderName,
        }
      },
    }

    return axios.post(`${SPABase}/users/${userId()}/projects`, params)
          .then(response => {
            if (!openProjectManagement) {
              dispatch(closeModal())
            }
            dispatch({ type: CREATE_PROJECT_SUCCESS, response: response.data })
            const { id: projectId } = response.data

            return dispatch(addPlan({ planId, projectId, modal: { dismissOnSuccess: !openProjectManagement } }))
          }).catch(error => {
            dispatch(createError({ error, type: CREATE_PROJECT_FAILURE }))
          })
  }
}

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'
export const deleteProject = ({ currentPlan, id: projectId }) => {
  return dispatch => {
    dispatch({ type: DELETE_PROJECT_REQUEST })

    axios.delete(`${SPABase}/users/${userId()}/projects/${projectId}`)
          .then(response => {
            dispatch({
              type: DELETE_PROJECT_SUCCESS,
              projectId
            })

            if (currentPlan) {
              dispatch(redirectToHome())
            }
          }).catch(error => {
            dispatch(createError({ error, type: DELETE_PROJECT_FAILURE }))
          })
  }
}

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE'
export const fetchProject = projectId => {
  return dispatch => {
    dispatch({ type: FETCH_PROJECT_REQUEST })

    axios.get(`${SPABase}/users/${userId()}/projects/${projectId}`)
          .then(response => {
            dispatch({
              type: FETCH_PROJECT_SUCCESS,
              response
            })
          }).catch(error => {
            dispatch(createError({ type: FETCH_PROJECT_FAILURE, error }))
          })
  }
}

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'
export const fetchProjects = () => {
  return dispatch => {
    dispatch({ type: FETCH_PROJECTS_REQUEST })

    axios.get(`${SPABase}/users/${userId()}/projects`)
          .then(response => {
            dispatch({ type: FETCH_PROJECTS_SUCCESS, response })
          }).catch(error => {
            dispatch(createError({ error, type: FETCH_PROJECTS_FAILURE }))
          })
  }
}

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE'
export const updateProject = ({ projectId, name, publicAccessEnabled: public_access_enabled, privateJoinEnabled: private_join_enabled }) => {
  return dispatch => {
    dispatch({ type: UPDATE_PROJECT_REQUEST})

    const params = {
      project: {
        name,
        public_access_enabled,
        private_join_enabled
      }
    }

    axios.put(`${SPABase}/users/${userId()}/projects/${projectId}`, params)
          .then(response => {
            dispatch({ type: UPDATE_PROJECT_SUCCESS, response })
          }).catch(error => {
            dispatch(createError({ error, type: UPDATE_PROJECT_FAILURE }))
          })
  }
}

export const ADD_PLAN_TO_PROJECT_REQUEST = 'ADD_PLAN_TO_PROJECT_REQUEST'
export const ADD_PLAN_TO_PROJECT_SUCCESS = 'ADD_PLAN_TO_PROJECT_SUCCESS'
export const ADD_PLAN_TO_PROJECT_FAILURE = 'ADD_PLAN_TO_PROJECT_FAILURE'
export const addPlan = ({ planId, projectId, folderId, folderName, modal: { dismissOnSuccess = false, dismissOnCancel = false, reloadOnSuccess = null } = {} }) => {
  return dispatch => {
    const onSuccess = () => {
      if ( dismissOnSuccess ) {
        dispatch(closeModal())
        if ( reloadOnSuccess ) {
          Turbo.visit(reloadOnSuccess)
        }
      } else {
        // Unfortunately window.location is a pain in testing,
        // better to skip it
        if (process.env.NODE_ENV !== 'test') {
          location.reload()
        }
      }
    }

    return dispatch(
      updatePlan({
        id: planId,
        owner_type: 'Project',
        owner_id: projectId,
        folder: {
          id: folderId,
          name: folderName
        },
      },
      onSuccess))
  }
}

export const removePlan = ({ planId, modal: { dismissOnSuccess, dismissOnCancel, reloadOnSuccess } }) => {
  return dispatch => {
    dispatch(
      updatePlan({
        id: planId,
        owner_type: 'User',
        owner_id: userId()
      },
      () => {
        if ( dismissOnSuccess ) {
          return dispatch(closeModal())
        } else {
          return dispatch(openModal({ name: MANAGE_PLANS }))
        }
      }))
  }
}