import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    expected: String,
    targetId: String
  }

  onKeyUp(event) {
    const textField = event.currentTarget
    const target = document.getElementById(this.targetIdValue);
    const match = textField.value == this.expectedValue;
    if (match) {
      target.classList.remove("disabled");
      target.disabled = false;
    } else {
      target.classList.add("disabled");
      target.disabled = true;
    }

    if (event.key === "Enter" && match) {
      target.click();
    }
  }
}