import {
  CLOSE_MODAL, IMPORT_HELP, MANAGE_PLANS, OPEN_MODAL, PROJECT_MANAGEMENT, REDIRECT_TO_HOME, REVISIONS, DELETE_PLAN_REQUEST
} from 'actions/shared'
import { DELETE_INVITATION_SUCCESS } from 'actions/invitations'
import { CANCEL_COPY_REVISION, CANCEL_COPY_PLAN, CANCEL_REVERT_PLAN, CANCEL_EDIT_PLAN, CANCEL_NEW_PLAN } from 'actions/managePlans'
import { DELETE_MEMBERSHIP_SUCCESS } from 'actions/memberships'
import { UPDATE_PLAN_SUCCESS, UPDATE_PLAN_FAILURE } from 'actions/plans'
import { CANCEL_DELETE_PROJECT, DELETE_PROJECT_SUCCESS } from 'actions/projects'
import { CANCEL_DELETE_PROJECT_FOLDER, DELETE_PROJECT_FOLDER_SUCCESS } from 'actions/projectFolders'
import {
  BULK_DELETE_REQUIREMENTS_SUCCESS, UPDATE_REQUIREMENT_SUCCESS, CREATE_REQUIREMENT_SUCCESS, CREATE_REQUIREMENT_FAILURE,
  UPDATE_REQUIREMENT_FAILURE, IMPORT_REQUIREMENT_REQUEST, IMPORT_REQUIREMENT_FAILURE, IMPORT_REQUIREMENT_SUCCESS, UNFREEZE_FROZEN_SCENARIOS_SUCCESS
} from 'actions/requirements'
import {
  DELETE_ALL_VALUE_PAIRS_REQUEST, DELETE_VALUE_PAIR_REQUEST, DELETE_ALL_VALUE_PAIRS_SUCCESS, DELETE_VALUE_PAIR_SUCCESS, CREATE_VALUE_PAIR_SUCCESS
} from 'actions/valuePairs'

export default (state = { spinnerVisible: false }, action) => {
  switch(action.type) {
    case CANCEL_COPY_REVISION:
      return { name: REVISIONS }
    case CANCEL_COPY_PLAN:
    case CANCEL_REVERT_PLAN:
    case CANCEL_EDIT_PLAN: {
      if (state?.dismissOnCancel){
        return {...state, name: state.previousName, previousName: null }
      } else {
        return { name: MANAGE_PLANS }
      }
    }
    case CLOSE_MODAL: {
      switch(state.name) {
        case IMPORT_HELP:
          return { ...state, name: state.previousName, previousName: null }
        default:
          return {}
      }
    }

    case UPDATE_PLAN_FAILURE:
    case UPDATE_PLAN_SUCCESS: {
      if (state?.dismissOnSuccess){
        return {...state, name: state.previousName, previousName: null}
      } else {
        return { name: MANAGE_PLANS }
      }
    }

    case BULK_DELETE_REQUIREMENTS_SUCCESS:
    case CANCEL_NEW_PLAN:
    case CREATE_REQUIREMENT_SUCCESS:
    case CREATE_VALUE_PAIR_SUCCESS:
    case DELETE_VALUE_PAIR_SUCCESS:
    case DELETE_ALL_VALUE_PAIRS_SUCCESS:
    case IMPORT_REQUIREMENT_SUCCESS:
    case REDIRECT_TO_HOME:
    case UNFREEZE_FROZEN_SCENARIOS_SUCCESS:
    case UPDATE_REQUIREMENT_SUCCESS:
      return {}

    case IMPORT_REQUIREMENT_REQUEST:
      return {
        ...state,
        spinnerVisible: true
      }

    case IMPORT_REQUIREMENT_FAILURE:
      return {
        ...state,
        ...action.data,
        spinnerVisible: false
      }

    case CREATE_REQUIREMENT_FAILURE:
    case UPDATE_REQUIREMENT_FAILURE:
      return {
        ...state,
        ...action.data,
        modalState: action.stateData,
        name: action.data.error_type
      }

    case CREATE_VALUE_PAIR_SUCCESS:
      if(action.pair_type != 'invalid') {
        return {}
      } else {
        return state
      }

    case DELETE_PLAN_REQUEST:
    case DELETE_VALUE_PAIR_REQUEST:
    case DELETE_ALL_VALUE_PAIRS_REQUEST:
      return {
        ...state,
        actions: {
          ...state.actions,
          proceed: {
            ...state.actions.proceed,
            disabled: true,
            spinnerVisible: true
          }
        }
      }

    case CANCEL_DELETE_PROJECT:
    case DELETE_PROJECT_SUCCESS:
    case CANCEL_DELETE_PROJECT_FOLDER:
    case DELETE_PROJECT_FOLDER_SUCCESS:
      return { name: MANAGE_PLANS }

    case DELETE_INVITATION_SUCCESS:
    case DELETE_MEMBERSHIP_SUCCESS: {
      const { projectId } = action 

      return { name: PROJECT_MANAGEMENT, projectId }
    }
    case OPEN_MODAL:
      return action.context

    default:
      return state
  }
}
