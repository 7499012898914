import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = ['selectedAccessibleResource', 'selectedProject', 'projectKey', 'exportScript', 'testType', 'testSet', 'testPlan']

  static values = {
    previousSyncSuccess: Boolean,
    projectChangeUrl: String,
    resourceChangeUrl: String
  }

  didChangeAccessibleResource() {
    const jiraCloudName = document.getElementById('user_jira_cloud_name')

    const selectedCloudResource = this.selectedAccessibleResourceTarget.value
    const selectedCloudResourceSplit = selectedCloudResource.split('::JiraAccessibleResource::')
    const selectedCloudResourceName = selectedCloudResourceSplit[1]

    jiraCloudName.value = selectedCloudResourceName

    // Also make a call to the server side, as we'll want to see if they have previously committed to this
    // resource before
    fetch(this.resourceChangeUrlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ user: { jira_cloud_id: selectedCloudResource }})
    })
  }

  didChangeProject() {
    // Update the Client Side
    const jiraProjectKey = document.getElementById('jira_project_key')
    const jiraProjectName = document.getElementById('jira_project_name')
    const jiraProjectValue = document.getElementsByClassName('jira-project')[0]

    const selectedProjectKey = this.selectedProjectTarget.value
    const selectedProjectName = this.selectedProjectTarget.options[this.selectedProjectTarget.selectedIndex].text

    jiraProjectKey.value = selectedProjectKey

    jiraProjectName.value = selectedProjectName
    jiraProjectName.dataset.projectKey = selectedProjectKey

    jiraProjectValue.value = `${selectedProjectName}::JiraProject::${selectedProjectKey}`

    // Also make a call to the server side, as we'll want to see if they have previously committed to this
    // project before
    fetch(`${this.projectChangeUrlValue}?project_key=${selectedProjectKey}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  didCancelCreate() {
    const updateRadio = document.getElementById('xray_issues_action_update')
    updateRadio?.click()

    this.didHideModal()
  }

  didSelectTestAction(event) {
    if(event.target.dataset.target === 'testSet') {
      this.testSetTarget.disabled = event.target.dataset.disabled === 'true';
    } else if (event.target.dataset.target === 'testPlan') {
      this.testPlanTarget.disabled = event.target.dataset.disabled === 'true';
    }
  }

  didSelectCreate() {
    const modal = document.getElementById('xray_sync_create_confirmation')
    modal.dataset.controller = 'hexawise-modal synchronization--xray'
  }

  didHideModal() {
    const modal = document.getElementById('xray_sync_create_confirmation')
    modal.dataset.controller = ''
  }

  submitForm(event) {
    const jiraProjectName = document.getElementById('jira_project_name')
    const testStrength = document.getElementById('test_strength')

    if (jiraProjectName.dataset.projectKey == undefined) {
      jiraProjectName.setCustomValidity(jiraProjectName.dataset.validityMessage)
      jiraProjectName.checkValidity()
      event.preventDefault();
    } else {
      jiraProjectName.setCustomValidity('')
    }

    if (testStrength.value == "0") {
      testStrength.setCustomValidity(testStrength.dataset.validityMessage)
      testStrength.checkValidity()
      event.preventDefault();
    } else {
      testStrength.setCustomValidity('')
    }

    if (this.exportScriptTarget.value == 'manual_auto_scripts' && this.testTypeTarget.value == 'Cucumber') {
      this.testTypeTarget.setCustomValidity(this.testTypeTarget.dataset.validityMessage)
      this.testTypeTarget.checkValidity()
      event.preventDefault();
    } else {
      this.testTypeTarget.setCustomValidity('')
    }
  }
}