import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { plan: Object, planId: Number, userId: Number }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      coverage_matrix.core.start_BANG_(this.planValue, this.planIdValue, this.userIdValue)
    }
  }
}