/**
 * Logging mixins for Stimulus controllers
 * @module Controllers/mixins/logging
 * @file app/controllers/mixins/logging.js
 * @author Iacopo Carraro <iacopo.carraro@hexawise.com>
 * @exports useLogging
 */

const HexPurple = '#7265C4'

/**
 * @function useLogging Mixin used to add logging capabilities to a Stimulus controller
 * @param {Controller} controller the stimulus controller to apply to
 * @param {*} debug if debug is initially ON or OFF
 * @param {*} logColor the color used for the prefix, defaults to hex purple
 * @example How to turn on/off debugging from browser dev-tools console
 * > window._stimulusDebug = true
 * // to activate debug for all controllers. Or use
 * > window['your-stimulus-controller' + '_stimulusDebug'] = true
 * // to debug a single controller. Development env always has debug active
 * @example How to active/deactivate debug mode on page load:
 * https://example.com/users/1/plans/123?_stimulusDebug=1
 * // or for a specific controller
 * https://example.com/users/1/plans/123?your-stimulus-controller_stimulusDebug=1
 */
export const useLogging = (controller, debug = false, logColor = HexPurple) => {
  Object.assign(controller, {
    /**
     * @property Color for the controller's id prefix
     */
    hexDebugColor: logColor,
    /**
     * @property Should print to console or ignore
     */
    verbose: debug,

    /**
     * @method _activateLogging Turn on verbose debug to console for the current controller
     * @param {String} color - optional color expressed in hexadecimal or a color name (CSS names), defaults to hex purple
     */
    _activateLogging: (color = HexPurple) => {
      controller.verbose = true
      controller.hexDebugColor = color ?? controller.hexDebugColor
    },

    _dbgControllerName: () => {
      return controller.identifier || 'HexController'
    },

    /**
     * @method dbg Print to console the passed argument prefixed with the colored controller's id
     * @param  {...any} args A list of items to log to console, it accepts everything console.log can ingest
     */
    dbg: (...args) => {
      if (controller.verbose) {
        // eslint-disable-next-line
        console.log(`%c[${controller._dbgControllerName()}]`, `color: ${ controller.hexDebugColor }`, ...args)
      }
    }
  });
}
