import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = {
    title: String,
    body: String,
    type: String
  }

  ////////////////////
  // Lifecycle events
  ////////////////////
  connect() {
    AJS.messages[this.typeValue]('#notification-inner-container', {
      title: this.titleValue,
      body: this.bodyValue
    });
    this.element.remove();
  }
}