import HexController from 'controllers/hex_controller'
import { object as zipObject, partial, property as prop } from 'underscore'
import { Grid } from 'ag-grid-community'
import HTMLNodesHeaderRenderer from "modules/ag-grid/htmlNodesHeaderRenderer";

const stringOrNumber = (val) => {
  return ["number", "string"].includes(typeof val)
}

class TablePreviewController extends HexController {
  _gridData = null
  _frozenRows = null
  _truncatedParamNames = null
  // Hold AG Grid instance
  columnApi = null
  api = null

  disconnect() {
    // Cleanup the table instance
    this.destroyGrid()
  }

  /* DATA HANDLING/TRASFORM */
  get canRenderTable() {
    return this._gridData?.status?.complete
  }

  get gridData() {
    return this._gridData
  }

  get columns() {
    return this.combinationData.as_columns
  }

  set gridData(newGridData) {
    this._gridData = newGridData
    this._truncatedParamNames = this._parseTruncatedParamNames(newGridData)
    this._frozenRows = this._parseFrozenRows(newGridData)
  }

  get combinations() {
    return this._gridData?.combination_data?.combinations
  }

  get combinationData() {
    return this._gridData?.combination_data || []
  }

  get truncatedParamNames() {
    return this._truncatedParamNames || {}
  }

  get frozenRows() {
    return this._frozenRows || {}
  }

  /* ******************************************************* */

  /* Tooltips */
  get tooltipsController() {
    return this.element['auto-scripts--table-tooltips']
  }

  updateTooltips() {
    // Tooltips need only a subset of the combination data we have...
    this.tooltipsController?.updateCombinationData({
      dynamicText: this.combinationData.dynamic_text,
      truncatedParamNames: this.truncatedParamNames,
      testCaseCounts: this.combinationData.test_case_counts,
    })
  }
  /* ******************************************************* */

  /* Parsing data */
  _parseFrozenRows(newGridData) {
    return newGridData.combination_data.frozen_rows
  }

  _parseTruncatedParamNames(newGridData) {
    const truncParams = newGridData.combination_data.truncated_param_names
    const symbs = truncParams.map(({position}) => Symbol.for(this.columns[position + 1].title.toLowerCase()))
    return zipObject(symbs, truncParams)
  }
  /* *******************************************************  */

  /* AGGrid handling */
  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    this.updateGrid()
    // In case the table is smaller than the available space, let's resize it to make it fill the full width by the columns
    if (this.columnApi.columnModel.bodyWidth < this.columnApi.columnModel.scrollWidth) {
      this.dbg(`Resizing to fit: body ${this.columnApi.columnModel.bodyWidth} < ${this.columnApi.columnModel.scrollWidth}`)
      this.api.sizeColumnsToFit();
    }
  }

  cellRenderer = (params) => {
    if (stringOrNumber(params.value)) {
      return params.value
    }
    const classes = []
    let titleAttr = ""
    const props = params.value || {}
    if (props.title?.length) {
      titleAttr = `data-tiptitle="${props.title}"`
      classes.push('middle-truncated', 'has-tooltip')
    }
    if (props.any_value) {
      classes.push('any-value')
    }
    if (props.skipped_value) {
      classes.push('skipped-value')
    }
    if (props.no_possible_value) {
      classes.push('no-possible-value')
    }
    if (props.bold) {
      classes.push('bold')
    }
    if (props.italic) {
      classes.push('italic')
    }
    if (classes.length || titleAttr.length) {
      return `<span class="${classes?.join(" ")}" ${titleAttr}>${props.value}</span>`
    } else {
      return props.value
    }
  }

  // Which row should be automatically selected when initializing?
  _initiallySelectedRow = 1
  // Make sure it's done only once
  _initialRowSelected = false
  rowSelected = (event) => {
    if (!this._initialRowSelected) {
      this._initialRowSelected = true
      return
    }
    const selectedQ = event.node.isSelected()
    if (selectedQ) {
      const testCaseNumber = Math.max(parseInt(event.node.data["#"], 10) - 1, 0)
      // Retrieve the value (filtering out formatting and js objects) of each column for the
      // selected row, cut out the first col: # (or id) is not needed
      const caseValues = this.columns.map((col) => {
        const val = event.node.data[col.title]
        if (stringOrNumber(val)) {
          return val
        }
        return val.value
      }).slice(1)
      // Create a new TestCase with the row values
      currentTestCase = new HW.model.TestCase(testCaseNumber, caseValues);
      // Replace the current test preview with the new steps
      currentTestCase.select(true, testCaseNumber, this.combinationData.request_parameters, !selectedQ);
    }
  }

  get columnDefs() {
    return this.columns.map((col, columnIndex) => {
      const comparator = (valA, valB) => {
        if (typeof valA === "number" && typeof valB === "number") {
          return valA - valB
        }
        const va = stringOrNumber(valA)? valA : valA.value
        const vb = stringOrNumber(valB)? valB : valB.value
        return `${va}`.localeCompare(`${vb}`)
      }
      const colMap = columnIndex === 0 ? { sort: 'asc', comparator } : { comparator }
      return {
        ...colMap,
        field: col.title,
        headerComponentParams: col.headerComponentParams
      }
    })
  }

  setupGrid() {
    if (this.canRenderTable) {
      const gridOptions = {
        columnDefs: this.columnDefs,
        rowSelection: 'single',
        suppressRowDeselection: true,
        onFirstDataRendered: (_event) => {
          if (!this._initialRowSelected) {
            const initRowNode = this.api.getRowNode(`${this._initiallySelectedRow}`)
            initRowNode.setSelected(true, true)
          }
        },
        rowClassRules: {
          frozen: (params) => this.frozenRows[params.node.rowIndex],
          odd: (params) => params.node.rowIndex % 2 === 1,
          even: (params) => params.node.rowIndex % 2 === 0,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          unSortIcon: true,
          cellRenderer: this.cellRenderer
        },
        getRowNodeId: (rowNodeData) => {
          return rowNodeData["#"]
        },
        rowHeight: 49,
        rowData: null,
        // Events
        onRowSelected: this.rowSelected,
        onGridReady: this.onGridReady,
        enableFilter: true,
        enableSorting: true,
        enableColResize: true,
        suppressMenuHide: true,
        components: {
          headerComponent : HTMLNodesHeaderRenderer
        }
      }
      new Grid(this.element, gridOptions) //, this.gridUIUpdated)
    }
  }

  updateGrid() {
    if (this.api && this.gridData) {
      const keys = this.columnDefs.map(prop('field'))
      const zipRow = partial(zipObject, keys)         // Partial creates: zip([k1, k2, k3]), then apply it to each row:
      const tableData = this.combinations.map(zipRow) // [k1, k2, k3] and [v1, v2, v3] => {k1: v1, k2: v2, k3: v3}
      this.api.setRowData(tableData)
      this.api.refreshCells() // Use refreshCells instead of refreshRows because it goes conditional refresh
    }
  }

  destroyGrid() {
    if (this.api) {
      this.api.destroy()
      this.columnApi.destroyGrid
    }
    this.api = null
    this.columnApi = null
  }

  updateTableData(data) {
    this.gridData = data
    if (!this.api) {
      this.destroyGrid()
      this.setupGrid()
    } else {
      this.updateGrid()
    }
    this.updateTooltips()
  }
  /* ******************************************************* */
}

export default TablePreviewController
