import { Controller } from '@hotwired/stimulus'
import { el } from 'modules/DOM/element'
import FileDisplayerController from "./file_displayer_controller"

export default class ResourceManagerController extends Controller {
  static targets = ['loading', 'resourceTree', 'errorMessage', 'fileDisplayer']
  static values = {
    resourcesUrl: String
  }

  connect() {
    this.loadResources()
    this.element[this.identifier] = this
  }

  createErrorMessageElement() {
    if (!this.hasErrorMessageTarget) {
      const errorMessage = el("DIV", {
        classList: ['resource-error'],
        attributes: {
          [`data-${this.identifier}-target`]: 'errorMessage'
        }
      })
      this.element.append(errorMessage)
    }
  }

  hideAll() {
    if (this.hasResourceTreeTarget) {
      this.resourceTreeTarget.style.display = 'none'
    }
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.style.display = 'none'
    }
    if (this.hasFileDisplayerTarget) {
      this.fileDisplayerTarget.style.display = 'none'
    }
    if (this.hasLoadingTarget) {
      this.loadingTarget.style.display = 'none'
    }
  }

  showLoading() {
    this.hideAll()
    if (this.hasLoadingTarget) {
      this.loadingTarget.style.display = 'block'
    }
  }

  showFileDisplayer() {
    this.hideAll()
    if (this.hasResourceTreeTarget) {
      this.resourceTreeTarget.style.display = 'block'
    }
    if (this.hasFileDisplayerTarget) {
      this.fileDisplayerTarget.style.display = 'block'
    }
  }

  showFoldersList() {
    this.hideAll()
    if (this.hasResourceTreeTarget) {
      this.resourceTreeTarget.style.display = 'block'
    }
  }

  showErrorMessage() {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.style.display = 'block'
    }
  }

  displayErrorMessage(msg, info) {
    this.createErrorMessageElement()
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = msg + process.env.DEBUG? JSON.stringify(info, null, 2) : ""
      this.showErrorMessage()
    }
  }

  /**
   * Load bucket data from 
   */
  loadResources() {
    if (!this.hasResourceTreeTarget) {
      this.showLoading()
    }
    if (this.hasResourcesUrlValue) {
      this.getFilesList().then((resp) => {
        if (this._respOK(resp)) {
          resp.text().then((bodyText) => {
            this.parseResourcesResponse(bodyText)
          }, (parseError) => {
            console.error(parseError)
            this.displayErrorMessage(`Error: failed parsing the files data`, parseError)
            return
          })
        } else {
          resp.text().then((respBody) => {
            this._fetchFailed(`Error: failed retrieving the list of files`, respBody, false)
          })
        }
      }, (err) => {
        console.error(err)
        this.displayErrorMessage(`Error: failed retrieving the files list`, err)
      })
    }
  }

  parseResourcesResponse(rawBody) {
    try {
      this.resourcesData = JSON.parse(rawBody)
    } catch(err) {
      console.error('Error: error parsing resources response', error, rawBody)
    }
    this.renderResources()
  }

  get resourceTreeController() {
    if (this.hasResourceTreeTarget) {
      return this.resourceTreeTarget['automate-admin--resource-tree']
    }
  }

  renderResources() {
    this.showFoldersList()
    this.resourceTreeController.updateResources(this.resourcesData)
  }

  /*************************/
  /****   FILE DISPLAY  ****/
  get fileDisplayer() {
    if (this.hasFileDisplayerTarget) {
      return this.fileDisplayerTarget['automate-admin--file-displayer']
    }
  }

  updateDisplayedFile = (file, fileContent) => {
    if (!this.hasFileDisplayerTarget) {
      this.element.append(FileDisplayerController.Element(this.identifier, 'fileDisplayer'))
    }
    setTimeout(() => {
      this.fileDisplayer.updateDisplayedFile(file, fileContent)
      this.showFileDisplayer()
    }, 100)
  }
  /****   FILE DISPLAY  ****/
  /*************************/

  /*************************/
  /****    NETWORKING   ****/
  _headers = {
    "cache-control": "no-cache",
    "pragma": "no-cache",
    // 'Access-Control-Allow-Origin': '*'
  }

  _apiOptions = {
    // headers: this._headers,
    // referrerPolicy: "origin",
    // mode: "cors",
    // credentials: "omit"
  }

  getFilesList() {
    return new Promise((resolve, reject) => {
      fetch(this.resourcesUrlValue, this._apiOptions).then(resolve, reject)
    })
  }

  getFile(file) {
    return new Promise((resolve, reject) => {
      const itemLink = file.links.item
      fetch(itemLink.href, this._apiOptions).then((resp) => {
        if (this._respOK(resp)) {
          resp.text().then((body) => resolve(body), (parseErrorReason) => {
            console.error(`Parse error:`, parseErrorReason)
            reject(`Error parsing body response`, parseErrorReason)
          })
        } else {
          resp.text().then((err) => {
            reject(`Error: failed retrieving file content`, err)
          }, (rej) => {
            reject(`Error: failed retrieving file content`, rej)
          })
        }
      }, (reason) => {
        reject(`Error: failed loading file with url ${itemLink.href}`, reason)
      })
    })
  }

  _fetchSuccess = (resp) => {
    if (this._respOK(resp)) {
      this.loadResources()
    }
  }

  _respOK = (resp) => {
    return resp.status >= 200 && resp.status < 300
  }

  _fetchFailed = (errorMsg, info, refreshListQ=true) => {
    console.error(errorMsg, info)
    this.displayErrorMessage(errorMsg, info)
    if (refreshListQ) {
      this.loadResources()
    }
  }

  deleteFile(file) {
    const deleteLink = file.links.destroy
    const opts = {
      ...this._apiOptions,
      method: deleteLink.method,
      headers: this._headers
    }
    fetch(deleteLink.href, opts).then(this._fetchSuccess, (rejectionReason) => {
      this._fetchFailed(`Error: failed deleting file ${file.key}`, rejectionReason)
    })
  }

  uploadFile(file, fileInfo) {
    const createLink = this.resourcesData.links.create
    if (createLink && file) {
      let filename = file.name.replace(/:/g, "/")
      if (fileInfo?.pathname?.length) {
        filename = `${fileInfo.pathname}/${filename}`
      }
      const formData = new FormData()
      formData.append('form_data[file]', file)
      formData.append('form_data[filename]', filename)
      fetch(createLink.href, {
        ...this._apiOptions,
        method: createLink.method,
        body: formData,
        headers: this._headers
      }).then(this._fetchSuccess, (error) => this._fetchFailed(`Error: failed uploading file ${file.filename}`, error))
    }
  }

  uploadFileWithContent(filename, fileContent) {
    const createLink = this.resourcesData.links.create
    if (createLink && fileContent) {
      const formData = new FormData()
      formData.append('form_data[content]', fileContent)
      formData.append('form_data[filename]', filename)
      fetch(createLink.href, {
        ...this._apiOptions,
        method: createLink.method,
        body: formData,
        headers: this._headers
      }).then(this._fetchSuccess, (error) => this._fetchFailed(`Error: failed uploading file data for ${filename}`, error))
    }
  }

  replaceFile(fileMeta, newFile) {
    const updateLink = fileMeta.links.update
    if (updateLink && newFile) {
      const formData = new FormData()
      formData.append('form_data[file]', newFile)
      formData.append('form_data[filename]', fileMeta.key)
      fetch(updateLink.href, {
        ...this._apiOptions,
        method: updateLink.method,
        body: formData,
        headers: this._headers
      }).then(this._fetchSuccess, this._fetchFailed)
    }
  }

  duplicateFile(file) {
    const itemLink = file.links.item
    fetch(itemLink.href, this._apiOptions).then((resp) => {
      resp.blob().then((content) => {
        const filename = `${file.folders.join("/")}/${file.name}_copy.${file.mimetype}`
        this.uploadFileWithContent(filename, content)
      })
    })
  }

  showFile(file) {
    this.getFile(file).then((fileContent) => {
      this.updateDisplayedFile(file, fileContent)
    }, (errorReason) => {
      this._fetchFailed(`Error: failed loading content of file ${file.filename}`, errorReason)
    })
  }
  /****    NETWORKING   ****/
  /*************************/
}
