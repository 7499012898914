import { el } from "./element"

let safeHTMLEl = null
const safeElementSelector = "#safe-html-el.safe-html-element.hidden"

const getOrCreateElement = () => {
  return document.querySelector(safeElementSelector) ||
         document.body.appendChild( el(safeElementSelector, { style: { display: 'none' } }))
}

const safeElement = () => {
  if (!safeHTMLEl) {
    safeHTMLEl = getOrCreateElement()
  }
  return safeHTMLEl
}

export const safeHTML = (text) => {
  const safeEl = safeElement()
  safeEl.textContent = text
  const safeText = safeEl.innerHTML
  // Cleanup
  safeEl.textContent = ''
  return safeText
}
