import { el } from 'modules/DOM'
import { buildLoadingSpinner } from 'modules/ag-grid/loadingSpinnerRenderer'

export default class OrganizationNameCellRenderer {
  init(params) {
    this.eGui = this.buildNameCell(params);
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    this.eGui = this.buildNameCell(params);
    return true;
  }

  buildNameCell(params) {
    if(params.data === undefined) {
      return buildLoadingSpinner();
    } else {
      return el('div.truncated', params.data.name);
    }
  }
}