import { FETCH_PLANS_SUCCESS } from 'actions/plans'
import { SELECT_FILTER_ITEM, SELECT_PROJECT, SELECT_PROJECT_FOLDER } from 'actions/managePlans'

const primaryFilter = (state = '', action) => {
  switch(action.type) {
    case FETCH_PLANS_SUCCESS: {
      const onlySamplePlans = action.response.data.plans.every(plan => plan.sample)

      return onlySamplePlans ? 'SAMPLE_PLANS' : 'ALL_PLANS'

    } case SELECT_FILTER_ITEM: {
      return action.primaryFilter

    } case SELECT_PROJECT:
      case SELECT_PROJECT_FOLDER: {
      if (action.projectType === 'projects') {
        return 'PROJECTS'
      } else {
        return 'RECENT_PROJECTS'
      }

    } default:
      return state
  }
}

export default primaryFilter
