import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { icon: String }

  click(event) {
    let icon = document.getElementById(this.iconValue)
    icon.classList.add('fa-spin')

    // Disable button and show spinner
    event.currentTarget.setAttribute('disabled', true)
    event.currentTarget.previousElementSibling.removeAttribute('hidden');

    const { url, body } = event.currentTarget.dataset
    const token = document.getElementsByName("csrf-token")[0].content
    const data = { ...JSON.parse(body), authenticity_token: token }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(data => Turbo.visit(data.url))
  }
}