import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { spaUrl: String };

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      automate.web.start_BANG_({ spa_url: this.spaUrlValue });
    }
  }
}