import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "src") {
          history.pushState({ turbo_frame_history: true }, "", this.element.getAttribute("src"))
        }
      })
    })

    this.observer.observe(this.element, { attributes: true })

    this.popStateListener = ((event) => {
      if (event.state?.turbo_frame_history) {
        Turbo.visit(window.location.href, { action: 'replace' })
      }
    })

    window.addEventListener("popstate", this.popStateListener)
  }

  disconnect() {
    this.observer.disconnect()
    window.removeEventListener("popstate", this.popStateListener)
  }
}