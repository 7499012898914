import { SELECT_COLUMN_HEADER, SELECT_FILTER_ITEM, SELECT_PROJECT } from 'actions/managePlans'
import { FETCH_PLANS_SUCCESS } from 'actions/plans'

const sortBy = (state = { attribute: 'MODIFIED', ascending: false }, action) => {
  switch(action.type) {
    case FETCH_PLANS_SUCCESS: {
      const onlySamplePlans = action.response.data.plans.every(plan => plan.sample)

      return onlySamplePlans ? { attribute: 'NAME', ascending: true } : state
    } case SELECT_COLUMN_HEADER: {
      const { attribute, initiallyAscending } = action
      const ascending = attribute === state.attribute ? !state.ascending : initiallyAscending

      return { ...state, attribute, ascending }

    } case SELECT_FILTER_ITEM: {
      const { ascending, attribute } = action

      return { ascending, attribute }

    } case SELECT_PROJECT: {
      return { ascending: false, attribute: 'MODIFIED' }

    } default:
      return state
  }
}

export default sortBy