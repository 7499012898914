import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = { title: String }

  connect() {
    // Setup our popover trigger and allow it to pop up on click
    // but also close out when clicked out, but still allow the
    // user to click on links within without closing out
    $('body').popover({
      selector: '[data-manual-toggle="popover"]',
      trigger: 'manual'
    })

    $('body').delegate('[data-manual-toggle="popover"]', 'focus', function (e) {
      $(this).popover('show');
    })

    // Set it up such that if we are clicking anywhere within the body
    // we check and see if the element being clicked exists within the popover.
    // If so, don't close it out, otherwise we close out of the popover
    // as expected.
    $('body').on('click', function (e) {
      $('[data-manual-toggle=popover]').each(function () {
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          $(this).popover('hide');
        }
      });
    });
  }
}
