import { COPY_PLAN } from 'actions/planForm'
import { CREATE_PROJECT_SUCCESS, DELETE_PROJECT_SUCCESS, FETCH_PROJECT_SUCCESS, FETCH_PROJECTS_SUCCESS, UPDATE_PROJECT_SUCCESS  } from 'actions/projects'
import { CREATE_PROJECT_FOLDER_SUCCESS, DELETE_PROJECT_FOLDER_SUCCESS, UPDATE_PROJECT_FOLDER_SUCCESS } from 'actions/projectFolders'
import { ADD_USER_SUCCESS } from 'actions/projectManagement'
import { LEAVE_PROJECT } from 'actions/managePlans'
import { DELETE_MEMBERSHIP_SUCCESS, UPDATE_MEMBERSHIP_SUCCESS } from 'actions/memberships'
import { DELETE_INVITATION_SUCCESS, RESEND_INVITATION_SUCCESS, UPDATE_INVITATION_SUCCESS } from 'actions/invitations'

export default (state = [], action) => {
  switch(action.type) {
    case DELETE_PROJECT_SUCCESS:
    case LEAVE_PROJECT: {
      const { projectId } = action

      const projectIndex = state.findIndex((element) => element.id === projectId)

      return [...state.slice(0, projectIndex), ...state.slice(projectIndex + 1)]

    } case FETCH_PROJECTS_SUCCESS: {
      const { projects } = action.response.data

      return [...projects]
    } case CREATE_PROJECT_SUCCESS: {
      const project = action.response

      return [...state, project]
    } case COPY_PLAN: {
      const { newProject, newProjectFolder } = action.response.data

      let retState = [...state]

      if (newProject) {
        retState = [...retState, newProject]
      }
      if (newProjectFolder) {
        retState = [...retState, newProjectFolder]
      }
      return retState
    }
      case ADD_USER_SUCCESS:
      case FETCH_PROJECT_SUCCESS:
      case UPDATE_PROJECT_SUCCESS:
      case CREATE_PROJECT_FOLDER_SUCCESS:
      case UPDATE_PROJECT_FOLDER_SUCCESS:
      case DELETE_PROJECT_FOLDER_SUCCESS: {
      const { data: updatedProject } = action.response

      const projectIndex = state.findIndex(element => element.id === updatedProject.id)
      const project = state[projectIndex]

      return [...state.slice(0, projectIndex), { ...project, ...updatedProject }, ...state.slice(projectIndex + 1)]

    } case DELETE_MEMBERSHIP_SUCCESS: {
      const { id, projectId } = action

      const projectIndex = state.findIndex(element => element.id === projectId)
      const project = state[projectIndex]

      const memberships = [...project.memberships.filter(membership => membership.id !== id)]

      return [...state.slice(0, projectIndex), { ...project, memberships }, ...state.slice(projectIndex + 1)]

    } case UPDATE_MEMBERSHIP_SUCCESS: {
      const { data: membership } = action.response
      const { id, projectId } = membership

      const projectIndex = state.findIndex(element => element.id === projectId)
      const project = state[projectIndex]

      const membershipIndex = project.memberships.findIndex(element => element.id === id)
      const memberships = [...project.memberships.slice(0, membershipIndex), { ...membership }, ...project.memberships.slice(membershipIndex + 1)]

      return [...state.slice(0, projectIndex), { ...project, memberships }, ...state.slice(projectIndex + 1)]

    } case DELETE_INVITATION_SUCCESS: {
      const { id, projectId } = action

      const projectIndex = state.findIndex(element => element.id === projectId)
      const project = state[projectIndex]

      const invitations = [...project.invitations.filter(invitation => invitation.id !== id)]

      return [...state.slice(0, projectIndex), { ...project, invitations }, ...state.slice(projectIndex + 1)]

    } case RESEND_INVITATION_SUCCESS:
      case UPDATE_INVITATION_SUCCESS: {
      const { data: invitation } = action.response
      const { id, projectId } = invitation

      const projectIndex = state.findIndex(element => element.id === projectId)
      const project = state[projectIndex]

      const invitationIndex = project.invitations.findIndex(element => element.id === id)
      const invitations = [...project.invitations.slice(0, invitationIndex), { ...invitation }, ...project.invitations.slice(invitationIndex + 1)]

      return [...state.slice(0, projectIndex), { ...project, invitations }, ...state.slice(projectIndex + 1)]

    } default:
      return state
  }
}
