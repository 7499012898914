// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { SPABase, createError, userId } from './helpers'
import { networkActivity } from './networkActivity'
import { fetchProject } from './projects'

export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST'
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS'
export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE'
export const fetchPlans = () => {
  return dispatch => {
    dispatch({ type: FETCH_PLANS_REQUEST })
    dispatch(networkActivity({subject: 'fetchPlans', isActive: true}))

    axios.get(`${SPABase}/users/${userId()}/plans`)
          .then(response => {
            dispatch({ type: FETCH_PLANS_SUCCESS, response })
          }).catch(error => {
            dispatch(createError({ error, type: FETCH_PLANS_FAILURE }))
          }).finally(() => {
            dispatch(networkActivity({subject: 'fetchPlans', isActive: false}))
          })
  }
}

export const UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE'
export const updatePlan = (plan, onSuccess = null) => {
  return dispatch => {
    dispatch({ type: UPDATE_PLAN_REQUEST })

    // Signal network activity is taking place
    dispatch(networkActivity({subject: 'updatePlan', isActive: true}))

    return axios.put(`${SPABase}/users/${userId()}/plans/${plan.id}`, { plan })
          .then(response => {
            dispatch({ type: UPDATE_PLAN_SUCCESS, response: response.data })

            if(response.data.projectId) {
              dispatch(fetchProject(response.data.projectId))
            }

            if(onSuccess) {
              onSuccess()
            }
          }).catch(error => {
            dispatch(createError({ error, type: UPDATE_PLAN_FAILURE }))
          }).finally(() => {
            dispatch(networkActivity({subject: 'updatePlan', isActive: false}))
          })
  }
}
