import { Controller } from '@hotwired/stimulus'
import { COPY_PLAN, openModal } from 'actions/shared'
import { handleChange } from 'actions/planForm'
import { reactStateDispatch } from 'actions/helpers'
import { handleDelete } from '../actions/shared'
import { currentPlan } from '../actions/helpers'

export default class extends Controller {

  connect() {
  }

  deletePlan(event) {
    if (event) {
      event.preventDefault()
    }
    const planData = currentPlan()
    handleDelete(planData, reactStateDispatch)
  }

  copyPlan(event) {
    if (event) {
      event.preventDefault()
    }
    const planData = currentPlan()
    reactStateDispatch(handleChange({
      id: planData.id,
      name: `${planData.name} (copy)`,
      project: {
        id: planData.projectId,
        folderId: planData.projectFolderId || 0
      }
    }))
    reactStateDispatch(openModal({
      name: COPY_PLAN,
      id: planData.id,
      dismissOnSuccess: true,
      dismissOnCancel: true
    }))
  }
}