import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    userId: String, // Who is watching
    url: String,    // What is watching
    playedClass: String,  // Add a class to the triggering element (ie: button), null for not
    markWatchOnLoad: Boolean // Mark watch on loadstart instead of play
  }

  ////////////////////
  // Lifecycle events
  ////////////////////
  connect() {
    // Pause our video when the modal is hidden
    $('#video-player').on('hide.bs.modal', this.pauseVideoPlayer.bind(this))
  }

  disconnect() {
    // Turn off the hide event so we don't start doubling up on it
    $('#video-player').off('hide.bs.modal', this.pauseVideoPlayer.bind(this))
  }

  ////////////////////
  // Action events
  ////////////////////

  // If the playedClass value is defined and elTarget is an element
  // of the page then add this as className to the event target
  markViewed(elTarget) {
    if (elTarget?.parentElement && this.playedClassValue?.length) {
      elTarget.classList.add(this.playedClassValue)
    }
  }

  pauseVideoPlayer() {
    $('#video-player-controller')[0].pause()
  }

  // Communicate the server the video has been watched and apply a class to the elment if needed
  didWatch(targetElement, targetUrl) {
    HW.utils.performAjaxRequest(`/users/${this.userIdValue}/watch_video`, `url=${targetUrl}`, 'POST')
    this.markViewed(targetElement)
  }

  playVideoInModal(event) {
    event.preventDefault();

    // Only execute all this if we have an actual video url
    // Because we utilize this in both a click action and an
    // automatic load action (IE, intro videos), we need to
    // check if our element that the controller is installed
    // on has the data url, or if the event triggering the
    // action has the data url.  Look at both and, in a case
    // where for some reason NEITHER has a data url, then
    // just bail out entirely.
    let targetElement;
    let targetUrl;
    if(this.element?.dataset?.url) {
      targetElement = this.element
      targetUrl = this.element.dataset.url
    } else if (event.currentTarget?.dataset?.url) {
      targetElement = event.currentTarget
      targetUrl = event.currentTarget.dataset.url;
    } else {
      return
    }

    const videoController = $('#video-player-controller')
    // In some cases the autoplay is not working, let's mark the watch when the modal appear
    const markWatchEvent = this.markWatchOnLoadValue? "loadstart" : "play"
    videoController.on(markWatchEvent, () => {
      this.didWatch(targetElement, targetUrl);
    });

    $('#video-player-source').attr('src', targetUrl)
    videoController[0].load()

    // Try delaying the modal show by 250ms to see if the video is shown with the proper size already
    setTimeout(()=>{
      $('#video-player').modal();
    }, 250);
  }
} 