import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  ////////////////////
  // Lifecycle events
  ////////////////////
  connect() {
    this.element[this.identifier] = this
    $('[data-role=tagsinput]').tagsinput()
  }
}