// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { SPABase, createError, userId } from './helpers'

export const CANCEL_DELETE_PROJECT_FOLDER = 'project_folder/delete/cancel'

export const CREATE_PROJECT_FOLDER_REQUEST = 'CREATE_PROJECT_FOLDER_REQUEST'
export const CREATE_PROJECT_FOLDER_SUCCESS = 'CREATE_PROJECT_FOLDER_SUCCESS'
export const CREATE_PROJECT_FOLDER_FAILURE = 'CREATE_PROJECT_FOLDER_FAILURE'
export const createProjectFolder = ({ projectId, name }) => {
  return dispatch => {
    dispatch({ type: CREATE_PROJECT_FOLDER_REQUEST})

    const params = {
      project_folder: {
        project_id: projectId,
        name
      }
    }

    axios.post(`${SPABase}/users/${userId()}/project_folders`, params)
          .then(response => {
            dispatch({ type: CREATE_PROJECT_FOLDER_SUCCESS, response })
          }).catch(error => {
            dispatch(createError({ error, type: CREATE_PROJECT_FOLDER_FAILURE }))
          })
  }
}

export const DELETE_PROJECT_FOLDER_REQUEST = 'DELETE_PROJECT_FOLDER_REQUEST'
export const DELETE_PROJECT_FOLDER_SUCCESS = 'DELETE_PROJECT_FOLDER_SUCCESS'
export const DELETE_PROJECT_FOLDER_FAILURE = 'DELETE_PROJECT_FOLDER_FAILURE'
export const deleteProjectFolder = ({ id }) => {
  return dispatch => {
    dispatch({ type: DELETE_PROJECT_FOLDER_REQUEST })

    axios.delete(`${SPABase}/users/${userId()}/project_folders/${id}`)
          .then(response => {
            dispatch({
              type: DELETE_PROJECT_FOLDER_SUCCESS,
              response
            })
          }).catch(error => {
            dispatch(createError({ error, type: DELETE_PROJECT_FOLDER_FAILURE }))
          })
  }
}

export const UPDATE_PROJECT_FOLDER_REQUEST = 'UPDATE_PROJECT_FOLDER_REQUEST'
export const UPDATE_PROJECT_FOLDER_SUCCESS = 'UPDATE_PROJECT_FOLDER_SUCCESS'
export const UPDATE_PROJECT_FOLDER_FAILURE = 'UPDATE_PROJECT_FOLDER_FAILURE'
export const updateProjectFolder = ({ projectFolderId, name }) => {
  return dispatch => {
    dispatch({ type: UPDATE_PROJECT_FOLDER_REQUEST})

    const params = {
      project_folder: {
        name
      }
    }

    axios.put(`${SPABase}/users/${userId()}/project_folders/${projectFolderId}`, params)
          .then(response => {
            dispatch({ type: UPDATE_PROJECT_FOLDER_SUCCESS, response })
          }).catch(error => {
            dispatch(createError({ error, type: UPDATE_PROJECT_FOLDER_FAILURE }))
          })
  }
}