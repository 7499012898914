import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { editorArguments: Object }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      constraints.web.start_BANG_(this.editorArgumentsValue)
    }
  }
}
