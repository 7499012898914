import { combineReducers } from 'redux'

import { ADD_USER, ADD_USER_SUCCESS, UPDATE_EMAIL, UPDATE_LEVEL } from 'actions/projectManagement'
import { OPEN_MODAL } from 'actions/shared'

const INITIAL_STATE = { email: '', errorMessage: null, level: '1', requesting: false }

const form = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case ADD_USER_SUCCESS:
    case OPEN_MODAL: {
      return INITIAL_STATE

    } case ADD_USER: {
      return { ...state, requesting: true }

    } case UPDATE_EMAIL: {
      const { email } = action

      return { ...state, email, errorMessage: null }

    } case UPDATE_LEVEL: {
      const { level } = action
      
      return { ...state, level, errorMessage: null }

    } default:
      if(action.type.endsWith('_FAILURE') && action.message) {
        const { message } = action
        return { ...state, errorMessage: message, requesting: false }
      } else {
        return state
      }
  }
}

const rootReducer = combineReducers({
  form,
})

export default rootReducer
