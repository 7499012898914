import { Controller } from "@hotwired/stimulus";

const NEW_LINE = String.fromCharCode(13, 10);

export default class extends Controller {
  static targets = ['input', 'reset', 'submit']
  static values = { newRecord: Boolean }

  connect() {
    this.initialValue = this.inputTarget.value
    this.handleChange()
    this.inputTarget.focus()

    this.element.addEventListener('turbo:submit-start', () => this.disable())
    this.element.addEventListener('turbo:submit-end', () => this.reset())
  }

  disable() {
    this.inputTarget.setAttribute('readonly', true)
    this.resetTarget.setAttribute('disabled', true)
    this.submitTarget.setAttribute('disabled', true)
  }

  reset(event) {
    event?.preventDefault()
    this.inputTarget.removeAttribute('readonly')
    this.element.reset()
    this.resetTarget.removeAttribute('disabled')
    this.submitTarget.removeAttribute('disabled')
    this.dispatchInputEvent()
    this.inputTarget.focus()
  }

  handleChange(_event) {
    if (this.initialValue === this.trimmedValue()) {
      this.submitTarget.setAttribute('disabled', true)
      if (this.newRecordValue) {
        this.resetTarget.setAttribute('disabled', true)
      }
    } else {
      if(this.hasSubmitTarget) { this.submitTarget.removeAttribute('disabled') }
      if(this.hasResetTarget) { this.resetTarget.removeAttribute('disabled') }
    }
  }

  /**
  * Used to make sure all the combination: Opts+Enter, Meta+Enter and Shift+Enter
  * are adding a new line at cursor position. Only Shift+Enter is submitting
  * the form.
  * @param {Event} event representing the pressed key action
  */
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
        event.preventDefault()
        this.submit()
      } else if (event.shiftKey || event.metaKey || event.altKey) {
        event.preventDefault()
        this.insertNewLine()
      }
    }
  }

  /**
  * Clean up the current textarea value and return it. NB: it doesn't replace
  * the textarea contente.
  * @returns the string representing the current value of the form textarea,
  * if the textarea or the value are nil it returns an empty string.
  */
  trimmedValue() {
    const curVal = this.inputTarget.value || ""
    return curVal.trim()
  }

  showFieldText(event) {
    event.currentTarget.parentNode.querySelector('small').style = 'display: block;'
    event.currentTarget.placeholder = '';
  }

  hideFieldText(event) {
    event.currentTarget.parentNode.querySelector('small').style = 'display: none;'
    event.currentTarget.placeholder = event.currentTarget.dataset.placeholder;
  }

  submit() {
    const cleanValue = this.trimmedValue()
    if (this.initialValue !== cleanValue) {
      this.inputTarget.value = cleanValue
      this.element.dispatchEvent(new SubmitEvent('submit', { bubbles: true, cancelable: true }))
    }
  }

  insertNewLine() {
    const selStart = this.inputTarget.selectionStart
    this.inputTarget.setRangeText(NEW_LINE)
    this.inputTarget.selectionEnd = selStart + 1
    this.inputTarget.selectionStart = selStart + 1
    this.inputTarget.focus()
    this.dispatchInputEvent()
  }

  dispatchInputEvent(eventName = 'input') {
    this.inputTarget.dispatchEvent(new InputEvent(eventName, { bubbles: true, cancelable: true }))
  }
}