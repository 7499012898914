import { Controller } from "@hotwired/stimulus"

// This currently does not work for Safari!
// https://github.com/sindresorhus/linkify-urls/issues/24
// import * as linkifyUrls from "linkify-urls"

export default class extends Controller {
  static targets = ["searchSpace"]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.rerender()
    }
  }

  rerender() {
    this.searchSpaceTargets.forEach(target => {
      // target.innerHTML = linkifyUrls(target.innerHTML, { attributes: { target: '_blank' }})
    })
  }
}
