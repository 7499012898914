import axios from "axios"
import { SPABase, createError, planId, userId } from './helpers'

export const REQUIREMENTS_DIALOGS = 'REQUIREMENTS_DIALOGS'

export const BULK_DELETE_REQUIREMENTS_REQUEST = 'BULK_DELETE_REQUIREMENTs_REQUEST'
export const BULK_DELETE_REQUIREMENTS_SUCCESS = 'BULK_DELETE_REQUIREMENTS_SUCCESS'
export const BULK_DELETE_REQUIREMENTS_FAILURE = 'BULK_DELETE_REQUIREMENTS_FAILURE'
export const bulkDeleteRequirements = (requirementIds) => {
  return dispatch => {
    dispatch({ type: BULK_DELETE_REQUIREMENTS_REQUEST })

    return axios.delete(`${SPABase}/users/${userId()}/plans/${planId()}/requirements/bulk_delete`, { data: { ids: requirementIds } })
                .then(response => {
                  dispatch({ type: BULK_DELETE_REQUIREMENTS_SUCCESS, requirementIds, response })
                }).catch(error => {
                  dispatch(createError({ error, type: BULK_DELETE_REQUIREMENTS_FAILURE }))
                })
  }
}

export const CREATE_REQUIREMENT_REQUEST = 'CREATE_REQUIREMENT_REQUEST'
export const CREATE_REQUIREMENT_SUCCESS = 'CREATE_REQUIREMENT_SUCCESS'
export const CREATE_REQUIREMENT_FAILURE = 'CREATE_REQUIREMENT_FAILURE'
export const UPDATE_REQUIREMENT_REQUEST = 'UPDATE_REQUIREMENT_REQUEST'
export const UPDATE_REQUIREMENT_SUCCESS = 'UPDATE_REQUIREMENT_SUCCESS'
export const UPDATE_REQUIREMENT_FAILURE = 'UPDATE_REQUIREMENT_FAILURE'
export const saveRequirement = (stateData, rowIdx, cellKey = 'values') => {
  const { requirement, newParameters, sameValuesOverride, reqWarningOverride } = stateData
  const data = { requirement, newParameters, sameValuesOverride, reqWarningOverride }
  const action = requirement.is_new_row ? 'CREATE' : 'UPDATE'
  const method = requirement.is_new_row ? 'post' : 'put'

  let url = `${SPABase}/users/${userId()}/plans/${planId()}/requirements`
  if(!requirement.is_new_row)
    url = `${url}/${requirement.id}`

  return dispatch => {
    dispatch({ type: `${action}_REQUIREMENT_REQUEST`, updatingData: { updatingCell: cellKey, updatingRow: rowIdx } })

    return axios({method, url, data}).then(response => {
                  dispatch({ type: `${action}_REQUIREMENT_SUCCESS`, response })
                }).catch(error => {
                  dispatch({ type: `${action}_REQUIREMENT_FAILURE`, data: error.response.data, rowIdx, stateData })
                })
  }
}

export const DELETE_REQUIREMENT_REQUEST = 'DELETE_REQUIREMENT_REQUEST'
export const DELETE_REQUIREMENT_SUCCESS = 'DELETE_REQUIREMENT_SUCCESS'
export const DELETE_REQUIREMENT_FAILURE = 'DELETE_REQUIREMENT_FAILURE'
export const deleteRequirement = (requirement, rowIdx) => {
  return dispatch => {
    dispatch({ type: DELETE_REQUIREMENT_REQUEST })

    return axios.delete(`${SPABase}/users/${userId()}/plans/${planId()}/requirements/${requirement.id}`)
                .then(() => {
                  dispatch({ type: DELETE_REQUIREMENT_SUCCESS, rowIdx })
                }).catch(error => {
                  dispatch(createError({ error, type: DELETE_REQUIREMENT_FAILURE }))
                })
  }
}

export const FETCH_REQUIREMENTS_REQUEST = 'FETCH_REQUIREMENTS_REQUEST'
export const FETCH_REQUIREMENTS_SUCCESS = 'FETCH_REQUIREMENTS_SUCCESS'
export const FETCH_REQUIREMENTS_FAILURE = 'FETCH_REQUIREMENTS_FAILURE'
export const fetchRequirements = () => {
  return dispatch => {
    dispatch({ type: FETCH_REQUIREMENTS_REQUEST })

    return axios.get(`${SPABase}/users/${userId()}/plans/${planId()}/requirements`)
                .then(response => {
                  dispatch({ type: FETCH_REQUIREMENTS_SUCCESS, response })
                }).catch(error => {
                  dispatch(createError({ error, type: FETCH_REQUIREMENTS_FAILURE }))
                })
  }
}

export const IMPORT_REQUIREMENT_REQUEST = 'IMPORT_REQUIREMENT_REQUEST'
export const IMPORT_REQUIREMENT_SUCCESS = 'IMPORT_REQUIREMENT_SUCCESS'
export const IMPORT_REQUIREMENT_FAILURE = 'IMPORT_REQUIREMENT_FAILURE'
export const importRequirements = (file, forceImport) => {
  var form_data = new FormData();
  form_data.append('import_file', file);
  form_data.append('forceImport', forceImport);

  return dispatch => {
    dispatch({ type: IMPORT_REQUIREMENT_REQUEST })

    return axios.post(`${SPABase}/users/${userId()}/plans/${planId()}/requirements/import`, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                  dispatch({ type: IMPORT_REQUIREMENT_SUCCESS, response })
                }).catch(error => {
                  dispatch({ type: IMPORT_REQUIREMENT_FAILURE, data: error.response.data })
                })
  }
}

export const REORDER_REQUIREMENTS_REQUEST = 'REORDER_REQUIREMENTS_REQUEST'
export const REORDER_REQUIREMENTS_SUCCESS = 'REORDER_REQUIREMENTS_SUCCESS'
export const REORDER_REQUIREMENTS_FAILURE = 'REORDER_REQUIREMENTS_FAILURE'
export const reorderRequirements = (rowSourceIdx, rowTargetIdx, requirements) => {
  return dispatch => {
    // Shift our requirements
    if (rowTargetIdx >= requirements.length) {
      var k = rowTargetIdx - requirements.length + 1
      while (k--) {
        requirements.push(undefined)
      }
    }

    requirements.splice(rowTargetIdx, 0, requirements.splice(rowSourceIdx, 1)[0])

    const requirementIds = requirements.map(requirement => requirement.id)
    requirementIds.pop()

    dispatch({ type: REORDER_REQUIREMENTS_REQUEST, requirements })

    return axios.post(`${SPABase}/users/${userId()}/plans/${planId()}/requirements/reorder`, { requirementIds: requirementIds })
                .then(response => {
                  dispatch({ type: REORDER_REQUIREMENTS_SUCCESS })
                }).catch(error => {
                  dispatch({ type: REORDER_REQUIREMENTS_FAILURE })
                })
  }
}

export const TOGGLE_REQUIREMENT_CHECKBOX = 'TOGGLE_REQUIREMENT_CHECKBOX'
export const toggleRequirementCheckbox = (rowIdx) => {
  return {
    type: TOGGLE_REQUIREMENT_CHECKBOX,
    rowIdx: rowIdx
  }
}

export const TOGGLE_ALL_REQUIREMENTS_CHECKBOX = 'TOGGLE_ALL_REQUIREMENTS_CHECKBOX'
export const toggleAllRequirementsCheckbox = () => {
  return {
    type: TOGGLE_ALL_REQUIREMENTS_CHECKBOX
  }
}

export const UNFREEZE_FROZEN_SCENARIOS_REQUEST = 'UNFREEZE_FROZEN_SCENARIOS_REQUEST'
export const UNFREEZE_FROZEN_SCENARIOS_SUCCESS = 'UNFREEZE_FROZEN_SCENARIOS_SUCCESS'
export const UNFREEZE_FROZEN_SCENARIOS_FAILURE = 'UNFREEZE_FROZEN_SCENARIOS_FAILURE'
export const unfreezeFrozenScenarios = () => {
  return dispatch => {
    dispatch({ type: UNFREEZE_FROZEN_SCENARIOS_REQUEST })

    return axios.post(`${SPABase}/users/${userId()}/plans/${planId()}/requirements/thaw`)
                .then(response => {
                  dispatch({ type: UNFREEZE_FROZEN_SCENARIOS_SUCCESS, response })
                }).catch(error => {
                  dispatch(createError({ error, type: UNFREEZE_FROZEN_SCENARIOS_FAILURE }))
                })
  }
}