import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = {
    target: String
  }

  ////////////////////
  // Lifecycle events
  ////////////////////
  submitEnd(e) {
    if (this.hasTargetValue) {
      if (e.detail.success) {
        // Highlight
        $(this.targetValue).effect('highlight', {}, 3000);
      }
    }
  }
}