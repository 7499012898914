/**
 * Hexawise Stimulus controller, base of all our controllers
 * @module Controllers/hex_controller
 * @file app/javascript/controllers/hex_controller.js
 * @author Iacopo Carraro <iacopo.carraro@hexawise.com>
 * @exports HexController
 */

import { Controller } from '@hotwired/stimulus'
import { useLogging } from 'controllers/mixins/logging'
import { useI18n } from 'controllers/mixins/i18n'
import { printAsciiArt } from './utils/asciiArt'

const _stimulusDebug = '_stimulusDebug'
const debug = process.env.RAILS_ENV === 'development' ||       // Is dev env
              Boolean(window[_stimulusDebug]) ||               // Forced debug from console
              window.location.search.includes(_stimulusDebug)  // Forced debug by URL

/**
 * HexController class
 * @class
 * @classdesc This class should be the base for every Stimulus controller we create
 * @extends Controller
 */
class HexController extends Controller {
  static errorMessage = `Something went wrong, we have been informed of the error. Please try again later.<br/>` +
                        `If the problem persists, please contact support.</a>`
  hexDebug = debug ||
             Boolean(window[`${this.identifier}${_stimulusDebug}`]) ||
             window.location.search.includes(`${this.identifier}${_stimulusDebug}`)
  hexEnvironment = process.env.RAILS_ENV

  constructor(...args) {
    super(...args)
    // Setup logging using our mixin
    useLogging(this, this.hexDebug)
    // Setup i18n
    useI18n(this)
    // Wrap Stimulus lifecycle methods with a new function to avoid
    // using the methods directly, this is to avoid having to remember
    // to call super.initialize(), super.connect(), super.disconnect()
    this.initialize = this._initialize.bind(this, this.initialize)
    this.connect = this._connect.bind(this, this.connect)
    this.disconnect = this._disconnect.bind(this, this.disconnect)
  }

  _initialize = (initializeFn) => {
    if (typeof initializeFn === "function") {
      return initializeFn.call(this)
    }
  }

  _connect = (connectFn) => {
    this.element[this.identifier] = this
    if (typeof connectFn === "function") {
      return connectFn.call(this)
    }
  }

  _disconnect = (disconnectFn) => {
    delete this.element[this.identifier]
    if (typeof disconnectFn === "function") {
      return disconnectFn.call(this)
    }
  }

  get hexDevEnv() {
    return this.hexEnvironment === 'development'
  }

  /**//**//**//**//**//**/
  /*   Error handling   */
  /**//**//**//**//**//**/

  _serializeControllerValues() {
    return Object.values(this.valueDescriptorMap).map(({name}) => {
      return `${name} => ${JSON.stringify(this[name], null, 2)}`
    }).filter(Boolean)
  }

  _stackTrace(error) {
    if (error?.stack) {
      return error.stack
    }
    // If a stack is not provided by the error, let's create one
    return (new Error).stack
  }

  _composeDebugErrorMessage(error, extraData) {
    let errMsg = ''
    if (this.hexDebug) {
      this.dbg(error, extraData)
      const wrap = (...msgs) => `<pre>${msgs.join('\n')}</pre>`
      if (error instanceof Error) {
        errMsg += wrap(error?.name, error?.message)
      } else {
        errMsg += wrap(JSON.stringify(error, null, 2))
      }
      errMsg += wrap(this._stackTrace(error))
      errMsg += wrap('Controller values:', ...this._serializeControllerValues())
    }
    return errMsg
  }

  _composeErrorMessage(error, extraData) {
    return `<pre class="hex-error-message">` + this.constructor.errorMessage + this._composeDebugErrorMessage(error, extraData) + `</pre>`
  }

  /**
   * When a controller intercept an error it should call this method
   * to make sure it's reported to us with the necessary context.
   * @method handleError
   * 
   * @param {Error} error The 'catched' error
   * @param {object} extraData More data that are helpful to the rest
   * @returns {string} A generic error message, if in dev the error will be logged to console too
   * 
   * @example Report errors when an ajax call fails:
   * fetch("/").then((resp) => console.log(resp), (error) => {
   *   const errorMessage = this.handleError(error, {planId: this.planIdValue, userId: this.userIdValue})
   *   display(errorMessage)
   * })
   */
  handleError(error, extraData) {
    // @TODO: Add error reporting via Honeybadger
    return this._composeErrorMessage(error, extraData)
  }

  /**//**//**//**//**//**/
  /*        Help        */
  /**//**//**//**//**//**/
  static Help() {
    printAsciiArt()
    console.group(`Welcome to Hexawise Stimulus Help menu.`)
    console.log(`Stimulus controllers are always in debug mode while in development, but never by default on production.`)
    console.log(`To activate debug you can use 2 methods:`)
    console.log(` - set \`window.${_stimulusDebug} = true;\` as soon as the page starts loading`)
    console.log(` - add \`${_stimulusDebug}\` to the url's search (prefix with & or ?)`)
    console.log(`To debug specific controllers instead of all of them, perfix \`${_stimulusDebug}\` with the controller identifier, for example for auto_scripts/TablePreviewController use \`auto-scripts--table-preview${_stimulusDebug}\`.`)
    console.groupEnd()
  }
  /**//**//**//**//**//**/
}

// Expose help functions
const HelpPrint = HexController.Help
window.HexControllerHelp = HelpPrint;
export { HelpPrint as HexControllerHelp };
// Export the module default
export default HexController;
