/* global Intercom */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  expertChat() {
    Intercom('show');
  }

  scheduleCall() {
    window.open('https://calendly.com/hexawise/hexawise-working-session');
  }
}
