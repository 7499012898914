import { FETCH_VALUE_PAIRS_REQUEST, FETCH_VALUE_PAIRS_SUCCESS, CREATE_VALUE_PAIR_SUCCESS, DELETE_VALUE_PAIR_SUCCESS, DELETE_ALL_VALUE_PAIRS_SUCCESS, TOGGLE_IMPLED_PAIRS_LIST_REQUEST } from 'actions/valuePairs'

const initialState = {
  loading: false,
  modifiable: false,
  impliedPairsVisible: false,
  valuePairs: {
    userCreatedPairs: [],
    impliedPairs: []
  },
  parameters: [],
  columnCount: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VALUE_PAIRS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_VALUE_PAIRS_SUCCESS:
    case CREATE_VALUE_PAIR_SUCCESS:
    case DELETE_VALUE_PAIR_SUCCESS:
    case DELETE_ALL_VALUE_PAIRS_SUCCESS:
      return {
        ...state,
        ...action.response.data,
        loading: false
      }
    case TOGGLE_IMPLED_PAIRS_LIST_REQUEST:
      return {
        ...state,
        impliedPairsVisible: !state.impliedPairsVisible
      }
    default:
      return state
  }
}