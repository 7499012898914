import { property as prop, propertyOf as propOf, union, isNull, isUndefined } from 'underscore/underscore';

/**
 * Creates a text node given a string of text
 * @name DOM.element.buildTextNode
 * @function buildTextNode
 * @param {string} textContent The text string
 * @returns {Node} An HTML text node with the text string as content
 * @example
 * buildTextNode('Some text')
 * // Some text
 *
 * buildTextNode('Hello XSS <script>alert("hello");</script>')
 * // Hello XSS &lt;script&gt;alert(&quote;hello&quote;);&lt;/script&gt;
 */
export const buildTextNode = (textContent = "") => {
  return document.createTextNode(textContent)
}

/**
 * Parse a string representing a dom node selector, supports only: tagName, id and classList
 * @name DOM.element.parseTag
 * @function parseTag
 * @param {string} elStr The element tag string
 * @returns {object} An object with 3 keys: tagName, id (can be null), classList (can be null)
 * @example
 * // Simple div
 * parseTag()
 * // {tagName: 'DIV', id: null, classList: []}
 *
 * // Parse an element with only a class
 * parseTag('.my-class')
 * // {tagName: 'DIV', id: null, classList: ['my-class']}
 *
 * // Parse an element with only an id
 * parseTag('SPAN#uid')
 * // {tagName: 'SPAN', id: 'uid', classList: []}
 *
 * // Parse an element with tag name and the id mixed in the classes
 * parseTag('UL.pre-class#uid.post-class')
 * // {tagName: 'UL', id: 'uid', classList: ['pre-class', 'post-class']}
 *
 * // Parse an element with tag name, an id and then a list of classes
 * parseTag('CUSTOM#myIdentifiedSpan.class-x.class-y')
 * // {tagName: 'CUSTOM', id: 'uid', classList: ['class-x', 'class-y']}
 *
 */
export const parseTag = (elStr = null) => {
  elStr ??= ""
  const splitId = (dots) => dots.split("#")
  const [strTagName, ...tmpClassList] = elStr.split(".")
  let id = null
  // Create the new list of splitted classes cleaning the empty strings out
  // this might still be dirty of the id
  let classList = tmpClassList.filter(prop("length"))
  const [tmpTagName, tmpElId] = splitId(strTagName)
  id = tmpElId
  if (classList?.length) {
    classList = classList.map((clId) => {
      const [cl, ci] = splitId(clId)
      id ??= ci
      return cl?? clId
    })
  }
  const tagName = ( !tmpTagName || !tmpTagName?.length ) ? "DIV" : tmpTagName
  return {
    tagName,
    id,
    classList
  }
}

/**
 * Create a Node element with a single function call and lots of fancy stuff
 * @name DOM.element.buildHTMLElement
 * @function buildHTMLElement
 * @param {string} elementTag the tag, accept every formatted string as per @function parseTag above
 * @param {rawText} innerText optional: the text to add to the node, no HTML tags or formatting, this is safe for XSS since it creates a text node and append it.
 * @param {object} opts
 *   - id,
 *   - classList {Array} ['a', 'b'],
 *   - classMap {object} {a: true, b: thisIsTrue(), c: thisIsFalse()},
 *   - dataSet {object} {controller: 'my-stimulus-contr'},
 *   - style {object} {'display': 'none'},
 *   - children {Array} a list of Node elements to attach,
 *   - innerHTML {String} text to insert, this is ignored if `children` is present,
 *   - tipped: {Boolean} use `title` attribute as text for a Tipped tooltip, the `title` attribute is removed if this is true to prevent dup tooltip
 *   - text: {String} TextNode content, will be set via innerText,
 *   - attributes {object} other attributes: `href`, `title`, `target`, etc...,
 *   - events {object} a map where keys are the event name (`click`, `change`, `drag`, etc..) and the value are the listener funcitons
 * @returns {HTMLNode} the HTML DOM node ready to append to the tree
 * @example
 * // Create an element is as simple as:
 * buildHTMLElement()
 * < <div></div<
 *
 * @example
 * // Create en elmeent with id and classes
 * buildHTMLElement('#el-id.my-class.my-other-class')
 * // <div id='el-id' class='my-class my-other-class'></div>
 *
 * @example
 * // Create an elment with some fancy attributes
 * buildHTMLElement('UL', {classMap: {a: true, b: thisIsNotTrue()},
 *           dataSet: {'controller': 'my-stimulus-contr', 'my-stimulus-contr-target': 'myStimEl'},
 *           children: [buildHTMLElement('li', 'the good'), buildHTMLElement('li', 'the bad'), buildHTMLElement('li', 'the ugly')]
 *          })
 * // return
 *  <ul class='a' data-controller='my-stimulus-contr' data-my-stimulus-contr-target='myStimEl'>
 *    <li>the good</li>
 *    <li>the bad</li>
 *    <li>the ugly</li>
 *  </ul>
 */
export const buildHTMLElement = (elementTag = null, innerText = null, opts = null) => {
  // Fix optional parameters
  if (isNull(opts) || isUndefined(opts)) {
     // isUndefined is needed because `typeof null === "object" :facepalming:
    if (!isNull(innerText) && typeof innerText === "object") {
      opts = innerText
      innerText = opts?.text
    } else {
      opts = {}
    }
  }
  const {
    id, classList, classMap, dataSet, style, children, innerHTML, tipped, text: rawText, attributes, events, disabled, checked
  } = opts
  innerText ??= rawText
  const { tagName, classList: splitClassList, id: splitId } = parseTag(elementTag)
  // Create the element
  const newEl = document.createElement(tagName);
  // Element id
  const elementId = id || splitId
  if (elementId) {
    newEl.setAttribute('id', elementId)
  }
  const classesFromMap = classMap && Object.keys(classMap).filter(propOf(classMap))
  // Safe merge all classes with concat
  const elementClassList = union(classList, splitClassList, classesFromMap).filter(Boolean)
  if (elementClassList?.length) {
    newEl.classList.add(...elementClassList);
  }
  // Tooltips
  if (tipped && attributes.title) {
    // Attach a stimulus controller for the tooltip directly
    newEl.dataset.controller = 'tooltip'
    newEl.dataset.tooltipTitleValue = attributes.title
    // Remove title attribute to avoid the double tooltip
    delete attributes.title
  }
  // Dataset
  if (dataSet) {
    Object.keys(dataSet).forEach((k) => newEl.setAttribute(`data-${k}`, dataSet[k]));
  }
  // Style
  if (style) {
    Object.keys(style).forEach((k) => {
      newEl.style[k] = style[k];
    });
  }
  // Other attributes
  if (attributes) {
    Object.keys(attributes).forEach((k) => newEl.setAttribute(k, attributes[k]));
  }
  // Checked & Disabled
  if (checked === true) { newEl.setAttribute('checked', true) }
  if (disabled === true) { newEl.setAttribute('disabled', true) }
  // Append children / set innerHTML
  if (Array.isArray(children) && newEl.append) {
    newEl.append(...children);
  } else if (innerHTML) {
    newEl.innerHTML = innerHTML;
  }
  // innerText
  if (innerText) {
    newEl.append(buildTextNode(innerText));
  }
  // Event listeners
  if (events) {
    Object.keys(events).forEach((evName) => newEl.addEventListener(evName, events[evName]));
  }
  return newEl;
};

/**
 * Given an array, string, or object of JSON nodes, build up an HTML node tree
 * @name DOM.element.buildHTMLNodes
 * @function buildHTMLNodes
 * @param {object[]|string|object} The array, string, or object of JSON nodes to build
 * @returns {Node} An HTML node tree as created
 */
 export const buildHTMLNodes = (nodeEl) => {
  if (Array.isArray(nodeEl)) {
    // If this is an array, iterate over the members of the array and build them up
    // to be the content of a new span
    const children = nodeEl.map(buildHTMLNodes);
    return buildHTMLElement('span', { children });
  } else if (typeof nodeEl === 'string') {
    // If this is a string, just create a new span with the content of the string
    return buildHTMLElement('span', nodeEl);
  } else {
    // If this node has any children, go ahead and build them now and then assign them
    // to the opts.children property.
    const children = nodeEl?.children?.map(nodeChild => buildHTMLNodes(nodeChild));

    // If this node has a dataSet -> content property, and that property is an
    // array, then iterate through it and build up the nodes and then, taking their
    // outerHTML, assign it back to the content.  This is the case for popovers and
    // tooltips.
    const {opts = {}} = nodeEl;
    if (Array.isArray(opts.dataSet?.content)) {
      // data-content content, for popovers and tooltips
      const content = buildHTMLNodes(opts.dataSet.content);
      opts.dataSet.content = content.outerHTML;
    }

    // Return back our new node
    return buildHTMLElement(nodeEl.node, {...opts, children});
  }
}

// For backword compatibility
export {
  buildHTMLElement as el,
  buildHTMLNodes as els,
  buildTextNode as text
}
