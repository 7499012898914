import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['conventionTooltip', 'parameterizedField']

  connect() {
    if(this.hasConventionTooltipTarget) {
      Tipped.create(`#${this.conventionTooltipTarget.id}`, this.conventionTooltipTarget.dataset.tippedBody, {
        skin: 'light',
        maxWidth: 400,
        position: { target: 'bottomleft' }
      });
    }
  }

  insertFieldValue(event) {
    if(event.type === "click" || (event.type === "keydown" && event.ctrlKey && event.keyCode === 89)) {
      const targetData = event.target.dataset;

      // Grab the current scroll position
      const auto_position = $(targetData.textArea).scrollTop;
      const test_script = $(targetData.textArea);
      HW.widgets.AutoScript.insertAtCaret(test_script, '{' + $(targetData.scriptParameters).val() + '}');

      var control = $(targetData.scriptParameters)[0];

      if(control.selectedIndex != control.options.length - 1) {
        control.selectedIndex = control.selectedIndex + 1;
      }

      // Restore the old scroll position
      $(targetData.textArea).scrollTop = auto_position;
    }
  }

  changeFieldType(event) {
    if(this.hasParameterizedFieldTarget) {
      if(event.target.value === "text") {
        this.parameterizedFieldTarget.classList.remove('hidden');
      } else {
        this.parameterizedFieldTarget.classList.add('hidden');
      }
    }
  }
}