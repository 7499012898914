import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = ['noNotesMessage', 'notesContainer', 'neverShowForPlan', 'editLink', 'newNoteForm', 'noteItem']
  static values = {
    planId: String,
    editable: Boolean
  }

  connect() {
    if (this.hasNoNotesMessageTarget && this.hasNotesContainerTarget) {
      this.showHideNoNotesMessageTarget()
      // Hide or show the edit/delete buttons
      this.updateEditable()
      // Make sure the view is always laoded showing the last note
      this.scrollToBottom()
    }
  }

  noteItemTargetConnected(_el) {
    this.showHideNoNotesMessageTarget()
    this.updateEditable()
  }

  noteItemTargetDisconnected(_el) {
    this.showHideNoNotesMessageTarget()
  }

  scrollToBottom() {
    setTimeout(() => {
      this.notesContainerTarget.querySelector(':scope > turbo-frame:last-of-type')?.scrollIntoView()
    }, 500)
  }

  toggleElement(el, hide) {
    if (hide) {
      el.classList.add('hidden')
    } else {
      el.classList.remove('hidden')
    }
  }

  showHideNoNotesMessageTarget(_) {
    if (this.hasNoNotesMessageTarget && this.hasNotesContainerTarget) {
      const notesItems = this.notesContainerTarget.querySelectorAll('.card.note, .daform')
      this.toggleElement(this.noNotesMessageTarget, notesItems.length)
    }
  }

  neverShowForPlanClicked() {
    if (this.hasNeverShowForPlanTarget) {
      if (this.neverShowForPlanTarget.checked) {
        document.cookie = `notes_message_${this.planIdValue}=true; max-age=315576000`
      } else {
        document.cookie = `notes_message_${this.planIdValue}=; max-age=0`
      }
    }
  }

  focusOnCreateNoteBody() {
    // Wait 500ms before we do this since some events (such as modal dismiss)
    // wind up pulling focus. This ensures that the page is ready and won't have
    // the focus yanked away from it immediately.
    setTimeout(() => {
      document.getElementById('new_note').focus();
    }, 500)
  }

  checkNotesAndFocus() {
    this.focusOnCreateNoteBody()
    this.showHideNoNotesMessageTarget()
  }

  updateEditable() {
    this.toggleElement(this.newNoteFormTarget, !this.editableValue)
    this.editLinkTargets.map(el => this.toggleElement(el, !this.editableValue))
  }
}
