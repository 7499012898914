import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = {
    modalId: String
  }

  ////////////////////
  // Lifecycle events
  ////////////////////
  connect() {
    // Remove any popovers that may exist in the view
    $('body > .popover.show').popover('hide')

    // Clear out the source such that on navigation back we don't display the modal window again
    this.modalTurboFrame.removeAttribute("src");
    $(this.currentModalId).modal();
  }

  dismiss() {
    $(this.currentModalId).modal('hide');
  }

  submitEnd(e) {
    if (e.detail.success) {
      dispatchEvent(new CustomEvent('userGridUpdate', { bubbles: true }));
      dispatchEvent(new CustomEvent('organizationGridUpdate', { bubbles: true }));
      this.dismiss();
    }
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='hexawise-modal-container']");
  }

  get currentModalId() {
    if (this.hasModalIdValue) {
      return `#${this.modalIdValue}`
    } else {
      return '#hexawise-modal'
    }
  }
}