import axios from "axios"
import { SPABase, createError, planId, userId } from './helpers'
import { askToConfirm, displayError, closeModal } from './shared'

export const CREATE_CONSTRAINTS_MODAL = 'CREATE_CONSTRAINTS_MODAL'

const refreshNPVWarningBanner = (shouldShowNPVWarningDialog) => {
  HW.utils.App.refreshNPVWarningBanner(shouldShowNPVWarningDialog);
}

export const TOGGLE_IMPLED_PAIRS_LIST_REQUEST = 'TOGGLE_IMPLED_PAIRS_LIST_REQUEST'
export const toggleImpliedPairsList = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_IMPLED_PAIRS_LIST_REQUEST })
  }
}

export const FETCH_VALUE_PAIRS_REQUEST = 'FETCH_VALUE_PAIRS_REQUEST'
export const FETCH_VALUE_PAIRS_SUCCESS = 'FETCH_VALUE_PAIRS_SUCCESS'
export const FETCH_VALUE_PAIRS_FAILURE = 'FETCH_VALUE_PAIRS_FAILURE'
export const fetchValuePairs = () => {
  return dispatch => {
    dispatch({ type: FETCH_VALUE_PAIRS_REQUEST })

    return axios.get(`${SPABase}/users/${userId()}/plans/${planId()}/constraints`)
                .then(response => {
                  dispatch({ type: FETCH_VALUE_PAIRS_SUCCESS, response })
                }).catch(error => {
                  dispatch(createError({ error, type: FETCH_VALUE_PAIRS_FAILURE }))
                })
  }
}

export const CREATE_VALUE_PAIR_REQUEST = 'CREATE_VALUE_PAIR_REQUEST'
export const CREATE_VALUE_PAIR_SUCCESS = 'CREATE_VALUE_PAIR_SUCCESS'
export const CREATE_VALUE_PAIR_FAILURE = 'CREATE_VALUE_PAIR_FAILURE'
export const createValuePair = (constraint, onSuccessCallback, reqWarningOverride=false) => {
  return dispatch => {
    dispatch({ type: CREATE_VALUE_PAIR_REQUEST })
    const startTime = Date.now()

    return axios.post(`${SPABase}/users/${userId()}/plans/${planId()}/constraints`, { constraint, req_warning_override: reqWarningOverride })
                .then(response => {
                  const finishTime = Date.now()

                  // Set a timeout that will run everything either after 1.5 seconds, or immediately if
                  // 1.5 seconds have already passed.
                  setTimeout(() => {
                    dispatch({ type: CREATE_VALUE_PAIR_SUCCESS, response, pair_type: constraint.type })
                    refreshNPVWarningBanner(response.data.show_npv_warning_dialog);
                    onSuccessCallback()
                  }, 1500-(finishTime - startTime))
                }).catch(error => {
                  if(error.response.data.requirement_error) {
                    dispatch(askToConfirm({
                      title: 'Conflict between Requirement and Constraint',
                      message: error.response.data.requirement_error,
                      allowHTML: true,
                      actions: {
                        cancel: {
                          action: () => {
                            dispatch(closeModal())
                            onSuccessCallback()
                          }
                        }, proceed: {
                          action: () => {
                            dispatch(closeModal())
                            dispatch(createValuePair(constraint, onSuccessCallback, true))
                          },
                          text: 'Create Anyway'
                        }
                      }
                    }))
                  } else {
                    dispatch(displayError({
                      title: 'Error Creating Constraint',
                      message: error.response.data.error,
                      hideCancelButton: true,
                      action: () => {
                        dispatch(closeModal())
                        onSuccessCallback()
                      }
                    }))
                  }
                  dispatch(createError({ error, type: CREATE_VALUE_PAIR_FAILURE }))
                })
  }
}

export const DELETE_VALUE_PAIR_REQUEST = 'DELETE_VALUE_PAIR_REQUEST'
export const DELETE_VALUE_PAIR_SUCCESS = 'DELETE_VALUE_PAIR_SUCCESS'
export const DELETE_VALUE_PAIR_FAILURE = 'DELETE_VALUE_PAIR_FAILURE'
export const deleteValuePair = (id) => {
  return dispatch => {
    dispatch({ type: DELETE_VALUE_PAIR_REQUEST })

    return axios.delete(`${SPABase}/users/${userId()}/plans/${planId()}/constraints/${id}`)
                .then(response => {
                  dispatch({ type: DELETE_VALUE_PAIR_SUCCESS, response })
                  refreshNPVWarningBanner(response.data.show_npv_warning_dialog);
                }).catch(error => {
                  dispatch(createError({ error, type: DELETE_VALUE_PAIR_FAILURE }))
                })
  }
}

export const DELETE_ALL_VALUE_PAIRS_REQUEST = 'DELETE_ALL_VALUE_PAIRS_REQUEST'
export const DELETE_ALL_VALUE_PAIRS_SUCCESS = 'DELETE_ALL_VALUE_PAIRS_SUCCESS'
export const DELETE_ALL_VALUE_PAIRS_FAILURE = 'DELETE_ALL_VALUE_PAIRS_FAILURE'
export const deleteAllValuePairs = () => {
  return dispatch => {
    dispatch({ type: DELETE_ALL_VALUE_PAIRS_REQUEST })

    return axios.delete(`${SPABase}/users/${userId()}/plans/${planId()}/constraints/destroy_all`)
                .then(response => {
                  dispatch({ type: DELETE_ALL_VALUE_PAIRS_SUCCESS, response })
                  refreshNPVWarningBanner(response.data.show_npv_warning_dialog);
                }).catch(error => {
                  dispatch(createError({ error, type: DELETE_ALL_VALUE_PAIRS_FAILURE }))
                })
  }
}