import HexController from 'controllers/hex_controller'

export default class TestResultsController extends HexController {
  static values = {
    conditional: Boolean, // Should we render the results table
    exists: Boolean, // Are there any computed results
    fetchOnceUrl: String,
    combinationsUrl: String,
    changeStrengthUrl: String
  }

  static targets = ['previewContainer', 'errorMessage', 'loadingContainer']

  fetchStates = {
    ready: 'ready',
    fetching: 'fetching-data',
    fetched: 'fetch-succeed',
    errored: 'fetch-error',
  }

  /* Controller lifecycle */
  connect() {
    this.fetchInit()
    this.containerStateChange(this.fetchStates.ready)
  }

  containerStateChange(toClass) {
    const clss = Object.values(this.fetchStates).map((c) => `${this.identifier}--${c}`)
    this.element.classList.remove(...clss)
    this.element.classList.add(`${this.identifier}--${toClass}`)
  }
  /* ******************** */

  /* TABLE DATA EXCHANGE */
  get preview() {
    return this.previewContainerTarget['auto-scripts--table-preview']
  }

  get tableData() {
    return this.preview?.tableData
  }

  set tableData(data) {
    if (this.preview) {
      this.preview.updateTableData(data)
    }
  }
  /* ******************** */

  /* Fetch data and handle responses */
  fetchInit() {
    if (!document.documentElement.hasAttribute('data-turbo-preview')) {
      if (this.conditionalValue) {
        let fetchOnce = this.existsValue

        if (fetchOnce) {
          this.fetchResults(this.fetchOnceUrlValue)
          fetchOnce = false
        }
      }
    }
  }

  fetchSuccessCallback = (data) => {
    this.hideLoadingContainer()
    this.dbg('Data fetch succeeded')
    this.containerStateChange(this.fetchStates.fetched)
    this.tableData = data
  }

  fetchErrorCallback = (request, textStatus, errorThrown) => {
    this.dbg({request, textStatus, errorThrown})
    this.hideLoadingContainer()
    this.containerStateChange(this.fetchStates.errored)
    const errMsg = this.handleError(errorThrown, {request, textStatus})
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = errMsg
    }
  }

  fetchResults(url) {
    this.showLoadingContainer()
    this.containerStateChange(this.fetchStates.fetching)
    window.HW.utils.performAjaxRequest(url,
      undefined,
      'GET',
      this.fetchSuccessCallback,
      this.fetchErrorCallback,
      'JSON');
  }
  /* ******************* */

  /* Strength change handler */
  strengthChange(event) {
    const strength = parseInt(event?.target?.value, 10)
    if (!strength) {
      Turbo.visit(this.combinationsUrlValue)
    } else {
      this.fetchResults(this.changeStrengthUrlValue.replace('$1', strength))
    }
  }
  /* *********************** */

  /* Loading spinner control */
  hideLoadingContainer() {
    if (this.hasLoadingContainerTarget) {
      this.loadingContainerTarget.style.display = 'none';
    }
  }

  showLoadingContainer() {
    if (this.hasLoadingContainerTarget) {
      this.loadingContainerTarget.style.display = 'block';
    }
  }
  /* *********************** */
}
