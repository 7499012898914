import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = { title: String, options: Object, position: String}

  connect() {
    this.element[this.identifier] = this

    const options = {
      behavior: 'hide',
      maxWidth: 350,
      stem: false,
      position: {
        target: 'bottommiddle'
      },
      size: 'large',
      skin: 'dark'
    }

    const position = { position: { target: this.positionValue || options.position.target } }
    const mergedOptions = { ...options, ...position, ...this.optionsValue }

    Tipped.create(this.element, this.titleValue, mergedOptions)
  }
}
