import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.lineHeight = 32
    this.baseHeight = this.getTextareaBoundary() + (this.lineHeight * 2)

    this.resize()
    this.element.addEventListener('turbo:submit-end', () => this.resize())
  }

  resize(event) {
    this.inputTarget.style.height = `${this.baseHeight}px`
    const height = this.inputTarget.scrollHeight
    const numberOfLines = Math.floor(height / this.lineHeight)

    const newHeight = ((numberOfLines - 1) * 32) + this.baseHeight

    this.inputTarget.style.height = `${newHeight}px`
  }

  getTextareaBoundary() {
    const computedStyles = window.getComputedStyle(this.inputTarget)
    const borderTop = computedStyles.getPropertyValue('border-top-width')
    const borderBottom = computedStyles.getPropertyValue('border-bottom-width')
    const marginTop = computedStyles.marginTop
    const marginBottom = computedStyles.marginBottom
    const paddingTop = computedStyles.paddingTop
    const paddingBottom = computedStyles.paddingBottom

    return parseFloat(borderTop) + parseFloat(borderBottom) + parseFloat(marginTop) + parseFloat(marginBottom) + parseFloat(paddingTop) + parseFloat(paddingBottom)
  }
}