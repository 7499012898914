import { default as HexMindMap } from '../mind_map'
import { linkHorizontal } from 'd3-shape'

export default class Node {
  // Reference to the linkHorizontal function, while inverting our
  // x and y coordinates to create a tree that is actually horizontal
  // as opposed to vertical with the spread of the tree being horizontal
  static diagonal = linkHorizontal().x(d => d.y).y(d => d.x)

  //////////////////////////
  // Node Links Display
  //////////////////////////

  // Grabs the CSS styles for the circles that the user can click on
  // to show/hide the children of a given branch.
  static nodeLinkCircleStyles(node) {
    if(!node.children && !node._children) return "display: none;";

    var style = HexMindMap.styles['nodeCircle'];

    if(node.parent) {
      style += HexMindMap.styles[`hexawise_color_${node.data.color_idx}`]
    }

    if(node.data.collapsed) {
      style += HexMindMap.styles[`hexawise_collapsed_${node.data.color_idx}`]
    }

    return style
  }

  // Just returns the display name label (which has been trimmed) for a node
  // so long as the node is not our plan root node.
  static nodeLinkTextDisplayLabel(node) {
    if (node.depth > 0) return node.data.displayName;
  }

  //////////////////////////
  // Node Link Lines Display
  //////////////////////////

  // Calls the appropriate function for our path lines for a given link between
  // two nodes, dependent on if this node has a depth greater than 1 (that is, not
  // the root and not a parameter node) and it does not have any children, or not.
  static nodeLinkPath(linkGroup, parent) {
    if(linkGroup.target.depth > 1 && !linkGroup.target.children) {
      return this.nodeLinkExtendedDiagonal(linkGroup, parent)
    } else {
      return this.nodeLinkLineDiagonal(linkGroup.source, linkGroup.target, true, parent)
    }
  }

  // Generates just a standard link between two nodes.  We offset the node to accomodate for
  // generating our "flat" section that we rest the node name on for better aesthetics, and then
  // draw a line for the length of where we offset to the end of where the line would be.
  static nodeLinkLineDiagonal(source, target, shouldOffset, parent) {
    var sourceNode = {
      x: source.x,
      y: source.y
    }

    let offset = shouldOffset ? parent.displayOffset : 0

    if(target.data.direction == "left") {
      offset *= -1;
    }

    var targetNode = {
      x: target.x,
      y: target.y - offset
    }

    return `${this.diagonal({source: sourceNode,target: targetNode})}L${target.y},${target.x}`
  }

  // Generates a link between two nodes that has been extended
  static nodeLinkExtendedDiagonal(linkGroup, parent) {
    let target = $(`${parent.selectorId} [data-id=${linkGroup.target.id}]`)

    if(target.length) {
      let textBBox = target[0].getBBox();
      let textWidth = textBBox.width;

      let offset = parent.displayOffset
      let newWidth = ((offset - textWidth) / 2) + textWidth

      if(linkGroup.target.data.direction == "left") {
        offset *= -1;
      } else {
        newWidth *= -1;
      }

      var sourceNode = {
        x: linkGroup.source.x,
        y: linkGroup.source.y
      }

      var targetNode = {
        x: linkGroup.target.x,
        y: linkGroup.target.y - offset
      }

      return `${this.diagonal({source: sourceNode,target: targetNode})}L${targetNode.y - newWidth},${targetNode.x}`
    } else {
      return
    }
  }

  //////////////////////////
  // Node Events
  //////////////////////////

  // When a click event occurs on a node, assuming this node has children or _children attributes,
  // then we will swap which attribute exists with the complimentary to alternate between a node
  // having children or not.
  static toggleChildrenForNode(node) {
    if(!node.children && !node._children) return;

    if(node.children.length) {
      node._children = node.children
      node.children = []
      node.data.collapsed = true
    } else if (node._children.length) {
      node.children = node._children
      node._children = [];
      node.data.collapsed = false
    }
  }
}