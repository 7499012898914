export const COPY_PLAN = 'COPY_PLAN'
export const EDIT_PLAN = 'EDIT_PLAN'
export const IMPORT_HELP = 'IMPORT_HELP'
export const MANAGE_PLANS = 'MANAGE_PLANS'
export const NEW_PLAN = 'NEW_PLAN'
export const SHARE_PLAN = 'SHARE_PLAN'
export const NOTES = 'NOTES'
export const PROJECT_MANAGEMENT = 'MANAGE_PROJECTS'
export const REVISIONS = 'REVISIONS'
export const REVERT_PLAN = 'REVERT_PLAN'

import axios from 'axios'
import React from 'react'
import { userId, planId, SPABase, createError } from './helpers'
import { networkActivity } from './networkActivity'

export const CONFIRMATION = 'CONFIRMATION'
export const askToConfirm = modalProps => {
  return {
    type: OPEN_MODAL,
    context: {
      name: CONFIRMATION,
      ...modalProps
    }
  }
}

export const ERROR = 'ERROR'
export const displayError = modalProps => {
  return {
    type: OPEN_MODAL,
    context: {
      name: ERROR,
      ...modalProps
    }
  }
}

export const REDIRECT_TO_HOME = 'REDIRECT_TO_HOME'
export const redirectToHome = () => {
  window.location.href = `/users/${userId()}/level`

  return {
    type: REDIRECT_TO_HOME
  }
}

export const OPEN_MODAL = 'OPEN_MODAL'
export const openModal = context => {
  return {
    type: OPEN_MODAL,
    context: context
  }
}

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const closeModal = () => {
  return {
    type: CLOSE_MODAL
  }
}

export const UPDATE_METADATA = 'UPDATE_METADATA'
export const updateMetadata = metadata => {
  return {
    type: UPDATE_METADATA,
    metadata
  }
}

export const DELETE_PLAN_REQUEST = 'DELETE_PLAN_REQUEST'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE'
export const deletePlan = (id, modalContext) => {
  return dispatch => {
    dispatch({ type: DELETE_PLAN_REQUEST, id })

    axios.delete(`${SPABase}/users/${userId()}/plans/${id}`).then(response => {
      dispatch({
        type: DELETE_PLAN_SUCCESS,
        response,
        id,
        ...modalContext
      })
      if (planId() === id) {
        dispatch(redirectToHome())
      } else if (modalContext.dismissOnSuccess) {
        dispatch(closeModal())
      } else {
        dispatch(openModal({ name: MANAGE_PLANS }))
      }
    }).catch(error => {
      dispatch(createError({ error, type: DELETE_PLAN_FAILURE, ...modalContext }))
    })
  }
}

export const deletePlanConfirmationMessage = (plan) => React.createElement(
  'p',
  null,
  'Are you sure you want to delete ',
  React.createElement(
    'b',
    null,
    `${plan.name}`
  ),
  '?'
)

export const handleDelete = (plan, dispatch, modalContext = {dismissOnSuccess: true, dismissOnCancel: true}) => {
  dispatch(askToConfirm({
    title: 'Delete Model',
    message: deletePlanConfirmationMessage(plan),
    actions: {
      cancel: {
        action: () => {
          if (modalContext.dismissOnCancel) {
            dispatch(closeModal())
          } else {
            dispatch(openModal({ name: MANAGE_PLANS }))
          }
        }
      },
      proceed: {
        action: () => {
          dispatch(deletePlan(plan.id, modalContext))
        }, text: 'Yes, Delete'
      }
    }
  }))
}

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const fetchUser = (callback) => {
  return dispatch => {
    dispatch({ type: FETCH_USER_REQUEST })
    dispatch(networkActivity({subject: 'fetchUser', isActive: true}))

    axios.get(`${SPABase}/users/${userId()}`)
          .then(response => {
            dispatch({ type: FETCH_USER_SUCCESS, response })
            if (typeof callback === 'function') {
              callback(response.data)
            }
          }).catch(error => {
            dispatch(createError({ error, type: FETCH_USER_FAILURE }))
            if (typeof callback === 'function') {
              callback(false)
            }
          }).finally(() => {
            dispatch(networkActivity({subject: 'fetchUser', isActive: false}))
          })
  }
}

export const showCheckPermissionLevelMessage = (id, modalContext) => {
  return dispatch => {
    dispatch(openModal({
      name: 'ERROR',
      message: 'Read-only users cannot access this feature. Please contact your administrator to increase your permission level.',
      title: 'Check your permission level',
      hideCancelButton: true,
      action: () => dispatch(closeModal())
    }))
  }
}
