import { LEAVE_PROJECT, REIMPORT_PLAN } from 'actions/managePlans'
import { COPY_PLAN, REVERT_PLAN } from 'actions/planForm'
import { FETCH_PLANS_SUCCESS, UPDATE_PLAN_SUCCESS } from 'actions/plans'
import { DELETE_PLAN_REQUEST } from 'actions/shared'
import { DELETE_PROJECT_SUCCESS } from 'actions/projects'

export default (state = [], action) => {
  switch(action.type) {
    case DELETE_PLAN_REQUEST: {
      const { id } = action
      const planIndex = state.findIndex(element => element.id === id)

      return [...state.slice(0, planIndex), ...state.slice(planIndex + 1)]
    } case FETCH_PLANS_SUCCESS: {
      const { plans } = action.response.data

      return [...plans]
    } case REIMPORT_PLAN: {
      const { plan: reimportedPlan } = action.payload.data
      const { id } = action.meta

      const planIndex = state.findIndex(element => element.id === id)

      return [...state.slice(0, planIndex), reimportedPlan, ...state.slice(planIndex + 1)]
    } case UPDATE_PLAN_SUCCESS: {
      const plan = action.response

      const planIndex = state.findIndex(element => element.id === plan.id)

      return [...state.slice(0, planIndex), { ...plan }, ...state.slice(planIndex + 1)]
    } case COPY_PLAN:
      case REVERT_PLAN: {
      const { plan } = action.response.data

      return [...state, plan]
    } case DELETE_PROJECT_SUCCESS:
      case LEAVE_PROJECT: {
      const { projectId } = action

      return [...state.filter(plan => plan.projectId !== projectId)]
    } default:
      return state
  }
}
