import { UPDATE_METADATA } from 'actions/shared'
import { UPDATE_PLAN_SUCCESS } from 'actions/plans'

export default (state = {}, action) => {
  switch(action.type) {
    case UPDATE_METADATA: {
      return {
        ...action.metadata
      }

    } case UPDATE_PLAN_SUCCESS: {
      const { response: plan } = action
      const { currentPlan } = state

      if (currentPlan && currentPlan.id === plan.id) {
        return { ...state, currentProjectId: plan.projectId, currentPlan: plan }
      } else {
        return state
      }
    } default:
      return state
  }
}
