import { Controller } from '@hotwired/stimulus'
import { el } from 'modules/DOM'

export default class FileDisplayerController extends Controller {
  connect() {
    this.element[this.identifier] = this
  }

  static Element(targetIdentifier, targetName) {
    return el("PRE", {
      classList: ['file-displayer'],
      dataSet: {
        'controller': 'automate-admin--file-displayer',
        [`${targetIdentifier}-target`]: targetName
      }
    })
  }

  updateDisplayedFile(file, content) {
    this.showingFile = file
    this.element.innerHTML = content
  }
}
