// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { OPEN_PLAN } from './managePlans'
import { openModal, closeModal, MANAGE_PLANS, NEW_PLAN, displayError } from './shared'

import { authenticityToken, userId, createError } from './helpers'

import { networkActivity } from 'actions/networkActivity'

const baseEndpoint = () => `/users/${userId()}/plans`

export const COPY_PLAN = 'COPY_PLAN'
export const COPY_PLAN_FAILURE = 'COPY_PLAN_FAILURE'
export const copyPlan = ({ id, name, project, copyLogs, copyFromRevision, revisionString, redirect, dismissOnSuccess, onError }) => {
  return dispatch => {
    const { id: projectId, name: projectName, folderId, folderName } = project
    const creatingNewProject = projectId === undefined
    const creatingNewProjectFolder = folderId === undefined

    const params = {
      id,
      plan: { name },
      authenticity_token: authenticityToken(),
      copy_logs: copyLogs.toString(),
      copy_revert: copyFromRevision ? revisionString : null,
      project_creation: creatingNewProject ? 'true' : 'false',
      project_folder_creation: creatingNewProjectFolder ? 'true' : 'false',
      project: {
        name: projectName,
        folder_name: folderName
      },
      project_selection: projectId || 'false',
      project_folder_selection: folderId || 'false'
    }

    // Signal network activity is taking place
    dispatch(networkActivity({subject: 'copyPlan', isActive: true}))

    const action = copyFromRevision ? 'copy_from_revision' : 'save_as'

    // should be a POST
    axios.put(`${baseEndpoint()}/${id}/${action}.json`, params)
         .then(response => {
           const { id } = response.data.plan

           // No need to send the COPY_PLAN action here because we'll get fresh data
           // after a full page refresh
           if (redirect) {
             window.location = `${baseEndpoint()}/${id}`

             dispatch({ type: OPEN_PLAN })
           } else {
            dispatch({ type: COPY_PLAN, response })
            if (dismissOnSuccess) {
              dispatch(closeModal())
            } else {
              dispatch(openModal({ name: MANAGE_PLANS }))
            }
           }
         }).catch(error => {
          // Remove the spinner only if the action failed, to avoid multiple submissions
          dispatch(networkActivity({subject: 'copyPlan', isActive: false}))
          const errMsg = error.response.data.error || 'Something went wrong.'
          dispatch(displayError({type: COPY_PLAN_FAILURE,
                                 title: "Unable to copy the model",
                                 message: errMsg,
                                 action: () => {
                                  dispatch(handleChange({ id, name, project, projectName }))
                                  dispatch(openModal({ name: COPY_PLAN, id }))
                                  onError(errMsg)
                                 }
                                }))
         })
  }
}

export const CREATE_PLAN = 'CREATE_PLAN'
export const createPlan = ({ name, project }) => {
  return dispatch => {
    const { id: projectId, name: projectName, folderId, folderName } = project
    const creatingNewProject = projectId === undefined

    const params = {
      plan: { name },
      authenticity_token: authenticityToken(),
      project_creation: creatingNewProject ? 'true' : 'false',
      project: {
        name: projectName,
      },
      folder: {
        id: folderId,
        name: folderName,
      },
      project_selection: projectId || 'false'
    }

    // Signal network activity is taking place
    dispatch(networkActivity({subject: 'createPlan', isActive: true}))

    const url = `${baseEndpoint()}.json`
    axios.post(url, params)
        .then(response => {
          const { id } = response.data

          window.location.href = `${baseEndpoint()}/${id}`
        }).catch(() => {
          // Remove the spinner only if the action failed, to avoid multiple submissions
          dispatch(networkActivity({subject: 'createPlan', isActive: false}))
        })

    return {
      type: CREATE_PLAN
    }
  }
}

export const createPlanViaImport = ({ name, project, file }) => {
  return dispatch => {
    const { id: projectId, name: projectName } = project
    const formData = new FormData()

    formData.append('plan[import]', file)
    formData.append('plan[name]', name)
    formData.append('authenticity_token', authenticityToken())

    switch(projectId) {
      case 0:
        formData.append('project_creation', 'false')
        formData.append('project_selection', 'false')
        break;
      case undefined:
        formData.append('project_creation', 'true')
        formData.append('project[name]', projectName)
        break;
      default:
        formData.append('project_selection', projectId)
    }

    dispatch(networkActivity({subject: 'createPlan', isActive: true}))

    const url = `${baseEndpoint()}.json`
    axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
         .then(response => {
          const { id } = response.data

          window.location = `${baseEndpoint()}/${id}`

          dispatch({ type: CREATE_PLAN })
         }).catch(error => {
          // Remove the spinner only if the action failed, to avoid multiple submissions
          dispatch(networkActivity({subject: 'createPlan', isActive: false}))
          const { message } = error.response.data

          dispatch(openModal({ name: 'ERROR',
                               message: message || 'An error occurred, please try again.',
                               hideCancelButton: true,
                               title: 'Unable to create the model',
                               action: () => dispatch(openModal({ name: NEW_PLAN })) }))
         })
  }
}

export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const handleChange = props => dispatch => {
  dispatch({
    type: HANDLE_CHANGE,
    props
  });
  return Promise.resolve();
}

export const handleCreate = plan => {
  return dispatch => {
    plan.file ? dispatch(createPlanViaImport(plan)) : dispatch(createPlan(plan))
  }
}

export const REVERT_PLAN = 'REVERT_PLAN'
export const REVERT_PLAN_FAILURE = 'REVERT_PLAN_FAILURE'
export const revertPlan = ({ id, revisionString, onError }) => {
  return dispatch => {

    const params = {
      id,
      revision: revisionString,
      authenticity_token: authenticityToken()
    }

    // Signal network activity is taking place
    dispatch(networkActivity({subject: 'revertPlan', isActive: true}))

    // should be a POST
    // /plans/130/plan_logs/862/revert
    axios.post(`/plans/${id}/plan_logs/${revisionString}/revert`, params)
         .then(response => {
          window.location = response.data.url;
         }).catch(error => {
          // Remove the spinner only if the action failed, to avoid multiple submissions
          dispatch(networkActivity({subject: 'revertPlan', isActive: false}))
          const errMsg = error.response.data.error || 'Something went wrong.'
          dispatch(displayError({type: REVERT_PLAN_FAILURE,
                                 title: "Unable to revert the model to the selected revision",
                                 message: errMsg,
                                 action: () => {
                                  onError(errMsg)
                                 }
                                }))
         })
  }
}