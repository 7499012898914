import HexController from 'controllers/hex_controller'

class StrengthDropdownController extends HexController {
  static values = {
    initialStrength: Number
  }

  connect() {
    this.dbg(`Connect, strength val: ${this.initialStrengthValue}`)
    // Let's reset the strength dropdown value on connect
    this.updateStrength(this.initialStrengthValue, 180)
  }

  /* Strength change management */
  strengthChange(event) {
    this.dbg(`Strength change: ${event.target.value}`)
    this.updateStrength(event?.target?.value)
  }

  updateStrength = (strength = 0, delay = 0) => {
    this.dbg(`Updating strength ${strength}, delay ${delay}`)
    // We use a delay here to avoid Rails reset the select
    // to the previous saved state that can be different
    setTimeout(() => {
      this.element.value = strength
    }, delay)
  }

  /* ******************************************************* */
}

export default StrengthDropdownController