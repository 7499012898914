// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import { SPABase, createError, userId } from './helpers'

export const DELETE_INVITATION_REQUEST = 'DELETE_INVITATION_REQUEST'
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS'
export const DELETE_INVITATION_FAILURE = 'DELETE_INVITATION_FAILURE'
export const deleteInvitation = ({ projectId, invitationId }) => {
  return dispatch => {
    dispatch({ type: DELETE_INVITATION_REQUEST })

    axios.delete(`${SPABase}/users/${userId()}/projects/${projectId}/invitations/${invitationId}`)
          .then(() => {
                  dispatch({
                    type: DELETE_INVITATION_SUCCESS,
                    id: invitationId,
                    projectId
                  })
                }).catch(error => {
                  dispatch(createError({ error, type: DELETE_INVITATION_FAILURE }))
                })
  }
}

export const FETCH_INVITATIONS_REQUEST = 'FETCH_INVITATIONS_REQUEST'
export const FETCH_INVITATIONS_SUCCESS = 'FETCH_INVITATIONS_SUCCESS'
export const FETCH_INVITATIONS_FAILURE = 'FETCH_INVITATIONS_FAILURE'
export const fetchInvitations = projectId => {
  return dispatch => {
    dispatch({ type: FETCH_INVITATIONS_REQUEST })

    axios.get(`${SPABase}/users/${userId()}/projects/${projectId}/invitations`)
          .then(response => {
            dispatch({
              type: FETCH_INVITATIONS_SUCCESS,
              response
            })
          }).catch(error => {
              dispatch(createError({ error, type: FETCH_INVITATIONS_FAILURE }))
            })
  }
}

export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST'
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS'
export const RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE'
export const resendInvitation = ({ projectId, invitationId }) => {
  return dispatch => {
    dispatch({ type: RESEND_INVITATION_REQUEST })

    axios.put(`${SPABase}/users/${userId()}/projects/${projectId}/invitations/${invitationId}/resend`)
          .then(response => {
            dispatch({
              type: RESEND_INVITATION_SUCCESS,
              response
            })
          }).catch(error => {
            dispatch(createError({ error, type: RESEND_INVITATION_FAILURE }))
          })
  }
}

export const UPDATE_INVITATION_REQUEST = 'UPDATE_INVITATION_REQUEST'
export const UPDATE_INVITATION_SUCCESS = 'UPDATE_INVITATION_SUCCESS'
export const UPDATE_INVITATION_FAILURE = 'UPDATE_INVITATION_FAILURE'
export const updateInvitation = invitation => {
  return dispatch => {
    dispatch({ type: UPDATE_INVITATION_REQUEST })

    const { id, level, projectId } = invitation
    const params = {
      invitation: {
        level
      }
    }

    axios.put(`${SPABase}/users/${userId()}/projects/${projectId}/invitations/${id}`, params)
          .then(response => {
            dispatch({
              type: UPDATE_INVITATION_SUCCESS,
              response
            })
          }).catch(error => {
            dispatch(createError({ error, type: UPDATE_INVITATION_FAILURE }))
          })
  }
}
