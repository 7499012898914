import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["log", "button"]

  disableButtons() {
    this.buttonTargets.forEach(button => {
      button.disabled = true
      button.classList.add('disabled')
    })
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase()

    const matches = this.logTargets.filter(target => target.dataset.planLogSearchValue.toLowerCase().includes(searchTerm))
    const misses = this.logTargets.filter(target => !target.dataset.planLogSearchValue.toLowerCase().includes(searchTerm))

    matches.forEach(match => match.hidden = false)
    misses.forEach(miss => miss.hidden = true)
  }
}