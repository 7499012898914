import HexController from 'controllers/hex_controller'
import { i18nTrnsl } from 'actions/helpers'
import { Grid } from 'ag-grid-community'
import UserNameCellRenderer from 'modules/ag-grid/admin/user_management/userNameCellRenderer';
import OrganizationNameCellRenderer from 'modules/ag-grid/admin/organization/organizationNameCellRenderer';
import HTMLNodesCellRenderer from 'modules/ag-grid/htmlNodesCellRenderer';
import HTMLNodesHeaderRenderer from 'modules/ag-grid/htmlNodesHeaderRenderer';
import { buildHTMLNodes } from 'modules/DOM/element';

export default class extends HexController {
  static values = { target: String, model: String, columns: Array, dataSourceUrl: String };
  static targets = ['editPermissionCheckbox']

  /////////////////////////
  // Lifecycle events
  /////////////////////////
  connect() {
    this.tableData = [];

    const dataSource = {
      rowCount: undefined,

      getRows: (params) => {
        this.fetchRows(params)
      }
    }

    // let the grid know which columns and what data to use
    this.gridOptions = {
      // Basic static part
      ...this._gridOptions(),
      // dynamic parts depending from the loaded data
      columnDefs: this.columnsValue,
      datasource: dataSource,
    }

    const gridDiv = document.querySelector(this.targetValue);
    // Reset the grid target content since turbo navigation is restoring the previous HTML
    // but is also re-initializing the AG Grid adding another table below the previous one
    gridDiv.innerHTML = "";
    new Grid(gridDiv, this.gridOptions);
  }

  _getRowsParams = {}

  fetchRows(params) {
    const fetchProm = fetch(this.dataSourceUrlWithParams(params))
    fetchProm.then((response) => response.json())
      .then((data) => this.handleJsonResponse(params, data));
    // Keep the latest get rows parameters to replicate the request laster on
    this._getRowsParams = {
      ...this._getRowsParams,
      ...params,
      startRow: Math.min(params.startRow, this._getRowsParams.startRow || 0),
      endRow: Math.max(params.endRow, this._getRowsParams.endRow || 0),
      limit: Math.max(params.limit, this.tableData.length || 0),
      offset: Math.min(params.offset, this._getRowsParams.offset || 0)
    }
    return fetchProm
  }

  /////////////////////////
  // Controller Methods
  /////////////////////////
  submitEnd(event) {
    const {detail: {success}} = event
    if (success) {
      this.submitEndSuccess(event)
    } else {
      this.submitEndError(event)
    }
  }

  submitEndSuccess(_event) {
    // Remove the popover from the view if it exists
    $('body > .popover.show').popover('hide')

    // When event is successful, update the grid
    this.gridOptions.api.refreshInfiniteCache();
  }

  submitEndError({detail = []}) {
    // Remove the popover from the view if it exists
    $('body > .popover.show').popover('hide')

    // When the event is not successful, we need to generate and display
    // the error message in the modal window
    if (detail[0]?.content) {
      let responseNode = buildHTMLNodes(detail[0].content);
      let modalContainer = document.getElementById('hexawise-modal-container');
      modalContainer.innerHTML = responseNode.outerHTML;
    }
  }

  /////////////////////////
  // AGGrid Functions
  /////////////////////////
  handleJsonResponse(params, data) {
    // if on or after the last page, work out the last row.
    let lastRow = -1;

    // If the length of data is less than the length of our expected end row minus the start (max cacheBlockSize)
    // then we have all the results and we should no longer expect more
    if (data.length < (params.endRow - params.startRow)) {
      lastRow = params.startRow + data.length;
    }

    // Some times we reload the same set of data so we need to filter out duplicates by id
    // and keep the most recent version
    const allNewIds = data.map((v) => v.id)
    const uniqOldData = this.tableData.filter((v) => !allNewIds.includes(v.id))
    this.tableData = uniqOldData.concat(data);

    params.successCallback(data, lastRow);
  }

  dataSourceUrlWithParams(params) {
    const offsetKeyName = `${this.getFilterModel(params.filterModel)}offset`
    const limitValue = params.endRow - params.startRow
    const sortQueryParameter = this.getSortModel(params.sortModel)
    // Compose the url from with all the pieces:
    return `${this.dataSourceUrlValue}?` +           // Basic url
           `${offsetKeyName}=${params.startRow}&` +  // offset row for pagination
           `limit=${limitValue}&` +                  // limit for pagination
           `${sortQueryParameter}`                   // sorting
  }

  getFilterModel(filterModel) {
    let filterString = '';

    for (const [key, value] of Object.entries(filterModel)) {
      filterString += `${this.modelValue}[${key}]=${value.filter}&`;
    }

    return filterString
  }

  getSortModel(sortModel) {
    const sortModelString = sortModel.map(sorting => `sort=${sorting.colId}&order=${sorting.sort}`).at(0);

    return sortModelString?.length > 0 ? `${sortModelString}` : '';
  }

  /////////////////////////
  // Static AGGrid conf
  /////////////////////////

  _gridOptions() {
    return {
      // General Options
      enableBrowserTooltips: true,
      rowHeight: 48,
      suppressCellFocus: true,

      // Infinite Row Model - https://www.ag-grid.com/javascript-grid-infinite-scrolling/
      cacheBlockSize: 50,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 10,
      maxConcurrentDatasourceRequests: 3,
      rowBuffer: 0,
      rowModelType: 'infinite',

      // General rules, components, and other misc
      columnTypes: this._columnTypes,
      components: this._components,
      defaultColDef: this._defaultColDef,
      rowClassRules: this._rowClassRules,

      // Functions
      getRowId: (params) => params?.data?.id,

      onBodyScroll: (_params) => $('body > .popover.show').popover('hide'),
    }
  }

  _rowClassRules = {
    'danger': (params) => params?.data?.row_classes?.includes('danger'),
    'warning': (params) => params?.data?.row_classes?.includes('warning'),
    'success': (params) => params?.data?.row_classes?.includes('success'),
    'admin': (params) => params?.data?.row_classes?.includes('admin'),
  }

  _columnTypes = {
    actionsCell: { headerClass: 'text-center', cellClass: 'text-center cell-vertical-align grid-table-actions' },
    noFilter: { filter: false },
    smallDisplay: { minWidth: 115, width: 115, resizable: false },
    textCenter: { headerClass: 'text-center', cellClass: 'text-center cell-vertical-align' },
    textRight: { headerClass: 'text-right', cellClass: 'text-right cell-vertical-align' },

    loading: {
      colSpan: (params) => params?.data ? 1 : params.api.columnModel.displayedColumns.length
    }
  }

  _components = {
    UserNameCellRenderer,
    OrganizationNameCellRenderer,
    HTMLNodesCellRenderer,
    HTMLNodesHeaderRenderer,
  }

  _defaultColDef = {
    cellClass: 'cell-vertical-align',
    editable: false,
    enableCellChangeFlash: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      debounceMs: 250,
      suppressAndOrCondition: true
    },
    flex: 1,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    minWidth: 125,
    resizable: true,
    sortable: true,
    suppressMenu: true,
  }

  /**
   * If the edit permission checkbox is present, uncheck it on every change of the checkboxes in the column.
   * It should be checked when all write seats have been used but the client has only a partial view due to pagination.
   */
  editPermissionCheckboxDidChange = () => {
    if (this.hasEditPermissionCheckboxTarget) {
      this.editPermissionCheckboxTarget.checked = false
    }
  }

  headerEditPermissionCheckboxDidChange = (e) => {
    const checkbox = e.srcElement
    const selectAll = checkbox.checked
    const promptCopy = i18nTrnsl(`Are you sure to turn all your team members into ${selectAll ? 'editors' : 'read-only users'}?`)
    const url = selectAll? checkbox.dataset.editorsUrl : checkbox.dataset.readersUrl
    if (confirm(promptCopy)) {
      fetch(url, { method: 'POST' }).then(_ => {
        this.fetchRows(this._getRowsParams).then(() => {
          this.gridOptions.api.refreshCells({force: true})
        })
      })
    } else {
      e.stopPropagation();
      e.preventDefault();
      checkbox.checked = !selectAll
    }
  }
}