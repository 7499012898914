import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { exists: Boolean, calculatedData: String }

  connect() {
    if (this.hasExistsValue) {
      document.getElementById('combination-list').dataset.scenariosExistsValue = this.existsValue;
    }
  }

  calculatedDataValueChanged() {
    if (this.hasCalculatedDataValue) {
      document.getElementById('combination-list').dataset.scenariosCombinationDataValue = this.calculatedDataValue;
    }
  }
}
