import { CREATE_REQUIREMENT_SUCCESS, DELETE_REQUIREMENT_SUCCESS, FETCH_REQUIREMENTS_SUCCESS, TOGGLE_REQUIREMENT_CHECKBOX, TOGGLE_ALL_REQUIREMENTS_CHECKBOX, UPDATE_REQUIREMENT_REQUEST, UPDATE_REQUIREMENT_SUCCESS, BULK_DELETE_REQUIREMENTS_SUCCESS, FETCH_REQUIREMENTS_REQUEST, IMPORT_REQUIREMENT_SUCCESS, REORDER_REQUIREMENTS_REQUEST, UNFREEZE_FROZEN_SCENARIOS_SUCCESS } from 'actions/requirements'

const initialState = {
  requirements: [],
  selectedRequirements: [],
  modifiable: true,
  loading: true,
  updatingCell: null,
  updatingRow: null
}

function bulkDeleteRequirements(state, action) {
  let requirements = [...state.requirements]
  let selectedRequirements = [...state.selectedRequirements]

  for (var requirementId of action.requirementIds) {
    requirements = requirements.filter(requirement => { return requirement.id != requirementId})
    selectedRequirements = selectedRequirements.filter(requirement => { return requirement.id != requirementId})
  }

  return {
    ...state,
    requirements,
    selectedRequirements
  }
}

function createNewRequirement(state, action) {
  const { parameters, requirement } = action.response.data

  const requirements = [...state.requirements.slice(0, -1), requirement, ...state.requirements.slice(-1)]

  return {
    ...state,
    requirements,
    parameters
  }
}

function deleteRequirement(state, action) {
  const requirements = [...state.requirements]
  const deletedRequirement = requirements[action.rowIdx]

  requirements.splice(action.rowIdx, 1)

  let selectedRequirements = [...state.selectedRequirements]
  selectedRequirements = selectedRequirements.filter(requirement => { return requirement.id != deletedRequirement.id})

  return {
    ...state,
    requirements,
    selectedRequirements
  }
}

function toggleRequirementCheckbox(state, action) {
  const requirements = [...state.requirements]
  const currentRequirement = requirements[action.rowIdx]
  let selectedRequirements = [...state.selectedRequirements]

  currentRequirement.is_checked = !currentRequirement.is_checked

  if(currentRequirement.is_checked) {
    selectedRequirements = [...selectedRequirements, currentRequirement]
  } else {
    selectedRequirements = selectedRequirements.filter(requirement => { return requirement !== currentRequirement})
  }

  return {
    ...state,
    requirements,
    selectedRequirements
  }
}

function toggleAllRequirementCheckboxes(state) {
  const requirements = [...state.requirements]
  const requirementsSize = requirements.length - 1
  let selectedRequirements = requirements.filter(requirement => { return requirement.is_checked })
  const allEnabled = (requirementsSize != 0 && selectedRequirements.length == requirementsSize)

  for(var i in requirements) {
    let requirement = requirements[i]
    if(requirement.id != "new") {
      requirements[i].is_checked = !allEnabled
    }
  }

  selectedRequirements = allEnabled ? [] : [...requirements].filter(requirement => { return requirement.id != "new" })

  return {
    ...state,
    requirements,
    selectedRequirements
  }
}

function updateRequirementRow(state, action) {
  const { parameters, requirement } = action.response.data

  const requirementIndex = state.requirements.findIndex(element => element.id === requirement.id)
  const requirements = [...state.requirements.slice(0, requirementIndex), { ...requirement }, ...state.requirements.slice(requirementIndex + 1)]

  return {
    ...state,
    requirements,
    parameters,
    updatingCell: null,
    updatingRow: null
  }
}

export default (state = initialState, action) => {
  switch(action.type) {
    case CREATE_REQUIREMENT_SUCCESS:
      return createNewRequirement(state, action)
    case BULK_DELETE_REQUIREMENTS_SUCCESS:
      return bulkDeleteRequirements(state, action)
    case DELETE_REQUIREMENT_SUCCESS:
      return deleteRequirement(state, action)
    case FETCH_REQUIREMENTS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case IMPORT_REQUIREMENT_SUCCESS:
    case FETCH_REQUIREMENTS_SUCCESS:
    case UNFREEZE_FROZEN_SCENARIOS_SUCCESS:
      return {
        ...state,
        ...action.response.data,
        selectedRequirements: [],
        loading: false
      }
    case REORDER_REQUIREMENTS_REQUEST:
      return {
        ...state,
        ...action.requirements
      }
    case TOGGLE_REQUIREMENT_CHECKBOX:
      return toggleRequirementCheckbox(state, action)
    case TOGGLE_ALL_REQUIREMENTS_CHECKBOX:
      return toggleAllRequirementCheckboxes(state)
    case UPDATE_REQUIREMENT_REQUEST:
      return {
        ...state,
        ...action.updatingData
      }
    case UPDATE_REQUIREMENT_SUCCESS:
      return updateRequirementRow(state, action)
    default:
      return state
  }
}