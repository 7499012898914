/**//**//**//**//**//**//**//**//**//**//**//**//**//**//**//**/
/**                                                            *
 * I18n mixin for Stimulus controllers                        *
 * @module Controllers/mixins/i18n                             *
 * @file app/controllers/mixins/i18n.js                        *
 * @author Iacopo Carraro <iacopo.carraro@hexawise.com>        *
 * @exports useI18n                                            *
 **                                                            *
/**//**//**//**//**//**//**//**//**//**//**//**//**//**//**//**/

 /**
  * @function useI18n "Extends" the passed object (usually a Stimulus controller)
  * adding translation features via i18n library.
  * @param {object} controller the object to extend, in our case it's a Stimulus controller
  */
 export const useI18n = (controller) => {
  Object.assign(controller, {
    /**
     * Take care of translating the passed string via i18n module in the current user language.
     * Fails safe by returning an empty string in case something diffent than a string is passed
     * @method t
     *
     * @param  {String} stringToTranslate The string to be translated
     * @returns The translated string
     */
    t(str2Trns) {
      if (typeof str2Trns === "string" && str2Trns?.length) {
        return i18n.translate(str2Trns).fetch()
      }
      return ""
    }
   });
 }
