import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    ReactRailsUJS.mountComponents(this.element)
  }

  disconnect() {
    ReactRailsUJS.unmountComponents(this.element)
  }
}