import { easeLinear } from 'd3-ease'
import { hierarchy } from 'd3-hierarchy'
import { select } from 'd3-selection'
import { transition } from 'd3-transition'
import { zoom, zoomIdentity } from 'd3-zoom'

import { debounce } from 'underscore'

import { default as hexTree } from 'vendor/javascripts/d3-hierarchy/hex_tree'
import { default as Node } from './mind_map/node'

export default class HexMindMap {
  // Method call when initializing a mind map for our Mind Map view
  static initializeMindMapView() {
    if($('#mindmap-container').length) {
      // Remove any existing mind map containers
      $('#mindmap-container svg').remove()

      window.currentMindMap = new HexMindMap('#mindmap-container')

      $('#panel-header-buttons button').removeClass('disabled');
    }
  }

  // Method called when initializing a mind map for our Mind Map export view.
  static initializeMindMapExport() {
    if($('#mind-map-export-data').length) {
      window.currentMindMapExport = new HexMindMap('#mind-map-export-data', true);
      document.removeEventListener('mindMapRendered', window.currentMindMapExport.toHTML);
      document.addEventListener('mindMapRendered', window.currentMindMapExport.toHTML);
    }
  }

  // Returns the styles that exist for the Mind Map to be used inline the svg
  static get styles() {
    return {
      link: "fill: none; stroke: #CCC; stroke-width: 3px;",
      hexawise_color_1: "stroke: #36B37E;",
      hexawise_color_2: "stroke: #FFAB00;",
      hexawise_color_3: "stroke: #FF5630;",
      hexawise_color_4: "stroke: #00B8D9;",
      hexawise_color_5: "stroke: #0065FF;",
      hexawise_color_6: "stroke: #6554C0;",
      hexawise_collapsed_1: "fill: #36B37E;",
      hexawise_collapsed_2: "fill: #FFAB00;",
      hexawise_collapsed_3: "fill: #FF5630;",
      hexawise_collapsed_4: "fill: #00B8D9;",
      hexawise_collapsed_5: "fill: #0065FF;",
      hexawise_collapsed_6: "fill: #6554C0;",
      collapsed: "fill: #FFF;",
      expanded: "fill: #FFF;",
      nodeCircle: "stroke: #6554C0; cursor: pointer;"
    }
  }

  // General class constructor method, sets ups or basic settings, pulls the
  // json data from our mindmap-json data attribute, sets the text display attributes
  // that we use throughout, and generates our initial SVG DOM structure.
  //
  // From there, we also set up the zoom handler, default tree hierarchy, trim the display
  // labels, and set up the initial display, as well as set up the resize handler for the
  // window, assuming that this instance is not an svg export instance.
  constructor(selectorId, svgForExport) {
    this.selectorId = selectorId
    this.svgForExport = svgForExport

    this.container = $(this.selectorId)
    this.mindMapJson = this.container.data('mindmap-json')
    this.duration = this.svgForExport ? 0 : 250;

    this.displayOffset = 150;
    this.textOffset = this.displayOffset/2 + 10;
    this.displayLabelPrefixLength = 12;
    this.displayLabelSuffixLength = 12;
    this.displayLabelLengthTolerance = 5;
    this.displayLabelLength = this.displayLabelPrefixLength + this.displayLabelSuffixLength + (2 * this.displayLabelLengthTolerance)

    this.zoom = zoom().scaleExtent([0.1, 3]).on("zoom", ({transform}) => select(`${this.selectorId} g.svg-group`).attr("transform", transform))
  
    this.svg = select(selectorId).append("svg")
        .attr('id', 'mindMapSvg')
        .attr("class", "overlay")
        .attr('height', '100%')
        .attr('width', '100%');

    this.svgGroup = this.svg.append("g")
        .attr("class", "svg-group")
        .attr("font-size", 10)

    // Append our primary links element
    this.linkGroup = this.svgGroup.append("g")
        .attr("fill", "none")
        .attr("stroke-width", 3)

    // Append our primary nodes element
    this.nodeGroup = this.svgGroup.append("g")
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)

    if(!this.svgForExport) {
      this.tooltipDiv = select(selectorId).append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
    }

    this.transition = () => {
      return transition()
        .duration(this.duration)
        .ease(easeLinear);
    }

    this.svg.call(this.zoom);

    this.root = hierarchy(this.mindMapJson)
    this.trimLabels();

    this.setup();

    if(!this.svgForExport) {
      $(window).on('resize', debounce(() => {
        if (window.currentMindMap != null && $(window.currentMindMap.selectorId).length > 0) {
          window.currentMindMap.setup();
        }
      }, 250));
    }
  }

  //////////////////////////
  // Rendering & Data
  //////////////////////////

  // We calculate out the tree nodes by filtering out the left and right children,
  // calculating out the maximum depth of our overall tree, and calculating out a
  // view height to use as a minimum, to ensure that we will have enough spacing
  // between each child node for a clean display.
  //
  // From there we calculate out the right nodes, figure out what the offset is
  // from the root to x, and then calculate out the left nodes and pass that same offset
  // in so that our 2 roots will be located at the same point.  For all the left children,
  // we set it's y value equal to the negative offset so that it goes to the left.
  //
  // We then take these two separate trees we have generated, join them together, 
  // and set them to be the children of our root node.
  calculateTreeNodes() {
    const leftChildren = this.root.children.filter(d => d.data.direction == 'left')
    const rightChildren = this.root.children.filter(d => d.data.direction == 'right')

    const depthMax = Math.max.apply(Math, this.root.descendants().map(function(o) { return o.children ? o.children.length : 0 }))
  
    // Then calculate out our max depth and multiply that by 60
    // Whichever is the greater of the 2 becomes the height, so that we can accomodate large trees.
    const viewHeight = depthMax * 60

    this.root.children = rightChildren;
    let rightNodeTree = this.tree(viewHeight)
    let rightNodes = rightNodeTree.children
    let rootOffset = rightNodeTree.rootOffset

    this.root.children = leftChildren;
    let leftNodes = this.tree(viewHeight, rootOffset).children

    leftNodes.forEach(d => d.descendants().forEach(e => e.y = e.y * -1));

    this.root.children = rightNodes.concat(leftNodes)
  }

  // Calculate out the width of our panel class component, selecting the
  // max of either that width or 1280 to ensure we have a minimum width.
  // For the height, we take the height of the panel, minus the height of the
  // panel header, then 10 more for buffer.
  //
  // If this is an export svg, we grab the minimum for width and height at
  // this current height or 2560 and 1024, respectively.
  //
  // Finally, set the viewbox reference points to 0,0 x and y, and then
  // the width and height to our calculated sizes.
  resizeSVG() {
    // Ensure we are never smaller in width than 1280px
    this.width = Math.max(1280, $('.panel').width());
    this.height = $('.panel').height();

    if(this.svgForExport) {
      // If this is for export, ensure we're never larger than 2560 width, and never smaller than 1024 for height.
      this.width = Math.min(2560, this.width);
      this.height = Math.max(1024, this.height);
    }

    this.svg.attr("viewBox", [0, 0, this.width, this.height])
  }

  // Setup function to simply run our needed methods for setting up the
  // svg display.
  setup() {
    this.resizeSVG();
    this.calculateTreeNodes();
    this.update();
    this.centerNode();
  }

  // Get a tree from our hexTree class, which is copied from the d3-hierarchy tree class and modified
  // to support having a specific x offset from the calcuated root node x location.
  tree(viewHeight, rootOffset) {
    return hexTree().rootOffset(rootOffset).size([Math.max(viewHeight, this.height), this.width / 1.75])(this.root);
  }

  // Generates the html output of our SVG by grabbing the bounding box of our svg overlay group,
  // hiding the now generated export data object, setting the width and height of our svg view box
  // to equal our bounding box (the size of our SVG) and then add our output padding to the width
  // and height so there is a small amount of buffer.
  //
  // From there we shift the svg to the center of our bounding box and then shift it a bit more based on
  // half the size of our padding to accomodate for the variance.
  //
  // Finally we grab the mind map SVG data and store it into a hidden input field, then remove all of our
  // fields.
  toHTML() {
    const outputPaddingAmount = 150;

    // Remove the old mind map data object, in case it exists
    $("#mind-map-data").remove();

    var svgObj = $('#mind-map-export-data > svg.overlay');

    // Set our svg object's actual width and height (it differs from the bounding box)
    var boundingBox = $('#mind-map-export-data > svg.overlay > g')[0].getBBox();

    // Then remove the mind map data from the DOM
    $("#mind-map-export-data").hide();

    svgObj.attr("viewBox", [0, 0, boundingBox.width + outputPaddingAmount, boundingBox.height + outputPaddingAmount])

    // And set the child g object's transform horizontal to half the width and vertical to 0
    svgObj.children("g").attr("transform", `translate(${-boundingBox.x + outputPaddingAmount / 2},${-boundingBox.y + outputPaddingAmount / 2})`);

    var mind_map_data = $("<input>").attr("id", "mind-map-data").attr("type", "hidden").attr("name", "mind_map_data").val(svgObj.parent().html());

    $('#mind-map-export-data').remove();
    $("#mind-map-data").remove();

    $(".exporter-content form").append(mind_map_data);
  }

  // Update function which simply goes through all the g nodes and path links,
  // coupled with the data for our root descendants and root links to generate
  // the layout for our mind map.
  //
  // For any elements that exist in the data but do not exist in the layout,
  // the enter logic layout and animations are called, including adding any
  // DOM elements and transitioning their attributes.
  //
  // For any elements that exist in the data and the DOM but have different
  // data than it did when created, the update logic is called.
  //
  // For any elements that exist in the layout which do not exist in the data,
  // the exit path logic and animations are called.
  //
  // We also add any applicable event handlers, such as click and mousover.
  update() {
    //////////////////////////
    // Node Display Setup
    //////////////////////////
    this.nodeGroup.selectAll("g.node")
        .data(this.root.descendants(), d => {
          return d.id || (d.id = d.data.id)
        })
        .join(
          enter => {
            const nodeEnter = enter.append("g")

            nodeEnter.attr('data-id', d => d.id)
              .attr("transform", d => `translate(${d.y},${d.x})`)
              .attr("fill-opacity", 0)
              .attr("stroke-opacity", 0)
              .call(enter => enter.transition(this.transition()).delay(this.duration)
                .attr("fill-opacity", 1)
                .attr("stroke-opacity", 1))

            nodeEnter.append("circle")
              .attr("fill", "#FFF")
              .attr("style", Node.nodeLinkCircleStyles)
              .attr("class", d => d.parent ? d.data.direction : "center")
              .attr("r", 4.5);

            nodeEnter.append("text")
              .attr("x", d => d.data.direction == 'left' ? this.textOffset : -this.textOffset)
              .attr("y", -5)
              .attr("text-anchor", 'middle')
              .attr("style", "font-size: 20px; line-height: 20px; font-style: normal; font-weight: 600;")
              .text(Node.nodeLinkTextDisplayLabel)

            return nodeEnter
          },
          update => {
            update.call(update => update.transition(this.transition())
            .attr("transform", d => `translate(${d.y},${d.x})`))

            update.select("circle")
              .call(update => update.transition(this.transition())
                .attr("style", Node.nodeLinkCircleStyles))

            return update
          },
          exit =>
            exit.call(exit => exit.transition(this.transition())
                .attr("fill-opacity", 0)
                .attr("stroke-opacity", 0)
                .remove())
        )
          .on('click', (event, node) => this.handleNodeClick(event, node, this))
          .on('mouseover', (event, node) => {
            if(!this.svgForExport && (node.data.name_length > this.displayLabelLength)) {
              this.tooltipDiv.transition(this.transition())
                  .duration(200)
                  .style('opacity', 1);
              this.tooltipDiv.text($.unescape(node.data.name))
                  .style('left', `${event.clientX}px`)
                  .style('top', `${event.clientY - 28}px`)
            }
          })
          .on('mouseout', (_, node) => {
            if (!this.svgForExport && (node.data.name_length > this.displayLabelLength)) {
              this.tooltipDiv.transition(this.transition())
                .duration(500)
                .style("opacity", 0);
            }
          })
          .attr("class", d => `node ${d.data.direction || 'center'}`)

    //////////////////////////
    // Node Paths Setup
    //////////////////////////
    this.linkGroup.selectAll("path.link")
      .data(this.root.links(), d => d.id || (d.id = `${d.source.data.id}-${d.target.data.id}`))
      .join(
        enter =>
          enter.append("path")
            .attr("class", d => `link hexawise_color_${d.target.data.color_idx}`)
            .attr("style", d => HexMindMap.styles[`hexawise_color_${d.target.data.color_idx}`])
            .attr("opacity", 1)
            .attr("d", d => Node.nodeLinkLineDiagonal(d.source, d.source, false, this))
            .call(enter => enter.transition(this.transition())
              .attr("d", (d) => { return Node.nodeLinkPath(d, this) })),
        update =>
          update.call(update => update.transition(this.transition())
              .attr("d", (d) => { return Node.nodeLinkPath(d, this) })),
        exit =>
            exit.call(exit => exit.transition(this.transition().duration(this.duration))
              .attr("opacity", 0)
              .remove())
      )
      .attr('data-target-id', d => d.target.id)
      .attr('data-source-id', d => d.source.id)
  }

  //////////////////////////
  // Text Mutations
  //////////////////////////

  // Based on the unescaped length of the name, we perform mutations on
  // the text to try and find a break for our display name that we can appropriately
  // and logically truncate it for display.
  trimLabels() {
    this.root.descendants().forEach(d => {
      let name = $.unescape(d.data.name);
      let labelLength = name.length;

      d.data.name_length = labelLength;

      if (labelLength > this.displayLabelLength) {
        // Now need to break looking for a clean break at the last space of the prefix within tolerance and the first space of the suffix within tolerance
        if (name.substr(this.displayLabelPrefixLength - this.displayLabelLengthTolerance, this.displayLabelPrefixLength + this.displayLabelLengthTolerance).lastIndexOf(" ") !== -1) {
          var prefixBreakpoint = name.substr(0, this.displayLabelPrefixLength + this.displayLabelLengthTolerance).lastIndexOf(" ");
          d.data.displayName = name.substr(0, prefixBreakpoint) + ' ... ';
        } else {
          d.data.displayName = name.substr(0, this.displayLabelPrefixLength) + '...';
        }

        var suffixBreakpoint = 0;
        if ((suffixBreakpoint = name.substr((labelLength - this.displayLabelSuffixLength - this.displayLabelLengthTolerance), (labelLength - this.displayLabelSuffixLength + this.displayLabelLengthTolerance)).indexOf(" ")) !== -1) {
          suffixBreakpoint += (labelLength - this.displayLabelSuffixLength - this.displayLabelLengthTolerance);
          d.data.displayName += name.substr(suffixBreakpoint, labelLength);
        } else {
          d.data.displayName += name.substr((labelLength - this.displayLabelSuffixLength), labelLength);
        }
      } else {
        d.data.displayName = name;
        this.maxLabelLength = Math.max(labelLength, this.maxLabelLength);
      }
    });
  }

  //////////////////////////
  // Click Events
  //////////////////////////

  // Simple click handler to toggle children from a node based
  // on if this is the root node or not, and then call update on
  // our tree to update the display.
  handleNodeClick(event, node, parent) {
    if(!event.target.classList.contains('center')) {
      Node.toggleChildrenForNode(node)
      parent.update()
    }
  }

  //////////////////////////
  // Zoom and Display
  //////////////////////////

  // Calculates out the bounding box for the current view
  // to determine what an appropriate scale and zoom would
  // be for our SVG.
  //
  // It is important to note that setting the scale to 1,
  // then performing the translation, then setting the
  // scale for the SVG DOM is necessary to happen in those steps.
  // If not, then the translation will occur based on the current
  // scale of the SVG (which means if we have a scale of 0.5 and
  // we say to transition x by 50, it will actually only translate
  // x by 25).
  //
  // This ensures that we scale based on the bounding box, which does
  // not take into account SVG scale, and consistently have an SVG
  // that is centered and scaled as best possible to we get at least
  // one axis fully encompassed within the view.
  boundingBoxTranslation() {
    var bbox = this.svgGroup.node().getBBox();
    const heightMax = Math.max(this.height, bbox.height)
    const heightMin = Math.min(this.height, bbox.height)
    const widthMax = Math.max(this.width, bbox.width)
    const widthMin = Math.min(this.width, bbox.width)

    const heightScale = heightMin / heightMax;
    const widthScale = widthMin / widthMax;

    let scale = Math.max(heightScale, widthScale);
    const vScale = scale * 0.9;
    const xScale = scale == widthScale ? scale : widthMax/widthMin
    const yScale = scale == heightScale ? scale : heightMax/heightMin

    let y = -bbox.y;

    let scaledY, scaledYDiff

    if(scale == xScale) {
      scaledY = y * yScale;
      scaledYDiff = y/scaledY;
      y = y * scaledYDiff - 15;
    }

    return this.svg.transition(this.transition()).call(
      this.zoom.transform,
      zoomIdentity.scale(1).translate(this.width / 2, y).scale(vScale)
    )
  }

  // Taking our bounding box transition, perform the transition
  // but then also perform it once more.  The reason for this is
  // so that we can roughly transform based off the new view size
  // and all, but then perform the translation again for the
  // now correctly sized bounding box.  This causes a slight
  // "bounce," but ensures that we are properly centered and
  // scaled for the view.
  //
  // As well, if this is an svg export instance, send the event
  // stating that our mind map has been rendered so we can grab the
  // appropriately sized data and store it.
  centerNode() {
    this.boundingBoxTranslation().end().then(() => {
      return this.boundingBoxTranslation().end()
    }).then(() => {
      if(this.svgForExport) {
        let event = new CustomEvent('mindMapRendered');
        document.dispatchEvent(event);
      }
    }).catch(() => {
      // Sometimes this inexplicably throws an error, but the error is undefined, so...just ignore it
    });
  }

  // Performs our calculated zoom transitions on the svg group
  zoomTransition({transform}, parent) {
    select(`${parent.selectorId} g.svg-group`).attr("transform", transform)
  }
}