import { CANCEL_COPY_REVISION, CANCEL_COPY_PLAN, CANCEL_EDIT_PLAN, CANCEL_NEW_PLAN } from 'actions/managePlans'
import { HANDLE_CHANGE } from 'actions/planForm'

const INITIAL_STATE = {
  copyLogs: false,
  file: undefined,
  name: '',
  project: {
    id: 0,
    name: '',
    folderId: 0,
    folderName: ''
  },
  redirect: true,
  initial: true
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CANCEL_COPY_REVISION:
    case CANCEL_COPY_PLAN:
    case CANCEL_EDIT_PLAN:
    case CANCEL_NEW_PLAN:
      return INITIAL_STATE

    case HANDLE_CHANGE:
      return {
        ...state,
        ...action.props,
        initial: false
      }

    default:
      return state
  }
}
